import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  children: ReactNode;
  className?: string;
}

const Well = ({ children, className }: Props) => (
  <div
    className={classNames(
      'tw-min-h-5 tw-border tw-border-neutral-200 tw-bg-neutral-100 tw-p-5',
      className
    )}
  >
    {children}
  </div>
);

export default Well;

import React from 'react';

import { Col, Row } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import InformationField from 'shared/components/InformationField';
import CompanyInformation from './CompanyInformation';
import FinanceAllInvoices from './FinanceAllInvoices';
import ProfileContract from './ProfileContract';
import { isAlfa } from 'models/Seller';
import { AlfaSeller, Seller } from 'types';
import AlfaRates from './AlfaRates';
import useAccountInformation from './useAccountInformation';
import { Link } from 'react-router-dom';
import { toPercentage } from 'shared/utils/Helpers';

const ns = 'app.profilePage.contract';

interface Props {
  sellers: Seller[];
}

const AccountInformation = ({ sellers }: Props) => {
  const { currentSeller, signedContract, user } = useAccountInformation();
  const { fullName, email } = user;

  return (
    <div className="c-account-information">
      <h3>{I18n.nt('app.profilePage', 'profile')}</h3>
      <div className="profile-page-section">
        {sellers.map((seller) => (
          <CompanyInformation key={seller.id} seller={seller} />
        ))}
      </div>

      <div className="profile-page-section">
        <h4>{I18n.nt('app.profile', 'userFields')}</h4>
        <Row>
          <Col lg={4}>
            <InformationField field="full_name" value={fullName} model="user" />
          </Col>
          <Col lg={4}>
            <InformationField field="email" value={email} model="user" />
          </Col>
          <Col lg={4}>
            <InformationField field="phone" value={user.phone} model="user" />
          </Col>
        </Row>
      </div>

      {currentSeller.productVersion === 'alfa' && (
        <AlfaRates seller={currentSeller as AlfaSeller} />
      )}

      <div className="profile-page-section">
        <Row>
          {signedContract ? (
            <Col lg={6}>
              <ProfileContract />
            </Col>
          ) : (
            <Col lg={6} className="tw-my-auto">
              <h4>{I18n.nt(ns, 'title')}</h4>
              <p>{I18n.nt('app.profilePage.contract', 'no_contract_found')}</p>
            </Col>
          )}
          {isAlfa(currentSeller) && (
            <Col lg={6}>
              <FinanceAllInvoices />
            </Col>
          )}
          {'partnerCommissionRate' in currentSeller &&
            currentSeller.partnerCommissionRate && (
              <Col lg={6}>
                <h4>{I18n.nt(ns, 'partner_commission_rate')}</h4>
                {I18n.nt(ns, 'partner_commission_rate_explanation', {
                  partnerCommissionRate: toPercentage(
                    currentSeller.partnerCommissionRate
                  ),
                })}
              </Col>
            )}
        </Row>
      </div>
      <div className="profile-page-section">
        <h4>{I18n.nt('app.profilePage', 'settings')}</h4>
        <p>
          {I18n.nt(ns, 'service_message_html_start')}{' '}
          <Link to="/klant/account/service">
            {I18n.nt(ns, 'service_message_html_link')}
          </Link>{' '}
          {I18n.nt(ns, 'service_message_html_end')}
        </p>
      </div>
    </div>
  );
};

export default AccountInformation;

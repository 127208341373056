import React from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

interface DropDownItem {
  eventKey: any;
  disabled: boolean;
}

interface DropdownButtonProps {
  id: string;
  title?: string;
  className?: string;
  disabled?: boolean;
  datatestid?: string;
  items: DropDownItem[];
  renderItem: (any) => React.ReactNode;
  onSelect: (any) => void;
}

const DropdownButton = ({
  title,
  items,
  renderItem,
  onSelect,
}: DropdownButtonProps) => {
  return (
    <div className="tw-relative">
      <Menu>
        <MenuButton className="button-light">
          <div className="tw-flex tw-items-center">
            <div className="tw-mr-1 tw-flex">{title}</div>
            <span className="caret"></span>
          </div>
          <span></span>
        </MenuButton>
        <MenuItems className="tw-absolute tw-left-0 tw-top-full tw-z-10 tw-mt-1 tw-overflow-hidden tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-shadow">
          {items.map((item, id) => (
            <MenuItem key={id} disabled={item.disabled}>
              {() => (
                <a
                  className="tw-cursor-pointer tw-no-underline hover:tw-no-underline"
                  onClick={() => onSelect(item.eventKey)}
                >
                  <div className="tw-w-60 tw-p-2 hover:tw-bg-gray-200">
                    {renderItem(item.eventKey)}
                  </div>
                </a>
              )}
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
    </div>
  );
};

export default DropdownButton;

import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';

import TextInput from 'hookForm/TextInput';
import resolver from 'hookForm/resolver';

import { Alert, Button, Modal } from 'shared/bootstrap';
import { validateEmail, validatePresence } from 'shared/utils/validation';
import * as I18n from 'shared/utils/I18n';

const ns = 'charlie.users.form';

interface Props {
  createStatus: string;
  createUser: (values: any) => Promise<void>;
  onHide: () => void;
  show: boolean;
}

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
}

const validate = ({ first_name, last_name, email }: FormValues) => ({
  email: validatePresence(email) || validateEmail(email),
  first_name: validatePresence(first_name),
  last_name: validatePresence(last_name),
});

const UserFormModal = ({ createStatus, createUser, onHide, show }: Props) => {
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: resolver(validate),
  });

  const submit = handleSubmit(createUser);

  const firstNameInputRef = useRef<HTMLInputElement>(null);

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="c-user-dialog"
      initialFocus={firstNameInputRef}
    >
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
      </Modal.Header>
      <form onSubmit={submit} autoComplete="off" noValidate>
        <Modal.Body>
          <p className="tw-mb-6">{I18n.nt(ns, 'description_html')}</p>
          {createStatus === 'error' && (
            <Alert type="danger">{I18n.ut('common.server_error')}</Alert>
          )}
          <TextInput
            autoFocus
            control={control}
            inputRef={firstNameInputRef}
            label={I18n.label('user', 'first_name')}
            name="first_name"
          />
          <TextInput
            control={control}
            label={I18n.label('user', 'last_name')}
            name="last_name"
          />
          <TextInput
            control={control}
            label={I18n.label('user', 'email')}
            name="email"
            type="email"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="button-primary">
            {I18n.nt(ns, 'submit')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UserFormModal;

import React from 'react';
import TextInput from 'hookForm/TextInput';
import { label, nt } from 'shared/utils/I18n';
import Button from 'shared/components/tailwind/Button';
import GuestModal from 'shared/components/tailwind/GuestModal';
import useOtp, { OtpStatus } from './useOtp';

const ns = 'app.login.otp';

const getOtpErrorMessage = (status: OtpStatus) => {
  switch (status) {
    case 'error':
    case 'invalidIntermediaryOtp':
      return nt('common', 'server_error');
    case 'expiredOtp':
      return nt(ns, 'expired_otp');
    case 'invalidOtp':
      return nt(ns, 'invalid_otp');
    case 'tooManyOtpAttempts':
      return nt(ns, 'too_many_attempts');
    default:
      return null;
  }
};

interface Props {
  intermediaryToken: string;
  lastThreeNumbers: string;
}

const Otp = ({ intermediaryToken, lastThreeNumbers }: Props) => {
  const { control, submit, status } = useOtp(intermediaryToken);

  const otpErrorMessage = getOtpErrorMessage(status);

  return (
    <GuestModal>
      <div className="tw-flex tw-flex-col tw-gap-8">
        <h3 className="tw-mb-0 tw-text-lg md:tw-text-bootstrap-h3">
          {nt(ns, 'title')}
        </h3>
        <div>{nt(ns, 'description', {}, { last_three: lastThreeNumbers })}</div>
        <form onSubmit={submit} className="tw-flex tw-flex-col tw-gap-6">
          <TextInput
            autoFocus
            control={control}
            label={label('user', 'two_factor_token')}
            name="otp"
            includeLayoutClass={false}
          />
          <div>
            {otpErrorMessage && (
              <p className="tw-text-red-dark">{otpErrorMessage}</p>
            )}
            <Button
              className="button-primary"
              disabled={status === 'submitting'}
              type="submit"
            >
              {nt(ns, 'continue')}
            </Button>
          </div>
        </form>
        <div className="tw-text-gray-600">{nt(ns, 'having_trouble')}</div>
      </div>
    </GuestModal>
  );
};

export default Otp;

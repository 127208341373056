import React from 'react';
import { Button } from 'shared/bootstrap';
import { UltimateBeneficialOwner } from 'types';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import { attribute } from 'shared/utils/I18n';
import { nt } from 'Onboarding/components/utils';

const t = nt('signup.onboarding.ultimate_beneficial_owners');

const InvitationMailStatus = ({
  ubo,
  onInvite,
}: {
  ubo: UltimateBeneficialOwner;
  onInvite: (ubo: UltimateBeneficialOwner) => void;
}) => {
  switch (ubo.onfidoIdentificationStatus) {
    case 'invitation_sent':
      return (
        <p className="text-warning tw-flex tw-items-center tw-gap-1">
          <span className="icon icon-clock tw-text-xl" />
          {t('modal.mail_status.sent')} -
          <a
            className="text-warning tw-cursor-pointer tw-underline"
            onClick={() => onInvite(ubo)}
          >
            {t('modal.mail_status.re_invite')}
          </a>
        </p>
      );

    case 'done':
      return (
        <p className="text-success tw-flex tw-items-center tw-gap-1">
          <span className="icon icon-checkmark tw-text-xl" />
          {t('modal.mail_status.done')}
        </p>
      );

    default:
      return (
        <p className="text-warning tw-flex tw-items-center tw-gap-1">
          <span className="icon icon-alert tw-text-xl" />
          {t('modal.mail_status.not_sent')}
        </p>
      );
  }
};

interface UboListProps {
  ubos: UltimateBeneficialOwner[];
  loadStatus: string;
  hideInvite?: boolean;
  onEdit: (ubo: UltimateBeneficialOwner) => void;
  onInvite: (ubo: UltimateBeneficialOwner) => void;
}

export default ({
  ubos,
  loadStatus,
  hideInvite,
  onEdit,
  onInvite,
}: UboListProps) => {
  if (loadStatus !== 'loaded') {
    return <LoadingAnimation />;
  }

  if (!ubos.length) {
    return null;
  }

  const showInviteFunctionality = !hideInvite;

  return (
    <table className="UboList">
      <tbody>
        {ubos.map((ubo) => (
          <tr key={ubo.id}>
            <td>
              <strong>
                {ubo.firstName} {ubo.lastName}
              </strong>
              <p>
                {ubo.role === 'other'
                  ? ubo.otherRole
                  : attribute('ultimate_beneficial_owner/role', ubo.role)}
              </p>
              {showInviteFunctionality && (
                <InvitationMailStatus ubo={ubo} onInvite={onInvite} />
              )}
            </td>
            <td>
              <div className="tw-flex tw-flex-col tw-items-end tw-gap-2">
                {showInviteFunctionality &&
                  ubo.onfidoIdentificationStatus === 'not_started' && (
                    <Button
                      className="button-primary-inverted button-small tw-w-[104px]"
                      onClick={() => onInvite(ubo)}
                    >
                      {t('modal.invite')}
                    </Button>
                  )}
                <Button
                  className="button-light button-small tw-w-[104px]"
                  onClick={() => onEdit(ubo)}
                >
                  {t('modal.edit')}
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

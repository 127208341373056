import React, { useState } from 'react';
import { Modal, Well } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { formatIban } from 'shared/utils/Helpers';
import { usesClientBankAccount } from 'charlie/model';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import LiableInfo from 'charlie/components/shared/LiableInfo';
import useRepayment from './useRepayment';
import IdealButton from './IdealButton';

const ns = 'charlie.frontpage.repayment_modal';

type ModalState = 'initial' | 'manual' | 'ideal';

interface Props {
  parentPath: string;
}

const RepaymentModal = ({ parentPath }: Props) => {
  const {
    florynIban,
    transferReference,
    close,
    loadingStatus,
    initiatePayment,
    seller,
  } = useRepayment(parentPath);

  const [status, setStatus] = useState<ModalState>('initial');

  return (
    <Modal show onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'pay_back_arrear')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tw-flex tw-flex-col tw-gap-5">
          {loadingStatus === 'error' && (
            <div className="tw-mb-6 tw-border tw-border-[#EBCCD1] tw-bg-[#F2DEDD] tw-p-4 tw-text-[#AA4442]">
              {I18n.nt(ns, 'ideal_error')}
            </div>
          )}
          {status === 'initial' && (
            <>
              <LiableInfo />
              <div>{I18n.nt(ns, 'ideal_info')}</div>
              <div className="tw-space-x-1">
                <span>{I18n.nt(ns, 'warning_message_part1')}</span>
                <a
                  className="tw-font-bold tw-text-black tw-underline hover:tw-cursor-pointer"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setStatus('manual');
                  }}
                >
                  {I18n.nt(ns, 'warning_message_part2')}
                </a>
                <span>{I18n.nt(ns, 'warning_message_part3')}</span>
              </div>
            </>
          )}
          {status === 'manual' && (
            <>
              <LiableInfo />
              <div>{I18n.nt(ns, 'warning_message_full')}</div>
              <Well>
                <dl className="tw-mb-0 tw-flex tw-flex-wrap">
                  <dt className="tw-w-1/3 tw-font-normal sm:tw-w-1/4">
                    {I18n.nt(ns, 'iban')}:
                  </dt>
                  <dd className="tw-w-2/3 tw-font-bold sm:tw-w-3/4">
                    {formatIban(
                      usesClientBankAccount(seller)
                        ? seller.clientBankAccount
                        : florynIban
                    )}
                  </dd>
                  <dt className="tw-w-1/3 tw-font-normal sm:tw-w-1/4">
                    {I18n.nt(ns, 'to_the_attention_of')}:
                  </dt>
                  <dd className="tw-w-2/3 tw-font-bold sm:tw-w-3/4">
                    {usesClientBankAccount(seller)
                      ? seller.companyName
                      : I18n.nt(ns, 'foundation_floryn_payments')}
                  </dd>
                  <dt className="tw-w-1/3 tw-font-normal sm:tw-w-1/4">
                    {I18n.nt(ns, 'description')}:
                  </dt>
                  <dd className="tw-w-2/3 tw-font-bold sm:tw-w-3/4">
                    {I18n.nt(ns, 'reference', { reference: transferReference })}
                  </dd>
                </dl>
              </Well>
              <div>
                <div>{I18n.nt(ns, 'pay_manual_description')}</div>
              </div>
            </>
          )}
          {loadingStatus === 'loading' && <LoadingAnimation />}
          {(status === 'initial' || status === 'manual') && (
            <IdealButton
              disabled={loadingStatus === 'loading'}
              initiatePayment={initiatePayment}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RepaymentModal;

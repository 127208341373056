import React, { ReactNode } from 'react';
import { Modal, Button } from 'shared/bootstrap';
import { translate } from 'shared/utils/I18n';

const t = translate('review_wizard.modal');

interface Props {
  children: ReactNode;
  show: boolean;
  onHide: () => void;
  title: string;
}

const ReviewWizardModal = ({ title, show, onHide, children }: Props) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>
      <Button className="button-light" onClick={onHide}>
        {t('close')}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ReviewWizardModal;

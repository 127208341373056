import { useState } from 'react';
import { useCurrentSeller } from 'namespaces/shared/hooks';

const useServicePage = () => {
  const [action, setAction] = useState<string>('');

  const resetAction = () => {
    setAction('');
  };

  const seller = useCurrentSeller();

  return {
    seller,
    action,
    setAction,
    resetAction,
  };
};

export default useServicePage;

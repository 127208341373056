import React from 'react';
import { Route } from 'react-router-dom';

import Action, { ActionButton, ActionClosingModal } from '../Action';
import { OnboardingActionStatus } from 'types';
import { nt } from '../utils';
import ReferralDialog from './ReferralDialog';

const t = nt('signup.onboarding.referral');

interface Props {
  basePath: string;
  status: OnboardingActionStatus;
}

export default ({ basePath, status }: Props) => {
  const referralPath = `${basePath}/hoe-heb-je-ons-gevonden`;

  const Button = () => (
    <ActionButton to={referralPath}>{t('button')}</ActionButton>
  );

  const showButton = status === 'Todo' || status === 'Pending';

  return (
    <>
      <Action
        button={showButton ? Button : null}
        description={t('description')}
        path={referralPath}
        status={status}
        title={t('title')}
      />
      <Route path={referralPath}>
        <ActionClosingModal basePath={basePath}>
          <ReferralDialog />
        </ActionClosingModal>
      </Route>
    </>
  );
};

import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Container from './Container';

interface Props {
  aboveMainChildren?: ReactNode;
  aboveSidebarChildren?: ReactNode;
  children?: ReactNode;
  includePaddingInSmallSidebar?: boolean;
  includeTopMargin?: boolean;
  sidebarChildren?: ReactNode;
  sidebarClassName?: string;
}

const ContentWithSidebar = ({
  aboveMainChildren,
  aboveSidebarChildren,
  children,
  includeTopMargin = true,
  sidebarChildren,
  sidebarClassName = 'tw-bg-gray-100',
}: Props) => (
  <>
    <Container>
      <div className={classNames(includeTopMargin && 'tw-mt-6 md:tw-mt-20')}>
        {aboveMainChildren}
      </div>
      <div className="tw-flex tw-flex-col md:tw-flex-row">
        <div className="tw-pb-8 md:tw-w-2/3 md:tw-pr-8">{children}</div>
        <div className="tw-hidden tw-flex-col tw-gap-4 md:tw-flex md:tw-w-1/3">
          {aboveSidebarChildren}
          <div className={sidebarClassName}>{sidebarChildren}</div>
        </div>
      </div>
    </Container>
    <div className={`md:tw-hidden ${sidebarClassName}`}>
      <Container>{sidebarChildren}</Container>
    </div>
  </>
);

export default ContentWithSidebar;

/* Check that no seller is logged in. Otherwise redirect to:
 * The redirect query parameter if it exists
 * Otherwise the frontpage.
 */
import React from 'react';
import qs from 'qs';
import { isString } from 'lodash';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { Location } from 'history';

import { currentSellerSelector } from 'shared/selectors';
import useFrontpageRoute from './useFrontpageRoute';

const parseRedirectUrl = (location: Location) => {
  const match = qs.parse(location.search.substring(1)).redirect;

  if (isString(match)) {
    return match;
  } else {
    return undefined;
  }
};

interface Props {
  children: React.ReactNode;
  supportRedirectParam?: boolean;
}

const RequireAuthGuestOnly = ({ children, supportRedirectParam }: Props) => {
  const location = useLocation();
  const seller = useSelector(currentSellerSelector);
  const getFrontpageRoute = useFrontpageRoute();

  const getNextUrl = () => {
    const redirectUrl = parseRedirectUrl(location);

    if (supportRedirectParam && redirectUrl) {
      return redirectUrl;
    } else {
      return getFrontpageRoute(false);
    }
  };

  if (!seller) {
    return children;
  } else {
    return <Redirect to={getNextUrl()} />;
  }
};

export default RequireAuthGuestOnly;

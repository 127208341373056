import React from 'react';
import MediumSignupProgressLine from './MediumSignupProgressLine';
import useSignupProgress from './useSignupProgress';

const MediumSignupProgress = () => {
  const { currentIndex, duration, stepsArray, titles } = useSignupProgress();

  return (
    <div
      className={`tw-mb-4 tw-mt-8 tw-grid tw-grid-cols-4 tw-justify-items-center tw-gap-y-3`}
    >
      {stepsArray.map((i) => (
        <div className="tw-px-1" key={i}>
          {titles[i - 1]}
        </div>
      ))}
      {stepsArray.map((i) => (
        <div
          className="tx-mx-1 tw-mb-1 tw-bg-gray-100 tw-px-6 tw-py-1 tw-text-sm tw-font-bold tw-text-gray-500"
          key={i}
        >
          {duration}
        </div>
      ))}
      {stepsArray.map((i) => (
        <MediumSignupProgressLine
          key={i}
          leftDone={currentIndex >= i}
          rightDone={currentIndex > i}
          stepIndex={i}
        />
      ))}
    </div>
  );
};

export default MediumSignupProgress;

import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { CUSTOMER_APP_ROOT, INVOICES } from 'app/routes';
import { translate } from 'shared/utils/I18n';
import ReviewStatusMessage from 'shared/components/ReviewMessages/ReviewStatusMessage';
import Feedback from 'shared/components/Consent/Feedback';

const t = translate('review_wizard.layout');

interface Props {
  children?: ReactNode;
  isAlfa: boolean;
}

const Layout = ({ children, isAlfa }: Props) => (
  <div className="rw-container">
    <div className="row">
      <div className="col-md-8 col-md-offset-2 rw-space-y-32">
        <div className="rw-space-y-16">
          <Link
            to={isAlfa ? INVOICES : CUSTOMER_APP_ROOT}
            className="button-light"
          >
            <div className="icon icon-arrow-left2 tw-mr-2 tw-inline tw-align-sub tw-text-[18px]" />
            {t('back')}
          </Link>
          <h2>
            {t('title')}
            <span className="icon icon-lock tw-ml-2 tw-text-floryn-green" />
          </h2>
          <Feedback t={t} />
          <ReviewStatusMessage hideInstructionsLink />
        </div>
        <div className="rw-pb-32">{children}</div>
      </div>
    </div>
  </div>
);

export default Layout;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAppDispatch from 'shared/hooks/useAppDispatch';

import { getQueryParam } from 'shared/utils/Helpers';
import {
  loadStatus,
  selectConnected,
  selectConnectionStatus,
  selectHaveSellersWithDivisions,
  selectLoadingStatus,
  selectUsername,
} from './exactOnlineConnectionSlice';

const useExactOnlineConnection = () => {
  const connected = useSelector(selectConnected);
  const connectionStatus = useSelector(selectConnectionStatus);
  const someHaveDivisions = useSelector(selectHaveSellersWithDivisions);
  const loadingStatus = useSelector(selectLoadingStatus);
  const dispatch = useAppDispatch();
  const username = useSelector(selectUsername);

  const callbackStatus = getQueryParam(location.search, 'status');

  useEffect(() => {
    dispatch(loadStatus());
  }, []);

  return {
    callbackStatus,
    connected,
    connectionStatus,
    loadingStatus,
    someHaveDivisions,
    username,
  };
};

export default useExactOnlineConnection;

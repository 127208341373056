import React from 'react';
import { OnboardingActionStatus } from 'types';
import Action, { ActionButton } from '../Action';
import { nt } from '../utils';

const t = nt('signup.onboarding.deliveries.action');

interface Props {
  basePath: string;
  status: OnboardingActionStatus;
}

const DeliveriesAction = ({ basePath, status }: Props) => {
  const deliveriesPath = `${basePath}/deliveries`;
  const Button = () => (
    <ActionButton to={deliveriesPath}>{t('button')}</ActionButton>
  );

  return (
    <Action
      button={Button}
      description={t('description')}
      path={deliveriesPath}
      status={status}
      title={t('title')}
    />
  );
};

export default DeliveriesAction;

import React, { ReactNode } from 'react';
import Container from './Container';

interface Props {
  children?: ReactNode;
}

const GuestModal = ({ children }: Props) => (
  <Container>
    <div className="tw-mt-8 md:tw-mx-auto md:tw-mt-12 md:tw-w-[50%] md:tw-border md:tw-border-gray-400 md:tw-p-8 md:tw-shadow-guest-modal">
      {children}
    </div>
  </Container>
);

export default GuestModal;

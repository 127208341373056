import React from 'react';

import useInvoiceForm, { FormValues } from './useInvoiceForm';
import { Alert } from 'shared/bootstrap';
import Button from 'shared/components/tailwind/Button';
import { numberFormat } from 'shared/utils/Helpers';
import { isExactInvoice } from 'shared/utils/invoice';
import * as I18n from 'shared/utils/I18n';

import AttachmentsInput from 'hookForm/AttachmentsInput';

import CompanyInput from 'hookForm/CompanyInput';
import ContactsInput from 'hookForm/ContactsInput';
import { ContactsInputProvider } from 'hookForm/ContactsInput/ContactsInputContext';
import CurrencyInput from 'hookForm/CurrencyInput';
import DatePickerInput from 'hookForm/DatePickerInput';
import GeneralErrors from 'hookForm/GeneralErrors';
import Icon from 'hookForm/Icon';
import TextInput from 'hookForm/TextInput';

import { Invoice, InvoiceContact, MinimalSeller, Seller } from 'types';
import { Control } from 'react-hook-form';

const ns = 'app.invoiceForm';

interface Props {
  seller: MinimalSeller | Seller;
}

interface TitleProps {
  invoice: Invoice;
}

const Title = ({ invoice }: TitleProps) => {
  if (invoice && isExactInvoice(invoice)) {
    return null;
  }

  return <h2>{I18n.nt(ns, 'title.uploadInvoice')}</h2>;
};

interface InvoiceFieldsProps {
  amount: string;
  calculateEndOfMonth: () => void;
  control: Control<FormValues>;
  gAmount: string;
  readOnly: boolean;
  useGAccount: boolean;
}

interface EndOfMonthProps {
  fn?: () => void;
  hint?: string;
  text?: string;
}

const InvoiceFields = ({
  amount,
  calculateEndOfMonth,
  control,
  gAmount,
  readOnly,
  useGAccount,
}: InvoiceFieldsProps) => {
  const endOfMonthProps = readOnly
    ? {}
    : ({
        fn: calculateEndOfMonth,
        hint: I18n.hint('invoice', 'payment_period_end_of_month_hint'),
        text: I18n.label('invoice', 'plus_end_of_month'),
      } as EndOfMonthProps);

  const ifAmount = Math.max(Number(amount) - Number(gAmount) || 0, 0);

  return (
    <div className="tw-grid tw-grid-cols-2 tw-gap-x-8">
      <div>
        <CurrencyInput
          autoFocus
          control={control}
          hint={
            useGAccount ? I18n.nt(ns, 'invoiceFields.amountHint') : undefined
          }
          label={I18n.nt(ns, 'invoiceFields.amount')}
          name="amount"
          readOnly={readOnly}
        />
      </div>
      <TextInput
        control={control}
        label={I18n.nt(ns, 'invoiceFields.reference')}
        name="reference"
        readOnly={readOnly}
      />
      <DatePickerInput
        control={control}
        hint={I18n.nt(ns, 'invoiceFields.dateSentHint')}
        label={I18n.nt(ns, 'invoiceFields.dateSent')}
        name="dateSent"
        readOnly={readOnly}
      />
      <TextInput
        control={control}
        label={I18n.nt(ns, 'invoiceFields.paymentPeriod')}
        name="paymentPeriod"
        type="number"
        readOnly={readOnly}
        action={endOfMonthProps}
      />
      {useGAccount && (
        <CurrencyInput
          control={control}
          label={I18n.nt(ns, 'invoiceFields.gAmount')}
          name="gAmount"
        />
      )}
      {useGAccount && (
        <div>
          <label className="tw-mb-2" htmlFor="ifAmount">
            Betaling aan Floryn
          </label>
          <div className="tw-flex">
            <Icon text="€" />
            <input
              className="form-control tw-rounded-bl-none tw-rounded-tl-none"
              disabled
              id="ifAmount"
              name="ifAmount"
              value={numberFormat(ifAmount)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

interface AttachmentsFieldsProps {
  control: Control<FormValues>;
  invoice: Invoice;
}

const AttachmentsFields = ({ control, invoice }: AttachmentsFieldsProps) => {
  return (
    <div className="tw-mb-8 tw-grid tw-gap-6">
      <h3 className="-tw-mb-2">{I18n.nt(ns, 'attachmentFields.title')}</h3>
      <AttachmentsInput
        allowMultipleFiles={false}
        control={control}
        description={I18n.nt(ns, 'attachmentFields.invoiceFileDescription')}
        includeLayoutClass={false}
        model="invoice"
        modelId={invoice?.id}
        name="invoiceFile"
        label={I18n.nt(ns, 'attachmentFields.invoiceFile')}
      />
      <AttachmentsInput
        allowMultipleFiles={true}
        control={control}
        description={I18n.nt(ns, 'attachmentFields.supportingFilesDescription')}
        includeLayoutClass={false}
        model="invoice"
        modelId={invoice?.id}
        name="supportingFiles"
        label={I18n.nt(ns, 'attachmentFields.supportingFiles')}
      />
    </div>
  );
};

interface DebtorFieldsProps {
  allDebtorContacts: InvoiceContact[] | null;
  control: Control<FormValues>;
  invoiceId: number;
  isDebtorValid: boolean;
  readOnly: boolean;
}

const DebtorFields = ({
  allDebtorContacts,
  control,
  invoiceId,
  isDebtorValid,
  readOnly,
}: DebtorFieldsProps) => {
  return (
    <div className="fieldset lastfieldset">
      <h3>{I18n.nt(ns, 'debtorFields.title')}</h3>
      <CompanyInput
        control={control}
        label={I18n.label('invoice', 'debtor')}
        name="debtor"
        readOnly={readOnly}
      />
      {allDebtorContacts && invoiceId && isDebtorValid && (
        <ContactsInput
          allContacts={allDebtorContacts}
          control={control}
          hint={I18n.nt(ns, 'debtorFields.contactsDescription')}
          label={I18n.nt(ns, 'debtorFields.contacts')}
          name="invoiceContacts"
        />
      )}
    </div>
  );
};

const InvoiceForm = ({ seller }: Props) => {
  const {
    allDebtorContacts,
    amount,
    calculateEndOfMonth,
    control,
    gAmount,
    invoice,
    isDebtorValid,
    isValid,
    serverErrorMessages,
    submit,
    submitEnabled,
  } = useInvoiceForm();

  const isExactOnlineInvoice = invoice && isExactInvoice(invoice);

  return (
    <div>
      <Title invoice={invoice} />
      <GeneralErrors generalErrors={serverErrorMessages} />
      <form autoComplete="off">
        <InvoiceFields
          amount={amount}
          calculateEndOfMonth={calculateEndOfMonth}
          control={control}
          gAmount={gAmount}
          readOnly={isExactOnlineInvoice}
          useGAccount={seller.useGAccount}
        />
        <AttachmentsFields control={control} invoice={invoice} />
        {isExactOnlineInvoice && !isValid && (
          <Alert type="warning">
            <strong>{I18n.nt(ns, 'watchOut')}</strong>{' '}
            {I18n.nt(ns, 'exactMissingData')}
          </Alert>
        )}
        <DebtorFields
          allDebtorContacts={allDebtorContacts}
          control={control}
          invoiceId={invoice?.id}
          isDebtorValid={isDebtorValid}
          readOnly={isExactOnlineInvoice}
        />
        <Button
          className="button-primary"
          disabled={!submitEnabled}
          onClick={submit}
        >
          {I18n.nt('common', 'goForward')}
        </Button>
      </form>
    </div>
  );
};

const InvoiceFormWithProvider = ({ seller }: Props) => (
  <ContactsInputProvider>
    <InvoiceForm seller={seller} />
  </ContactsInputProvider>
);

export default InvoiceFormWithProvider;

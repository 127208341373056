import React from 'react';
import { Button, Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';

const ns = 'app.partner_signup.bank_statements_step.more_information';

interface Props {
  show: boolean;
  closeModal: () => void;
  companyName: string;
  FinancialDashboardDaysAvailable: number;
}

const MoreInformationModal = ({
  show,
  closeModal,
  companyName,
  FinancialDashboardDaysAvailable,
}: Props) => (
  <>
    <Modal className="MoreInformationModal" show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {I18n.nt(ns, 'body_html', {
          days_available: FinancialDashboardDaysAvailable,
          partner: companyName,
        })}{' '}
      </Modal.Body>
      <Modal.Footer>
        <Button className="button-primary" onClick={closeModal}>
          {I18n.nt(ns, 'continue')}
        </Button>
      </Modal.Footer>
    </Modal>
  </>
);

export default MoreInformationModal;

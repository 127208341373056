import React from 'react';
import { Route, useHistory } from 'react-router-dom';

import { OnboardingActionStatus } from 'types';
import Action, { ActionButton, ActionClosingModal } from '../Action';
import { nt } from '../utils';
import Dialog from './Dialog';

const t = nt('signup.onboarding.property_taxation');

interface Props {
  basePath: string;
  status: OnboardingActionStatus;
}

const PropertyTaxation = ({ basePath, status }: Props) => {
  const history = useHistory();
  const closeModal = () => history.push(basePath);
  const propertyTaxationPath = `${basePath}/bedrijfspand`;
  const Button = () => (
    <ActionButton to={propertyTaxationPath}>{t('button')}</ActionButton>
  );

  return (
    <>
      <Action
        button={Button}
        description={t('description')}
        path={propertyTaxationPath}
        status={status}
        title={t('title')}
      />
      <Route path={propertyTaxationPath}>
        <ActionClosingModal basePath={basePath}>
          <Dialog onHide={closeModal} t={t} />
        </ActionClosingModal>
      </Route>
    </>
  );
};

export default PropertyTaxation;

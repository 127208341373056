import React from 'react';

import BankStatementInfo from 'shared/components/UploadBankStatements/BankStatementInfo';
import Button from 'shared/components/tailwind/Button';
import * as I18n from 'shared/utils/I18n';
import DontWorryMessage from 'shared/components/DontWorryMessage';
import { bankStatementStartDate } from 'shared/utils/bankStatementStartDate';
import RequiredIbans from './RequiredIbans';
import UploadStatusInfo from 'shared/components/UploadBankStatements/UploadStatusInfo';
import UploadStatementsWarning from 'shared/components/UploadBankStatements/UploadStatementsWarning';
import BankstatementUploadInput from 'hookForm/BankstatementUploadInput';
import useDocumentUpload from './useDocumentUpload';

const ns = 'app.partner_signup.bank_statements_step.document_upload';

interface Props {
  handleBankConnectionLink: () => void;
}

const Form = ({ handleBankConnectionLink }: Props) => {
  const {
    attachmentsWithWarning,
    control,
    removeInvalidAttachments,
    review,
    saving,
    seller,
    setUploading,
    submit,
    warnings,
  } = useDocumentUpload();

  return (
    <div className="PartnerSignupBankStatementUpload">
      <div className="fieldset tw-mb-8 tw-border-2 tw-p-6 tw-shadow-md">
        <div className="tw-flex tw-flex-row">
          <div className="tw-mb-6 tw-grow tw-text-right tw-text-sm">
            <a
              className="tw-font-bold tw-leading-none tw-text-gray-600"
              onClick={handleBankConnectionLink}
              href="#"
            >
              {I18n.nt(ns, 'bank_connection_link')}
            </a>
            <p className="tw-text-floryn-green">
              {I18n.nt(ns, 'bank_connection_endorsement_html')}
            </p>
          </div>
          <button
            type="button"
            className="close tw-ml-2 tw-flex tw-flex-col tw-items-start"
            onClick={handleBankConnectionLink}
          >
            <span aria-hidden="true" className="tw--mt-1 tw-leading-none">
              ×
            </span>
            <span className="sr-only">Close</span>
          </button>
        </div>
        <h4>{I18n.attribute('seller', 'bankStatementAttachments')}</h4>
        <p>
          {I18n.nt(ns, 'download_bank_statements_start', {
            date: bankStatementStartDate(new Date()),
          })}
          <br />
          <a
            href="https://www.floryn.com/nl/bankafschriften-downloaden"
            rel="noopener noreferrer"
            target="_blank"
            className="tw-underline"
          >
            {I18n.nt(ns, 'download_bank_statements_hint')}
          </a>
        </p>

        <RequiredIbans />
        <BankstatementUploadInput
          control={control}
          label={I18n.attribute('seller', 'bankStatementAttachments')}
          model="seller"
          modelId={seller.id}
          name="bankStatementAttachments"
          readOnly={saving}
          setUploading={setUploading}
        />

        <UploadStatusInfo
          bankStatementCount={review.bankStatementAttachments.length}
          saving={saving}
          warnings={warnings}
        />

        <UploadStatementsWarning
          attachmentsWithWarning={attachmentsWithWarning}
          removeInvalidAttachments={removeInvalidAttachments}
          disabled={saving}
        />
      </div>
      <Button
        className="button-primary"
        disabled={saving}
        onClick={submit}
        type="submit"
      >
        {I18n.nt(ns, 'continue')}
      </Button>
      <DontWorryMessage />
      <BankStatementInfo icon="lock">
        {I18n.nt(ns, 'statements_info')}
      </BankStatementInfo>
    </div>
  );
};

export default Form;

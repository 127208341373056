import { useForm } from 'react-hook-form';
import { addDays, subDays, subMonths, parseISO } from 'date-fns';

import { dateFormat, formatISODate } from 'shared/utils/Helpers';
import { validateDate, validateInclusion } from 'shared/utils/validation';
import { useCurrentSeller, useResource } from 'shared/hooks';
import resolver from 'hookForm/resolver';

type ExportInfo = {
  lastImportDatetime: string;
};

interface FormData {
  format: 'csv' | 'mt940';
  period: {
    startDate: Date;
    endDate: Date;
  };
}

const useExportModal = () => {
  const seller = useCurrentSeller();

  const initialValues = {
    format: 'csv',
    period: {
      startDate: subMonths(new Date(), 1),
      endDate: subDays(new Date(), 1),
    },
  };
  const validate = ({ format, period }: FormData) => ({
    format: validateInclusion('csv', 'mt940')(format),
    period:
      validateDate(period.startDate, 'yyyy-MM-dd') ||
      validateDate(period.endDate, 'yyyy-MM-dd'),
  });
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<FormData>({
    defaultValues: initialValues as FormData,
    resolver: resolver(validate),
    mode: 'onBlur',
  });

  const response = useResource<ExportInfo>('/api/mutations/export_info');

  const lastImportDatetime =
    response.type === 'success' ? response.response.lastImportDatetime : null;

  const getTransactionsAvailableDate = () => {
    if (!lastImportDatetime) {
      return '';
    }
    return dateFormat(addDays(parseISO(lastImportDatetime), -1), 'long');
  };

  const submitForm = ({ format, period }) => {
    const startDateAsString = formatISODate(period.startDate);
    const endDateAsString = formatISODate(period.endDate);

    const path = `/api/mutations/export?start_date=${startDateAsString}&end_date=${endDateAsString}&export_format=${format}&seller_id=${seller.id}`;

    location.href = path;
  };

  return {
    canExportMt940: seller.mt940Export,
    control,
    exportInfoStatus: response.type,
    isValid,
    isSubmitting,
    transactionsAvailableDate: getTransactionsAvailableDate(),
    submitForm: handleSubmit(submitForm),
  };
};

export default useExportModal;

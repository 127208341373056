import { schema } from 'normalizr';

export const Contract = new schema.Entity('contracts');
export const CustomerAccount = new schema.Entity('customerAccounts');
export const Invoice = new schema.Entity('invoices');
export const InvoiceContact = new schema.Entity('invoiceContacts');
export const Seller = new schema.Entity('sellers');
export const ImprovedContract = new schema.Entity('improvedContracts');

Seller.define({
  contract: Contract,
  improvedContracts: [ImprovedContract],
});

Invoice.define({
  seller: Seller,
  invoiceContacts: [InvoiceContact],
});

CustomerAccount.define({
  sellers: [Seller],
});

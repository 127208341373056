import React from 'react';
import * as I18n from 'shared/utils/I18n';
import { Row } from 'namespaces/shared/bootstrap';
import ChangeEmailRequest from './ChangeEmailRequest';
import ChangeFlorynInvoiceCcEmail from './ChangeFlorynInvoiceCcEmail';
import ChangePasswordRequest from 'app/containers/profile/ChangePasswordRequest';
import TwoFactorSettings from './TwoFactorSettings';
import { useCurrentSeller, useUser } from 'namespaces/shared/hooks';
import ChangeAddressRequest from '../ChangeAddressRequest';

const ns = 'app.profilePage.servicePage';

const ServicePage = () => {
  const user = useUser();
  const seller = useCurrentSeller();
  const showServiceActions = Boolean(seller.aBTests.showServiceActions);

  return (
    <>
      <div className="c-service-page">
        {showServiceActions && (
          <>
            <h3 className="tw-mt-0">{I18n.nt(ns, 'title')}</h3>
            <p>{I18n.nt(ns, 'intro_html')}</p>
            <hr />
          </>
        )}
        <div className="service-page-section two-factor">
          <h3>{I18n.nt('app.profilePage', 'settings')}</h3>
          <Row>
            <TwoFactorSettings
              enabled={user.twoFactorEnabled}
              phone={user.phone}
            />
            <ChangeFlorynInvoiceCcEmail />
          </Row>
        </div>
        <div className="service-page-section">
          <Row>
            <ChangePasswordRequest />
            <ChangeEmailRequest />
          </Row>
          <Row>{showServiceActions && <ChangeAddressRequest />}</Row>
        </div>
      </div>
    </>
  );
};

export default ServicePage;

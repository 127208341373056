import React from 'react';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import Button from 'shared/components/tailwind/Button';
import { useCurrentSeller } from 'shared/hooks';
import ExtraPayOffModal from 'charlie/components/ExtraPayOffModal';
import IncreaseLimitModal from 'charlie/components/IncreaseLimitModal';
import SearchForm from 'charlie/components/SearchForm';
import * as I18n from 'shared/utils/I18n';
import { isCancelled } from 'models/Seller';

const ns = 'charlie.frontpage.actions';

const LoanActions = () => {
  const seller = useCurrentSeller();
  const increaseLimitButtonVisible = !isCancelled(seller);

  const history = useHistory();
  const match = useRouteMatch();

  return (
    <div className="c-actions">
      <div className="c-actions-links">
        <Button
          className="button-secondary tw-flex-1 xs:tw-grow-0"
          onClick={() => history.push(`${match.path}/extra-aflossen`)}
        >
          {I18n.nt(ns, 'extra_pay_off')}
        </Button>
        {increaseLimitButtonVisible && (
          <Button
            className="button-light tw-flex-1 xs:tw-grow-0"
            onClick={() => history.push(`${match.path}/limiet-verhogen`)}
          >
            {I18n.nt(ns, 'increase')}
          </Button>
        )}
        <Route
          path={`${match.path}/extra-aflossen`}
          render={(props) => (
            <ExtraPayOffModal parentPath={match.path} {...props} />
          )}
        />
        {increaseLimitButtonVisible && (
          <Route path={`${match.path}/limiet-verhogen`}>
            <IncreaseLimitModal parentPath={match.path} />
          </Route>
        )}
      </div>
      <SearchForm sellerId={seller.id} />
    </div>
  );
};

export default LoanActions;

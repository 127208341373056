import React from 'react';
import { Button, Modal } from 'shared/bootstrap';
import { nt } from 'Onboarding/components/utils';

const t = nt('signup.onboarding.ultimate_beneficial_owners.delete_modal');

interface Props {
  isDeleting: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  show: boolean;
}

export default ({ isDeleting, onCancel, onConfirm, show }: Props) => (
  <Modal className="ConfirmDeleteModal" show={show} onHide={onCancel}>
    <Modal.Header closeButton>
      <Modal.Title>{t('title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{t('confirmation_text')}</Modal.Body>
    <Modal.Footer>
      <Button onClick={onCancel}>{t('cancel')}</Button>
      <Button
        className="button-danger"
        onClick={onConfirm}
        disabled={isDeleting}
      >
        {t('confirm')}
      </Button>
    </Modal.Footer>
  </Modal>
);

import React, { ReactNode } from 'react';
import { Col, Row } from 'shared/bootstrap';

interface Props {
  children: ReactNode;
}

const Container = ({ children }: Props) => (
  <div className="PartnerSignup">
    <Row>
      <Col md={8} mdOffset={2}>
        <div className="tw-mb-5 md:tw-border md:tw-border-gray-300 md:tw-shadow-[0_0_6px_4px_rgba(0,0,0,0.07)]">
          <div className="md:tw-p-8">{children}</div>
        </div>
      </Col>
    </Row>
  </div>
);

export default Container;

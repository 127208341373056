import React from 'react';
import { Well } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { dateFormat, toEuro } from 'shared/utils/Helpers';
import useWithdrawStatus from './useWithdrawStatus';
import InformationCircleSvg from 'svg/information_circle_2.svg';
import CheckCircleSvg from 'svg/check_circle.svg';

interface Props {
  parentPath: string;
  summaryProperties: {
    amount: number;
    closingCommissionAmount: number;
    debtCollectionDate: Date | undefined;
    withdrawalFeeRate: number;
    pendingCurrentBalance: number;
    isInstantWithdrawal: boolean;
  };
}

const ns = 'charlie.frontpage.withdraw_dialog.status';

const WithdrawStatus = ({ parentPath, summaryProperties }: Props) => {
  const {
    automaticWithdrawalVisible,
    linkToAutomaticWithdrawal,
    getCurrentHour,
    newDebtCollectionAmount,
  } = useWithdrawStatus(parentPath);

  const withdrawalFee =
    summaryProperties.amount * summaryProperties.withdrawalFeeRate;
  const amountMinusCosts =
    summaryProperties.amount -
    withdrawalFee -
    summaryProperties.closingCommissionAmount;

  const getSuccessMessage = () => {
    if (summaryProperties.isInstantWithdrawal) {
      return I18n.nt(ns, 'success_message_instant');
    }

    return getCurrentHour() < 13
      ? I18n.nt(ns, 'success_message_same_day')
      : I18n.nt(ns, 'success_message_next_day');
  };

  const getTransferAmountMessage = () => {
    if (
      summaryProperties.withdrawalFeeRate > 0 &&
      summaryProperties.closingCommissionAmount > 0
    ) {
      return I18n.nt(
        ns,
        'transfer_amount_withdrawal_fee_and_closing_commission_html',
        {
          amount: toEuro(amountMinusCosts),
          closingCommission: toEuro(summaryProperties.closingCommissionAmount),
          withdrawalFee: toEuro(withdrawalFee),
        }
      );
    }

    if (summaryProperties.withdrawalFeeRate > 0) {
      return I18n.nt(ns, 'transfer_amount_withdrawal_fee_html', {
        amount: toEuro(amountMinusCosts),
        withdrawalFee: toEuro(withdrawalFee),
      });
    }

    if (summaryProperties.closingCommissionAmount > 0) {
      return I18n.nt(ns, 'transfer_amount_closing_commission_html', {
        amount: toEuro(amountMinusCosts),
        closingCommission: toEuro(summaryProperties.closingCommissionAmount),
      });
    }

    if (summaryProperties.isInstantWithdrawal) {
      return I18n.nt(ns, 'transfer_instant_amount_html', {
        amount: toEuro(amountMinusCosts),
      });
    }

    return I18n.nt(ns, 'transfer_amount_html', {
      amount: toEuro(amountMinusCosts),
    });
  };

  return (
    <>
      <div className="tw-justify-content-between tw-mb-4 tw-border tw-border-floryn-dark-green tw-bg-floryn-tint-green tw-p-4">
        <span className="tw-my-0 tw-flex tw-items-center tw-font-heading tw-text-4xl tw-font-bold tw-text-floryn-dark-green">
          {toEuro(amountMinusCosts)}
          <CheckCircleSvg className="tw-ml-auto tw-h-[40px] tw-w-[40px]" />
        </span>
      </div>
      <p className="tw-my-6">{getSuccessMessage()}</p>
      <Well>
        <h5 className="tw-mb-3 tw-mt-0">{I18n.nt(ns, 'facts_title')}</h5>
        <ul className="tw-list-unstyled tw-mb-0">
          <li className="tw-mb-2">{getTransferAmountMessage()}</li>
          <li className="tw-mb-2">
            {I18n.nt(ns, 'new_balance_html', {
              amount: toEuro(summaryProperties.pendingCurrentBalance),
            })}
          </li>
          <li>
            {I18n.nt(ns, 'weekly_amount_html', {
              amount: toEuro(newDebtCollectionAmount),
              date: dateFormat(summaryProperties.debtCollectionDate),
            })}
          </li>
        </ul>
      </Well>
      {automaticWithdrawalVisible && (
        <div className="tw-mt-4 tw-flex tw-items-center">
          <InformationCircleSvg className="tw-mr-2 tw-h-[20px] tw-w-[20px]" />
          <span>
            {I18n.nt(ns, 'auto_withdraw_hint_html')}
            <span
              onClick={linkToAutomaticWithdrawal}
              className="tw-cursor-pointer tw-text-green-500 tw-underline"
            >
              {I18n.nt(ns, 'auto_withdraw_link')}
            </span>
          </span>
        </div>
      )}
    </>
  );
};

export default WithdrawStatus;

import React, { SyntheticEvent, useEffect, useRef } from 'react';
import { Button, Modal } from 'shared/bootstrap';

import { nt } from '../utils';
import useReferralDialog from './useReferralDialog';
import CheckboxInput from 'hookForm/CheckboxInput';
import TextInput from 'hookForm/TextInput';
import GeneralErrors from 'hookForm/GeneralErrors';
import { useWatch } from 'react-hook-form';

const t = nt('signup.onboarding.referral');

const SubmitButton = ({
  referralSources,
  otherReferral,
  isSubmitting,
}: {
  referralSources: string[];
  otherReferral?: string;
  isSubmitting: boolean;
}) => (
  <Button
    type="submit"
    className="button-primary"
    disabled={
      isSubmitting ||
      referralSources.length === 0 ||
      (referralSources.includes('other') && !otherReferral)
    }
  >
    {t('submit')}
  </Button>
);

export default () => {
  const { control, isSubmitting, serverErrorMessages, submitForm, watch } =
    useReferralDialog();

  const submit = async (event: SyntheticEvent) => {
    event.preventDefault();
    await submitForm();
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const formValues = watch();
  const otherReferral = useWatch({ control, name: 'otherReferral' });

  const referralSources = Object.entries(formValues)
    .filter(([key, value]) => value === true && key !== 'other')
    .map(([key]) => key);

  if (otherReferral) {
    referralSources.push(otherReferral);
  }

  return (
    <form onSubmit={submit}>
      <Modal.Header closeButton>
        <Modal.Title>{t('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <GeneralErrors generalErrors={serverErrorMessages} />
          <p>{t('description_modal')}</p>
          <div className="tw-flex tw-space-x-24">
            <div className="tw-space-y-2">
              <CheckboxInput
                control={control}
                label={t('dialog.google_label')}
                name="google"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.instagram_label')}
                name="instagram"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.linkedin_label')}
                name="linkedin"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.radio_label')}
                name="radio"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.outdoor_ad_label')}
                name="outdoorAd"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.newspaper_magazine_label')}
                name="newspaperMagazine"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.tv_label')}
                name="tv"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.youtube_label')}
                name="youtube"
              />
            </div>
            <div className="tw-space-y-2">
              <CheckboxInput
                control={control}
                label={t('dialog.podcast_label')}
                name="podcast"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.email_label')}
                name="email"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.facebook_label')}
                name="facebook"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.event_label')}
                name="event"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.financial_advisor_label')}
                name="financialAdvisor"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.online_ad_label')}
                name="onlineAd"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.word_of_mouth_label')}
                name="wordOfMouth"
              />
              <CheckboxInput
                control={control}
                label={t('dialog.other_label')}
                name="other"
              />
            </div>
          </div>
          {formValues.other && (
            <TextInput
              autoFocus
              control={control}
              inputRef={inputRef}
              label=""
              name="otherReferral"
            />
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton
          isSubmitting={isSubmitting}
          referralSources={referralSources}
          otherReferral={otherReferral}
        />
      </Modal.Footer>
    </form>
  );
};

import React from 'react';
import { includes } from 'lodash';

import { Button } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';

const ns = 'app.invoices';

const getTooltip = (financingState) => {
  if (financingState === 'all') {
    return I18n.nt(ns, 'finance-filters.all');
  }

  return I18n.attribute('invoice', `financing_states.${financingState}`);
};

interface Props {
  filterState: string;
  financingState: string;
  onRequestFinancingClick: (e) => void;
  requestFinancingAllowed: boolean;
}

const FinancingIcon = ({
  filterState,
  financingState,
  onRequestFinancingClick,
  requestFinancingAllowed,
}: Props) => {
  const mustHideIcon =
    filterState === 'withdrawn' &&
    !includes(['financed', 'finished'], financingState);

  if (mustHideIcon) {
    return null;
  }

  if (requestFinancingAllowed) {
    return (
      <Button
        className="button-primary tw-rounded-md tw-p-1"
        title={I18n.nt(ns, 'financing-status.not_requested')}
        onClick={onRequestFinancingClick}
      >
        <span className="icon-financing-not-requested" />
      </Button>
    );
  }

  return (
    <span
      className={`financing icon-financing-${financingState}`}
      title={getTooltip(financingState)}
    />
  );
};
export default FinancingIcon;

import React from 'react';
import { Well } from 'shared/bootstrap';
import { toEuro } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import { useCurrentSeller } from 'shared/hooks';
import useLiable from 'charlie/hooks/useLiable';

const ns = 'charlie.frontpage.liable_info';

const LiableInfo = () => {
  const { debtCollectionAmount, liableAmount, reversalCount } = useLiable();
  const { hasLoan } = useCurrentSeller();

  if (reversalCount === 0) {
    return null;
  }

  return (
    <Well className="tw-flex tw-flex-col tw-gap-4">
      <div>
        <span>{I18n.nt(ns, 'arrears')}</span>
        <span> </span>
        <span className="tw-font-bold">{toEuro(liableAmount)}</span>
      </div>
      {reversalCount <= 2 && (
        <div>
          {I18n.nt(ns, 'extra_interest_info', {
            amount: toEuro(debtCollectionAmount * 2),
          })}
        </div>
      )}
      {reversalCount > 2 && (
        <div>
          {I18n.nt(ns, 'message_liable_count_more_than_two', {
            amount: toEuro(debtCollectionAmount * 2),
          })}
        </div>
      )}
      {reversalCount > 2 && reversalCount <= 4 && !hasLoan && (
        <div>
          {I18n.nt(ns, 'message_liable_count_between_two_and_four', {
            amount: toEuro(debtCollectionAmount * 4),
          })}
        </div>
      )}
      {reversalCount > 4 && !hasLoan && (
        <div>
          {I18n.nt(ns, 'message_liable_count_more_than_four', {
            amount: toEuro(debtCollectionAmount * 4),
          })}
        </div>
      )}
    </Well>
  );
};

export default LiableInfo;

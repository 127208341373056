import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { LOGIN } from 'app/routes';
import TextInput from 'hookForm/TextInput';
import ServerError from 'shared/components/ServerError';
import Button from 'shared/components/tailwind/Button';
import GuestModal from 'shared/components/tailwind/GuestModal';
import useRequestResetPassword from './useRequestResetPassword';

const ns = 'app.resetPassword';

interface RequestResetPasswordContainerProps {
  children?: ReactNode;
}

const RequestResetPasswordContainer = ({
  children,
}: RequestResetPasswordContainerProps) => (
  <GuestModal>
    <h3 className="tw-mb-4 tw-text-lg md:tw-text-bootstrap-h3">
      {I18n.nt(ns, 'header')}
    </h3>
    {children}
  </GuestModal>
);

const RequestResetPassword = () => {
  const { control, requestedEmail, status, submit } = useRequestResetPassword();

  if (status === 'error') {
    return (
      <RequestResetPasswordContainer>
        <ServerError className="tw-mb-0 tw-mt-8" />
      </RequestResetPasswordContainer>
    );
  }

  if (status === 'requested') {
    return (
      <RequestResetPasswordContainer>
        <div className="tw-mt-8 tw-flex tw-flex-col tw-gap-8">
          {I18n.nt(ns, 'emailSentHtml', { email: requestedEmail })}
          <Link to={LOGIN}>{I18n.ut('common.goBack')}</Link>
        </div>
      </RequestResetPasswordContainer>
    );
  }

  return (
    <RequestResetPasswordContainer>
      <form onSubmit={submit} className="tw-flex tw-flex-col tw-gap-4">
        <div>{I18n.nt(ns, 'enterAccountDetails')}</div>
        <div className="tw-my-4">
          <TextInput
            autoFocus
            control={control}
            includeLayoutClass={false}
            label={I18n.label('user', 'email')}
            name="email"
          />
        </div>
        <div className="tw-flex tw-items-center tw-gap-4">
          <Button
            className="button-primary"
            disabled={status === 'requesting'}
            type="submit"
          >
            {I18n.ut('common.send')}
          </Button>
          <Link to={LOGIN}>{I18n.ut('common.orGoBack')}</Link>
        </div>
        <Alert type="info" className="tw-mb-0 tw-mt-4">
          {I18n.nt(ns, 'are_you_a_partner_html')}
        </Alert>
      </form>
    </RequestResetPasswordContainer>
  );
};

export default RequestResetPassword;

import React from 'react';
import { Button } from 'shared/bootstrap';
import { nt } from 'shared/utils/I18n';
import QuotedText from './QuotedText';
import { ReopenQuestion } from './useQuestions';
import Question from './question';
import { ns, formatAnswerInfo, formatQuestionInfo } from './utils';

interface Props {
  question: Question;
  reOpenQuestion: ReopenQuestion;
}

const AnsweredQuestion = ({ question, reOpenQuestion }: Props) => (
  <div className="Question">
    <QuotedText info={formatQuestionInfo(question)} text={question.question} />
    {question.answer && (
      <QuotedText info={formatAnswerInfo(question)} text={question.answer} />
    )}
    {question.answerFiles.length > 0 && (
      <div>
        {!question.answer && (
          <div className="answer_info">{formatAnswerInfo(question)}</div>
        )}
        <div className="files">
          {question.answerFiles.map((file) => (
            <div className="file" key={file.id}>
              <img src="/images/paperclip.svg" />
              <span>{file.fileName}</span>
            </div>
          ))}
        </div>
      </div>
    )}
    <div>
      <Button
        className="button-primary-inverted"
        onClick={() => reOpenQuestion(question.id)}
      >
        {nt(ns, 'reopen_question')}
      </Button>
    </div>
  </div>
);

export default AnsweredQuestion;

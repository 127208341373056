import React from 'react';
import { Alert } from 'shared/bootstrap';
import { differenceInHours, parseISO } from 'date-fns';
import { explicitDateFormat } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import Button from 'shared/components/tailwind/Button';
import { Invoice, InvoiceContact } from 'types';

const ns = 'app.invoiceSummary';

interface Props {
  invoice: Invoice;
  invoiceContactIds: number[];
  invoiceContactsMap: Record<number, InvoiceContact>;
  mailContact: {
    busy: boolean;
    error: string;
  };
  onMailInvoiceContact: (invoiceContact: InvoiceContact) => void;
  readonly: boolean;
}

interface ResendButtonProps {
  invoiceContact: InvoiceContact;
  onMailInvoiceContact: (invoiceContact: InvoiceContact) => void;
}

const ResendButton = ({
  invoiceContact,
  onMailInvoiceContact,
}: ResendButtonProps) => {
  const lastMailed = invoiceContact.lastMailed;

  const lastMailedDateTime = lastMailed ? parseISO(lastMailed) : null;
  const wasMailedRecently = lastMailedDateTime
    ? differenceInHours(new Date(), lastMailedDateTime) <= 12
    : false;
  const date = lastMailedDateTime
    ? explicitDateFormat(lastMailedDateTime, 'd LLLL H:mm')
    : null;

  const mailClick = () => {
    if (
      wasMailedRecently ||
      !confirm(I18n.nt(ns, 'debtorFields.reallyResendEmail'))
    ) {
      return;
    }

    onMailInvoiceContact(invoiceContact);
  };

  return (
    <Button
      onClick={mailClick}
      disabled={wasMailedRecently}
      className="ResendButton button-small button-light"
    >
      {wasMailedRecently
        ? I18n.nt(ns, 'debtorFields.emailSent', { date })
        : I18n.nt(ns, 'debtorFields.sendAgain')}
    </Button>
  );
};

const InvoiceContacts = ({
  invoice,
  invoiceContactIds,
  invoiceContactsMap,
  mailContact,
  onMailInvoiceContact,
  readonly,
}: Props) => {
  const invoiceContacts = invoiceContactIds.map((id) => invoiceContactsMap[id]);

  return (
    <div>
      {mailContact.error && <Alert type="danger">{mailContact.error}</Alert>}
      <ul>
        {invoiceContacts.map((invoiceContact) => (
          <li key={`contact-${invoiceContact.id}`}>
            {invoiceContact.fullName} ({invoiceContact.email})
            {invoice.canSendSoftcheckEmail && !readonly && (
              <ResendButton
                invoiceContact={invoiceContact}
                onMailInvoiceContact={onMailInvoiceContact}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InvoiceContacts;

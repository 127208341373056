import { LOAD_CAMPAIGN_DATA } from 'signup/actions/GuestSignup';
import {
  getSignupReferrer,
  setSignupReferrer,
} from 'shared/utils/referrerHelpers';
import { SignupState } from 'types/ReduxState';

const referrerRegex = /\/g\/aanmelden\/([a-zA-Z0-9-]+)\/?/;

const initialReferrer = () => {
  const cookieReferrer = getSignupReferrer();

  if (cookieReferrer) {
    return cookieReferrer;
  } else {
    const match = referrerRegex.exec(window.location.href);

    if (match) {
      const matchReferrer = match[1];
      setSignupReferrer(matchReferrer);
      return matchReferrer;
    } else {
      return undefined;
    }
  }
};

const initialState: SignupState = {
  campaign: undefined,
  referrer: initialReferrer(),
};

const reducer = (state: SignupState = initialState, action): SignupState => {
  switch (action.type) {
    case LOAD_CAMPAIGN_DATA.REQUEST:
      return {
        ...state,
        campaign: {
          status: 'loading',
        },
      };

    case LOAD_CAMPAIGN_DATA.SUCCESS:
      return {
        ...state,
        campaign: {
          campaignCompany: action.payload,
          code: action.code,
          email: action.email,
          status: 'loaded',
        },
      };

    case LOAD_CAMPAIGN_DATA.FAILURE:
      return {
        ...state,
        campaign: {
          status: 'error',
        },
      };

    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import * as I18n from 'shared/utils/I18n';
import ChangeAddressRequest from '../ChangeAddressRequest';
import ChangeEmailRequest from '../ChangeEmailRequest';
import ChangeFlorynInvoiceCcEmail from './ChangeFlorynInvoiceCcEmail';
import ChangeIbanRequest from '../ChangeIbanRequest';
import ChangePasswordRequest from 'app/containers/profile/ChangePasswordRequest';
import ChangePhoneRequest from '../ChangePhoneRequest';
import TwoFactorSettings from './TwoFactorSettings';
import { useUser } from 'namespaces/shared/hooks';

const ns = 'app.profilePage.servicePage';

const ServicePage = () => {
  const user = useUser();

  return (
    <div className="c-service-page">
      <h3 className="tw-mt-0">{I18n.nt(ns, 'title')}</h3>
      <p>{I18n.nt(ns, 'intro_html')}</p>
      <div className="service-page-section two-factor">
        <h4>{I18n.nt('app.profilePage', 'settings')}</h4>
        <div className="tw-grid tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-2">
          <TwoFactorSettings
            enabled={user.twoFactorEnabled}
            phone={user.phone}
          />
          <ChangeFlorynInvoiceCcEmail />
        </div>
      </div>
      <div className="service-page-section">
        <h4>{I18n.nt('app.profilePage.servicePage', 'changeInformation')}</h4>
        <div>
          <div className="tw-mb-6 tw-grid tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-2">
            <ChangePasswordRequest />
            <ChangeEmailRequest />
          </div>
          <div className="tw-mb-6 tw-grid tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-2">
            <ChangeAddressRequest />
            <ChangePhoneRequest />
          </div>
          <div className="tw-grid tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-2">
            <ChangeIbanRequest />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePage;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Button from 'shared/components/tailwind/Button';
import * as I18n from 'shared/utils/I18n';

import AutomaticWithdrawalModal from 'charlie/components/AutomaticWithdrawalModal';
import ExportModal from 'charlie/components/ExportModal';
import ExtraPayOffModal from 'charlie/components/ExtraPayOffModal';
import FlorynPayFailedConsent from 'charlie/components/FlorynPayFailedConsent';
import IncreaseLimitModal from 'charlie/components/IncreaseLimitModal';
import PayFeatureInfoModal from 'charlie/components/PayFeatureInfoModal';
import PayModal from 'charlie/components/PayModal';
import RepaymentModal from 'charlie/components/RepaymentModal';
import RepaymentStatusModal from 'charlie/components/RepaymentStatusModal';
import SearchForm from 'charlie/components/SearchForm';
import FlorynPayIdentificationStatus from 'charlie/components/FlorynPayIdentificationStatus';
import WithdrawDialog from 'charlie/components/WithdrawDialog';

import CharlieDropdown from './CharlieDropdown';
import WithdrawButton from './WithdrawButton';
import useCharlieActions from './useCharlieActions';
import FlorynPayTermsAndConditions from 'charlie/components/FlorynPayTermsAndConditions';

const ns = 'charlie.frontpage.actions';

/* Actions for a Charlie seller without a loan. */
const CharlieActions = () => {
  const {
    automaticWithdrawalVisible,
    canWithdraw,
    history,
    idealEnabled,
    increaseLimitButtonVisible,
    liableAmount,
    match,
    payButtonVisible,
    payFeatureInfoVisible,
    seller,
    transactionsButtonVisible,
  } = useCharlieActions();

  return (
    <div className="c-actions">
      <div className="c-actions-links">
        <WithdrawButton />
        {payButtonVisible && (
          <Button
            className="button-primary tw-grow xs:tw-grow-0"
            disabled={!canWithdraw}
            onClick={() => history.push(`${match.path}/direct-betalen`)}
          >
            {I18n.nt(ns, 'pay_instantly')}
          </Button>
        )}
        {payFeatureInfoVisible && (
          <Button
            className="button-primary tw-grow xs:tw-grow-0"
            onClick={() => history.push(`${match.path}/direct-betalen-info`)}
          >
            {I18n.nt(ns, 'pay_instantly')}
          </Button>
        )}
        {increaseLimitButtonVisible && (
          <Button
            className="button-secondary tw-grow xs:tw-grow-0"
            onClick={() => history.push(`${match.path}/limiet-verhogen`)}
          >
            {I18n.nt(ns, 'increase')}
          </Button>
        )}
        <CharlieDropdown />
        <Route path={`${match.path}/geld-opnemen`}>
          {canWithdraw ? (
            <WithdrawDialog parentPath={match.path} />
          ) : (
            <Redirect to={match.path} />
          )}
        </Route>
        {payButtonVisible && (
          <Route path={`${match.path}/direct-betalen`}>
            {canWithdraw ? (
              <PayModal parentPath={match.path} />
            ) : (
              <Redirect to={match.path} />
            )}
          </Route>
        )}
        {payButtonVisible && (
          <Route path={`${match.path}/ontbrekende-bevestiging/:id`}>
            <FlorynPayFailedConsent parentPath={match.path} />
          </Route>
        )}
        {payFeatureInfoVisible && (
          <Route path={`${match.path}/direct-betalen-info`}>
            <PayFeatureInfoModal parentPath={match.path} />
          </Route>
        )}
        <Route
          path={`${match.path}/identificatie-status`}
          render={(props) => (
            <FlorynPayIdentificationStatus parentPath={match.path} {...props} />
          )}
        />
        <Route
          path={`${match.path}/floryn-pay-voorwaarden`}
          render={(props) => (
            <FlorynPayTermsAndConditions parentPath={match.path} {...props} />
          )}
        />
        {idealEnabled && liableAmount > 0 && liableAmount <= 50_000 && (
          <Route path={`${match.path}/achterstand-terugbetalen`}>
            <RepaymentModal parentPath={match.path} />
          </Route>
        )}
        {idealEnabled && (
          <Route path={`${match.path}/betaalstatus/:end_to_end_id`}>
            <RepaymentStatusModal parentPath={match.path} />
          </Route>
        )}
        <Route
          path={`${match.path}/extra-aflossen`}
          render={(props) => (
            <ExtraPayOffModal parentPath={match.path} {...props} />
          )}
        />
        {automaticWithdrawalVisible && (
          <Route
            path={`${match.path}/automatisch-opnemen`}
            render={(props) => (
              <AutomaticWithdrawalModal parentPath={match.path} {...props} />
            )}
          />
        )}
        {increaseLimitButtonVisible && (
          <Route path={`${match.path}/limiet-verhogen`}>
            <IncreaseLimitModal parentPath={match.path} />
          </Route>
        )}
        <Route
          path={`${match.path}/transacties-downloaden`}
          render={() =>
            transactionsButtonVisible ? (
              <ExportModal parentPath={match.path} />
            ) : (
              <Redirect to={match.path} />
            )
          }
        />
      </div>
      <SearchForm sellerId={seller.id} />
    </div>
  );
};

export default CharlieActions;

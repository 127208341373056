import React from 'react';
import { nt } from 'shared/utils/I18n';
import { toPercentage } from 'shared/utils/Helpers';
const ns = 'signup.onboarding.partner_commission_info';

const PartnerCommissionInfo = ({ rate }) => {
  return (
    <div className="CostExplanation">
      <h4>{nt(ns, 'title')}</h4>
      <ul>
        <li>
          {nt(ns, 'explanation1_html', {
            rate: toPercentage(rate),
          })}
        </li>
      </ul>
    </div>
  );
};

export default PartnerCommissionInfo;

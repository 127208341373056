import React from 'react';
import Warning, {
  WarningMessage,
  WarningButton,
} from 'shared/components/Warning';
import * as I18n from 'shared/utils/I18n';
import { Attachment } from 'types';

const ns = 'shared.upload_statements_warning';

interface Props {
  attachmentsWithWarning: Attachment[];
  disabled: boolean;
  removeInvalidAttachments: () => void;
}

const UploadStatementsWarning = ({
  attachmentsWithWarning,
  disabled,
  removeInvalidAttachments,
}: Props) =>
  attachmentsWithWarning.length === 0 ? null : (
    <Warning>
      <WarningMessage title={I18n.nt(ns, 'not_recognized')}>
        {I18n.nt(ns, 'are_you_sure')}
      </WarningMessage>
      <WarningButton
        className="button-light"
        isDisabled={disabled}
        onClick={removeInvalidAttachments}
      >
        {I18n.nt(ns, 'remove_not_recognized_files')}
      </WarningButton>
    </Warning>
  );

export default UploadStatementsWarning;

import React from 'react';
import { MenuItem } from 'shared/bootstrap';
import { translate } from 'shared/utils/I18n';
import { Menu, MenuButton, MenuItems } from '@headlessui/react';

const t = translate('insights.time_filter_dropdown');

export type TimeFilter = '6' | '12' | 'all';

interface Props {
  onSelect: (months: TimeFilter) => void;
  currentValue: TimeFilter;
}

function TimeFilterDropdown({ currentValue, onSelect }: Props) {
  return (
    <div className="tw-relative tw-z-10">
      <Menu>
        <MenuButton className="button-light">
          {currentValue === 'all'
            ? t('title.all_html')
            : t(`title.restricted_html`, { value: currentValue })}
          <span className="caret"></span>
        </MenuButton>
        <MenuItems className="tw-absolute tw-right-0 tw-top-full tw-mt-1 tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-py-1 tw-shadow">
          <MenuItem
            active={currentValue === '6'}
            onSelect={() => onSelect('6')}
          >
            {t('options.restricted', { value: 6 })}
          </MenuItem>
          <MenuItem
            active={currentValue === '12'}
            onSelect={() => onSelect('12')}
          >
            {t('options.restricted', { value: 12 })}
          </MenuItem>
          <MenuItem divider />
          <MenuItem
            active={currentValue === 'all'}
            onSelect={() => onSelect('all')}
          >
            {t('options.all')}
          </MenuItem>
        </MenuItems>
      </Menu>
    </div>
  );
}

export default TimeFilterDropdown;

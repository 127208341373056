import React from 'react';
import { useHistory } from 'react-router';
import { Button, Modal } from 'shared/bootstrap';
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from 'shared/components/Tooltip';
import { SELLER_ONBOARDING } from 'app/routes';
import { UltimateBeneficialOwner } from 'types';
import { nt } from '../utils';
import UboList from './UboList';
import UboForm from './UboForm';
import ConfirmForm from './ConfirmForm';
import useUltimateBeneficialOwners from './useUltimateBeneficialOwners';

const t = nt('signup.onboarding.ultimate_beneficial_owners.modal');

const UboModal = () => {
  const {
    addUboToState,
    currentAction,
    loadStatus,
    removeUboFromState,
    resetActionAndUbo,
    setActionAndUbo,
    ubo,
    ubos,
    updateUboInState,
  } = useUltimateBeneficialOwners();

  const history = useHistory();
  const closeModal = () => history.push(SELLER_ONBOARDING);

  return (
    <>
      <Modal className="UboModal" show onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="intro-message">
            {t('intro')}
            <Tooltip tooltipInteraction="click" tooltipSize="xl">
              <TooltipTrigger>
                <a className="more-information">{t('more_information')}</a>
              </TooltipTrigger>
              <TooltipContent>{t('intro_explanation_html')}</TooltipContent>
            </Tooltip>
          </div>
          <UboList
            ubos={ubos}
            loadStatus={loadStatus}
            onEdit={(ubo: UltimateBeneficialOwner) => {
              setActionAndUbo('edit', ubo);
            }}
            onInvite={(ubo: UltimateBeneficialOwner) => {
              setActionAndUbo('invite', ubo);
            }}
          />
          <Button
            className="button-primary"
            onClick={() => setActionAndUbo('add', null)}
            disabled={loadStatus !== 'loaded'}
          >
            <span className="icon icon-plus tw-mr-3 tw-inline tw-align-middle tw-text-sm" />
            {t('add')}
          </Button>
        </Modal.Body>
        <Modal.Footer>{ubos.length !== 0 && <ConfirmForm />}</Modal.Footer>
      </Modal>

      <UboForm
        addUboToState={addUboToState}
        currentAction={currentAction}
        onHide={resetActionAndUbo}
        removeUboFromState={removeUboFromState}
        resetActionAndUbo={resetActionAndUbo}
        show={!!currentAction}
        ubo={ubo}
        updateUboInState={updateUboInState}
      />
    </>
  );
};

export default UboModal;

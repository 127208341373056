import { useEffect, useState } from 'react';
import client from 'shared/utils/client';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import useMainUser from 'shared/hooks/useMainUser';
import useUser from 'shared/hooks/useUser';
import { BaseSeller, MainUser, User } from 'types';
import { trackFormSubmit } from 'shared/utils/tracker';

type Action = '' | 'create' | 'changePermissions' | 'delete';
type ActionAndUser = [Action, User | null];

type Status = '' | 'error' | 'loaded' | 'loading';

interface Result {
  action: Action;
  afterSavePermissions: (user: User) => void;
  createStatus: Status;
  createUser: (values: any) => Promise<void>;
  currentUser: User | null;
  deleteStatus: Status;
  handleDeleteConfirm: () => void;
  mainUser: MainUser;
  mainUserIsLoggedIn: boolean;
  resetAction: () => void;
  seller: BaseSeller;
  setActionAndUser: (actionAndUser: ActionAndUser) => void;
  status: Status;
  users: User[];
}

const useUsers = (): Result => {
  const seller = useCurrentSeller();
  const loggedInUser = useUser();
  const mainUser = useMainUser();
  const mainUserIsLoggedIn = mainUser?.id === loggedInUser.id;

  const [actionAndUser, setActionAndUser] = useState<ActionAndUser>(['', null]);
  const [status, setStatus] = useState<Status>('');
  const [users, setUsers] = useState<User[]>([]);
  const [deleteStatus, setDeleteStatus] = useState<Status>('');
  const [createStatus, setCreateStatus] = useState<Status>('');

  const [action, currentUser] = actionAndUser;

  const resetAction = () => {
    setActionAndUser(['', null]);
  };

  const loadUsers = async () => {
    setStatus('loading');

    const response = await client<User[]>(
      'GET',
      '/api/users',
      {},
      { raiseError: false }
    );

    if (response.error) {
      setStatus('error');
    } else {
      setUsers(response.payload);
      setStatus('loaded');
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const createUser = async (values) => {
    const response = await client<User>(
      'POST',
      '/api/users',
      {
        user: values,
      },
      { raiseError: false }
    );

    if (response.error) {
      trackFormSubmit('user-form', false);
      setCreateStatus('error');
    } else {
      setUsers([...users, response.payload]);
      trackFormSubmit('user-form', true);
      setCreateStatus('');
      setActionAndUser(['', null]);
    }
  };

  const deleteUser = async (userId: number) => {
    const { error } = await client(
      'DELETE',
      `/api/users/${userId}`,
      {},
      { raiseError: false }
    );

    if (error) {
      setDeleteStatus('error');
    } else {
      setDeleteStatus('');
      setUsers(users.filter((user) => user.id !== userId));
    }
  };

  const handleDeleteConfirm = () => {
    const userId = currentUser?.id;

    if (userId) {
      setActionAndUser(['', null]);
      deleteUser(userId);
    }
  };

  const afterSavePermissions = (updatedUser: User) => {
    setUsers(
      users.map((user) => {
        if (user.id === updatedUser.id) {
          return updatedUser;
        } else {
          return user;
        }
      })
    );

    setActionAndUser(['', null]);
    trackFormSubmit('change-user-permissions-form');
  };

  return {
    action,
    afterSavePermissions,
    createStatus,
    createUser,
    currentUser,
    deleteStatus,
    handleDeleteConfirm,
    mainUser,
    mainUserIsLoggedIn,
    resetAction,
    seller,
    setActionAndUser,
    status,
    users,
  };
};

export default useUsers;

import React, { ReactNode } from 'react';

interface ComponentProps {
  children?: ReactNode;
  title?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  style?: React.CSSProperties;
  onClick?: (args?: any) => any;
  className?: string;
  disabled?: boolean;
}

const Button = ({
  className = 'button-light',
  type = 'button',
  ...props
}: ComponentProps) => <button {...props} type={type} className={className} />;

export default Button;

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { CUSTOMER_APP_ROOT, REVIEW } from 'app/routes';
import FrontPage from 'charlie/components/Frontpage';
import ReviewWizard from 'shared/components/ReviewWizard';

export default () => (
  <Switch>
    <Route path={REVIEW}>
      <ReviewWizard />
    </Route>
    <Route path={CUSTOMER_APP_ROOT}>
      <FrontPage />
    </Route>
  </Switch>
);

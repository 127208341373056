import konsole from './konsole';
import { Company, MainUser, MinimalSeller } from 'types';
import * as trackerService from './segment';

let trackingQueue: any[] = [];

class SafeTracker {
  call(
    functionName: string,
    name: string,
    properties?: Record<string, unknown>,
    callback?: () => void
  ) {
    if (!trackerService.isLoaded()) {
      trackingQueue.push({ functionName, name, properties });
      if (typeof callback !== 'undefined') callback();
      return;
    }

    try {
      this[functionName](name, properties, callback);
    } catch (e) {
      konsole.error(e);
      if (typeof callback !== 'undefined') callback();
    }
  }

  page(page: string, properties?: Record<string, unknown>) {
    trackerService.page(page, properties);
  }

  identify(id: string, properties?: Record<string, unknown>) {
    trackerService.identify(id, properties);
  }

  event(
    eventName: string,
    properties?: Record<string, unknown>,
    callback?: () => void
  ) {
    trackerService.event(eventName, properties, callback);
  }
}

const safeTracker = new SafeTracker();

const processTrackingQueue = () => {
  trackingQueue.forEach(({ functionName, name, properties }) => {
    try {
      safeTracker[functionName](name, properties);
    } catch (e) {
      konsole.error(e);
    }
  });
  trackingQueue = [];
};
(window as any).processTrackingQueue = processTrackingQueue;

type addUserTraits = (o: Record<string, unknown>) => void;
type getUserTraits = () => Record<string, unknown>;

// addUserTraits() is an exported function that allows for adding traits
// that will ultimately be included in segment.identify() calls made by
// identifySeller(). These functions get those traits
// by calling getUserTraits(). You can call addUserTraits() multiple
// times to add traits, getUserTraits() will merge the objects into one
// object, in the order the addSegmentTrait() calls were done.
const [addUserTraits, getUserTraits] = (() => {
  // Encapsulate list of trait objects; the returned functions close over it
  const traits: Record<string, unknown>[] = [];

  return [
    (obj) => {
      traits.push(obj);
    },
    () =>
      traits.reduce(
        (allTraits, traits) => ({
          ...allTraits,
          ...traits,
        }),
        {}
      ),
  ] as [addUserTraits, getUserTraits];
})(); // immediately call the function to instantiate the addUserTraits and getUserTraits functions

export { addUserTraits };

interface IdentifySellerParams {
  company: Company;
  mainUser: MainUser;
  seller: MinimalSeller;
}

export const identifySeller = ({
  company,
  mainUser,
  seller,
}: IdentifySellerParams) =>
  safeTracker.call('identify', `${mainUser.id}`, {
    aBTests: seller.aBTests,
    companyName: company.name,
    email: mainUser.email,
    expectedFinancingAmount: seller.expectedFinancingAmount,
    instantPaymentAvailableAmount: seller.instantPaymentAvailableAmount,
    instantPaymentEnabled: seller.instantPaymentEnabled,
    name: mainUser.fullName,
    phone: seller.phone,
    status: seller.status,
    signupType: seller.signupType,
    ...getUserTraits(),
  });

type BankStatementType = 'documents' | 'psd2';

export const trackDocumentsComplete = (
  bankStatementType: BankStatementType,
  email: string,
  bank: string | null = null,
  callback?: () => void
) => {
  trackEvent(
    'Upload bankdata',
    {
      bank,
      bankStatementType,
      email,
    },
    callback
  );
};

export const trackFormSubmit = (form: string, valid = true) => {
  trackEvent(`Submit ${form} ${valid ? 'valid' : 'invalid'}`);
};

// Make sure there is a corresponding event in the mobile apps.
export const trackCrossPlatformEvent = (
  eventName: string,
  properties?: Record<string, unknown>,
  callback?: () => void
) => {
  trackEvent(`[crossplatform] ${eventName}`, properties, callback);
};

export const trackCrossPlatformPage = (pageName: string) => {
  trackCrossPlatformEvent(`page-load: ${pageName}`);
};

export const trackEvent = (
  eventName: string,
  properties?: Record<string, unknown>,
  callback?: () => void
) => {
  safeTracker.call('event', eventName, properties, callback);
};

export const trackPage = (page: string) => {
  safeTracker.call('page', page);
};

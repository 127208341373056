import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { SELLER_ONBOARDING } from 'app/routes';
import * as I18n from 'shared/utils/I18n';

const ns = 'signup.onboarding.financial_dashboard';

const Buttons = () => {
  const { path } = useRouteMatch();

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 xs:tw-flex-row">
      <Link to={SELLER_ONBOARDING} className="button-primary tw-w-100%">
        {I18n.nt(ns, 'onboarding_link.finish_submission')}
      </Link>
      <Link to={`${path}/koppel`} className="button-light tw-w-100%">
        {I18n.nt(ns, 'onboarding_link.add_accounts')}
      </Link>
    </div>
  );
};

export default Buttons;

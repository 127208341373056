import React from 'react';
import useConnected from './useConnected';
import { datetimeFormat, formatIban } from 'shared/utils/Helpers';
import { nt, ut } from 'shared/utils/I18n';
import { dateFormat } from 'shared/utils/date';
import Button from 'shared/components/tailwind/Button';
import { useForm } from 'react-hook-form';
import { Alert } from 'shared/bootstrap';
import { Seller } from '../types';
import SelectDivision from './SelectDivision';

const ns = 'app.profilePage.exact_online_connection.connected';

const Connected = () => {
  const {
    changingDivisions,
    handleSave,
    hasFirstExport,
    haveMissingDivisions,
    sellers,
    setChangingDivisions,
    status,
  } = useConnected();

  const { register, reset, handleSubmit } = useForm();

  const clickChangeDivision = (sellerId, e) => {
    e.preventDefault();
    setChangingDivisions([sellerId, ...changingDivisions]);
  };

  const handleCancel = () => {
    reset();
    setChangingDivisions([]);
  };

  const disableButtons =
    !haveMissingDivisions && changingDivisions.length === 0;

  const onSubmit = (data) => {
    handleSave(data);
  };

  const lastExportValue = (seller: Seller) => {
    if (
      seller.lastExportAt &&
      seller.exportedStartDate &&
      new Date(seller.lastExportAt) >= new Date(seller.exportedStartDate)
    ) {
      // Only show the last export date if this date is from the current connection
      return datetimeFormat(seller.lastExportAt);
    } else if (seller.exportedStartDate) {
      return (
        <i>
          {nt(ns, 'first_export.starting_from_html', {
            start_date: dateFormat(seller.exportedStartDate),
          })}
        </i>
      );
    }
    return '-';
  };

  return (
    <div className="connected">
      <div>{nt(ns, 'intro')}</div>
      {hasFirstExport && (
        <div className="first-export">
          <strong>{nt(ns, 'first_export.title')}</strong>
          <div>
            <div>{nt(ns, 'first_export.intro_start')}</div>
            <div>{nt(ns, 'first_export.intro_manual')}</div>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {status === 'error' && (
          <Alert type="danger">{ut('common.server_error')}</Alert>
        )}
        <table>
          <thead className="DesktopOnly">
            <tr>
              <th>{nt(ns, 'client_bank_account')}</th>
              <th>{nt(ns, 'company_name')}</th>
              <th>{nt(ns, 'administration')}</th>
              <th>{nt(ns, 'last_export')}</th>
            </tr>
          </thead>
          <tbody>
            {sellers.map((seller, index) => (
              <tr key={seller.id}>
                <td>
                  <div className="MobileOnly header">
                    {nt(ns, 'client_bank_account')}
                  </div>
                  {formatIban(seller.clientBankAccount)}
                </td>
                <td>
                  <div className="MobileOnly header">
                    {nt(ns, 'company_name')}
                  </div>
                  {seller.companyName}
                </td>
                <td className="administration">
                  <div className="MobileOnly header">
                    {nt(ns, 'administration')}
                  </div>
                  {changingDivisions.includes(seller.id) ||
                  !seller.currentDivision ? (
                    <>
                      <input
                        type="hidden"
                        value={seller.id}
                        {...register(`sellers[${index}].id`)}
                      />
                      <SelectDivision
                        register={register}
                        name={`sellers[${index}].currentDivision`}
                        seller={seller}
                      />
                    </>
                  ) : (
                    <div className="change-division">
                      {seller.currentDivisionDescription}
                      <a
                        href="#"
                        onClick={(event) =>
                          clickChangeDivision(seller.id, event)
                        }
                      >
                        {nt(ns, 'change_administration')}
                      </a>
                    </div>
                  )}
                </td>
                <td className="last-export">
                  <div className="MobileOnly header">
                    {nt(ns, 'last_export')}
                  </div>
                  {lastExportValue(seller)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="buttons">
          <Button
            className="button-light"
            onClick={handleCancel}
            disabled={status === 'updating' || disableButtons}
          >
            {nt(ns, 'cancel')}
          </Button>
          <Button
            className="button-primary"
            onClick={handleSubmit(onSubmit)}
            disabled={status === 'updating' || disableButtons}
          >
            {nt(ns, 'save')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Connected;

import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

import * as I18n from 'shared/utils/I18n';
import TextInput from 'hookForm/TextInput';
import Button from 'shared/components/tailwind/Button';
import CompanyInfo from 'shared/components/CompanyInfo';
import ExpectedFinancingAmountInput from 'signup/shared/ExpectedFinancingAmountInput';
import ExpectedRevenueInput from 'signup/shared/ExpectedRevenueInput';
import { Alert } from 'shared/bootstrap';
import { MINIMUM_EXPECTED_REVENUE_AMOUNT } from 'signup/shared/validations';
import { toEuro } from 'shared/utils/Helpers';
import useAccountStep from './useAccountStep';
import { ProductVersion, Seller } from 'types/Seller';
import { label } from 'shared/utils/I18n';
import GeneralErrors from 'hookForm/GeneralErrors';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';

const ns = 'app.profile.accountStep';

const AccountStep = () => {
  const { company, control, isSubmitting, serverErrorMessages, submit } =
    useAccountStep();
  const seller = useCurrentSeller() as Seller;
  const [rejected, setRejected] = useState(false);

  const handleExpectedRevenueBlur = (value: string) => {
    setRejected(parseFloat(value) < MINIMUM_EXPECTED_REVENUE_AMOUNT);
  };

  const expectedRevenue = useWatch({ control, name: 'expectedRevenue' });

  const expectedFinancingAmountLabel = label(
    'seller',
    `expectedFinancingAmount${
      seller.productVersion === ProductVersion.ALFA ? 'Factoring' : ''
    }`
  );

  useEffect(() => {
    if (
      rejected &&
      expectedRevenue !== null &&
      parseFloat(expectedRevenue) >= MINIMUM_EXPECTED_REVENUE_AMOUNT
    ) {
      setRejected(false);
    }
  }, [expectedRevenue]);

  return (
    <div className="AccountStep lg:tw-pr-16">
      <h1 className="tw-mb-6 tw-text-xl md:tw-mt-0 md:tw-text-bootstrap-h3">
        {I18n.nt(ns, 'title')}
      </h1>
      <div className="fieldset">
        <GeneralErrors generalErrors={serverErrorMessages} />
        <div className="tw-mb-6">
          <ExpectedFinancingAmountInput
            autoFocus
            control={control}
            label={expectedFinancingAmountLabel}
            name="expectedFinancingAmount"
          />
        </div>
        <ExpectedRevenueInput
          control={control}
          name="expectedRevenue"
          onBlur={handleExpectedRevenueBlur}
        />
        {rejected && (
          <Alert type="info">
            {I18n.nt('app.signup', 'rejected_html', {
              minimum_amount: toEuro(MINIMUM_EXPECTED_REVENUE_AMOUNT, 0),
            })}
          </Alert>
        )}
        <div className="tw-grid tw-grid-cols-2 tw-gap-x-10">
          <TextInput
            control={control}
            label={I18n.attribute('user', 'first_name')}
            name="firstName"
            readOnly={rejected}
            maxLength={250}
          />
          <TextInput
            control={control}
            label={I18n.attribute('user', 'last_name')}
            name="lastName"
            readOnly={rejected}
            maxLength={250}
          />
        </div>
        <TextInput
          control={control}
          label={I18n.attribute('user', 'phone')}
          name="phone"
          type="tel"
          readOnly={rejected}
          maxLength={50}
        />
        <div className="tw-mb-8">
          <label className="control-label">
            {I18n.attribute('seller', 'company')}
          </label>
          <CompanyInfo company={company} />
        </div>
      </div>
      <div className="tw-flex">
        <Button
          type="submit"
          className="button-primary"
          disabled={isSubmitting || rejected}
          onClick={submit}
        >
          {I18n.nt(ns, 'continue')}
        </Button>
      </div>
    </div>
  );
};

export default AccountStep;

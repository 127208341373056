import React from 'react';
import { Button, Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';

interface Props {
  handleDeleteConfirm: () => void;
  onHide: () => void;
  show: boolean;
}

const DeleteModal = ({ handleDeleteConfirm, onHide, show }: Props) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>{I18n.nt('charlie.users.delete_user', 'title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {I18n.nt('charlie.users.delete_user', 'confirmation_text')}
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onHide}>
        {I18n.nt('charlie.users.delete_user', 'cancel')}
      </Button>
      <Button className="button-danger" onClick={handleDeleteConfirm}>
        {I18n.nt('charlie.users.delete_user', 'confirm')}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default DeleteModal;

import React from 'react';

import { Seller } from 'types';
import { getProduct } from 'models/Seller';

import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { nt } from 'shared/utils/I18n';
import {
  parseFloatOrUndefined,
  toEuro,
  toPercentage,
} from 'shared/utils/Helpers';
import SummaryBlock from 'Onboarding/components/ProductInformation/SummaryBlock';
import CreditLimitImage from 'svg/money-bag-euro.svg';
import InterestImage from 'svg/discount-1.svg';
import ClosingCommissionImage from 'svg/file-with-cash.svg';
import WithdrawalFeeImage from 'svg/touch-finger.svg';
import ServiceFeeImage from 'svg/office-employee.svg';
import SecuritiesSummary from './SecuritiesSummary';

const ns = 'signup.onboarding.proposal_summary.charlie';

const CharlieSummary = () => {
  const seller = useCurrentSeller() as Seller;
  const creditDecision = seller.creditDecision;
  const creditLimit = parseFloatOrUndefined(creditDecision.creditLimit);
  const baseInterestRate = parseFloatOrUndefined(
    creditDecision.baseInterestRate
  );

  if (!creditLimit || baseInterestRate === null) {
    return null;
  }

  const euriborRate = creditDecision.euriborRate;
  const closingCommissionAmount = parseFloatOrUndefined(
    creditDecision.closingCommissionAmount
  );
  const serviceFeeRate = parseFloatOrUndefined(creditDecision.serviceFeeRate);
  const withdrawalFeeRate = parseFloatOrUndefined(
    creditDecision.withdrawalFeeRate
  );

  const euriborText = creditDecision.hasEuribor
    ? nt(ns, 'euribor', { percentage: toPercentage(euriborRate) })
    : undefined;

  return (
    <>
      <div>{nt(ns, `introduction.${getProduct(seller)}_html`)}</div>

      <div className="tw-grid tw-grid-cols-2 tw-gap-2 tw-font-heading sm:tw-grid-cols-4 md:tw-grid-cols-2 lg:tw-grid-cols-4">
        <SummaryBlock
          title={nt(ns, `limit.${getProduct(seller)}`)}
          value={toEuro(creditLimit, 0)}
        >
          <CreditLimitImage />
        </SummaryBlock>
        <SummaryBlock
          title={nt(ns, 'interest_rate')}
          value={toPercentage(baseInterestRate)}
          subText={euriborText}
        >
          <InterestImage />
        </SummaryBlock>
        {closingCommissionAmount !== null && closingCommissionAmount !== 0 && (
          <SummaryBlock
            title={nt(ns, 'closing_commission')}
            value={toEuro(closingCommissionAmount, 0)}
          >
            <ClosingCommissionImage />
          </SummaryBlock>
        )}
        {withdrawalFeeRate !== null && withdrawalFeeRate !== 0 && (
          <SummaryBlock
            title={nt(ns, 'withdrawal_fee')}
            value={toPercentage(withdrawalFeeRate)}
          >
            <WithdrawalFeeImage />
          </SummaryBlock>
        )}
        {serviceFeeRate !== null && serviceFeeRate !== 0 && (
          <SummaryBlock
            title={nt(ns, 'service_fee')}
            value={toPercentage(serviceFeeRate)}
            subText={nt(ns, 'per_quarter')}
          >
            <ServiceFeeImage />
          </SummaryBlock>
        )}
      </div>
      <SecuritiesSummary />
    </>
  );
};

export default CharlieSummary;

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { SELLER_ONBOARDING } from 'app/routes';
import useEnsureFullSeller from 'shared/hooks/useEnsureFullSeller';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import OnboardingStep from './OnboardingStep';
import QuestionsStep from './Questions/QuestionsStep';
import ProductInformationStep from './ProductInformation/ProductInformationStep';
import DeliveriesStep from './Deliveries/DeliveriesStep';
import useDeliveries from 'namespaces/app/components/seller/profile/DeliveriesPage/useDeliveries';

const OnboardingPage = () => {
  const { seller } = useEnsureFullSeller();
  const { deliveryRequests } = useDeliveries();

  if (!seller || deliveryRequests.type === 'pending') {
    return <LoadingAnimation />;
  }

  const canSeeProductInformation =
    Boolean(seller.contract) ||
    seller.showProposalSummary ||
    seller.showAutomatedOffer;

  return (
    <Switch>
      {canSeeProductInformation && (
        <Route
          path={`${SELLER_ONBOARDING}/product-informatie`}
          component={ProductInformationStep}
        />
      )}
      <Route
        path={`${SELLER_ONBOARDING}/questions/:kind`}
        component={QuestionsStep}
      />
      <Route
        path={`${SELLER_ONBOARDING}/deliveries`}
        component={DeliveriesStep}
      />
      <Route
        path={SELLER_ONBOARDING}
        render={() => <OnboardingStep deliveryRequests={deliveryRequests} />}
      />
    </Switch>
  );
};

export default OnboardingPage;

import React from 'react';
import { Button, Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';

const ns = 'shared.psd2_banks.pdf_popup';

interface Props {
  onContinue: () => void;
  onHide: () => void;
  show: boolean;
}

const PdfPopup = ({ onContinue, onHide, show }: Props) => (
  <Modal className="PdfPopup" show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{I18n.nt(ns, 'message1')}</p>
      <p className="tw-mt-4">{I18n.nt(ns, 'message2')}</p>
      <Button
        className="button-primary tw-mt-2 tw-w-full md:tw-w-auto"
        onClick={onHide}
      >
        {I18n.nt(ns, 'go_back')}
      </Button>
      <Button
        className="button-light tw-mb-6 tw-mt-2 tw-w-full md:tw-w-auto"
        onClick={onContinue}
      >
        {I18n.nt(ns, 'continue')}
      </Button>
    </Modal.Body>
  </Modal>
);

export default PdfPopup;

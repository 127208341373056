import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as routes from 'app/routes';
import Error_ from './Error';
import Guest from 'app/Guest';
import NotFound from './NotFound';
import OnboardingUser from 'OnboardingUser';
import Sellers from 'app/Sellers';
import SecondSigner from 'SecondSigner';
import PartnerSignup from 'PartnerSignup';
import { RedirectToFrontpage } from 'shared/utils/routing';
import useLoadSession from 'shared/hooks/useLoadSession';
import { currentSellerSelector } from 'shared/selectors';
import { Status } from 'types/Seller';
import Logout from './Logout';

const AppRoot = () => {
  const { tokenError, loaded } = useLoadSession();
  const seller = useSelector(currentSellerSelector);

  if (tokenError) {
    // Handle token error by PartnerSignup component. Assume we're on the partner signup url.
    return (
      <div className="ApplicationRoot app">
        <Route path={routes.PARTNER_SIGNUP_BANK_STATEMENTS}>
          <PartnerSignup />
        </Route>
      </div>
    );
  }

  if (!loaded) {
    return <span />;
  }

  return (
    <div className="ApplicationRoot app">
      <Switch>
        <Route path={routes.GUEST_ROOT}>
          <Guest />
        </Route>
        <Route path={[routes.CUSTOMER_APP_ROOT, routes.OVERVIEW_PAGE_TAB]}>
          {seller ? <Sellers /> : <RedirectToFrontpage />}
        </Route>
        <Route path={routes.ERROR}>
          <Error_ />
        </Route>
        <Route path={routes.SECOND_SIGNER}>
          <SecondSigner />
        </Route>
        <Route path={routes.ONBOARDING_USER}>
          <OnboardingUser />
        </Route>
        <Route path={routes.PARTNER_SIGNUP_BANK_STATEMENTS}>
          {!seller ||
          seller.status === Status.DRAFT ||
          seller.status === Status.SUBMITTED ? (
            <PartnerSignup />
          ) : (
            <RedirectToFrontpage />
          )}
        </Route>
        <Route path={routes.LOGOUT}>
          <Logout />
        </Route>
        <Route exact path={routes.APP_ROOT}>
          <RedirectToFrontpage />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
};

export default () => (
  <Route path="/">
    <AppRoot />
  </Route>
);

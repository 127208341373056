import React from 'react';
import ChangeIbanModal from './ChangeIbanModal';
import ChangeRequestCard from '../shared/ChangeRequestCard';
import useServicePage from '../ServicePage/useServicePage';
import * as I18n from 'shared/utils/I18n';
import { Link } from 'react-router-dom';

const ns = 'app.profilePage.changeIban';

const ChangeIbanRequest = () => {
  const { action, setAction, resetAction, seller } = useServicePage();

  const description = (
    <>
      {seller.clientBankAccount
        ? I18n.nt(ns, 'introStartClientBankAccountHtml')
        : I18n.nt(ns, 'introStartHtml')}{' '}
      <Link to="/klant/account/rekeningen">{I18n.nt(ns, 'introLink')}</Link>{' '}
      {I18n.nt(ns, 'introEndHtml')}
    </>
  );

  const title = (
    <>
      {seller.clientBankAccount
        ? I18n.nt(ns, 'titleClientBankAccount')
        : I18n.nt(ns, 'title')}
    </>
  );

  const buttonText = (
    <>
      {seller.clientBankAccount
        ? I18n.nt(ns, 'buttonClientBankAccount')
        : I18n.nt(ns, 'button')}
    </>
  );

  return (
    <>
      <ChangeRequestCard
        title={title}
        description={description}
        buttonText={buttonText}
        onButtonClick={() => setAction('changeIban')}
        disabled={action !== ''}
      />
      <ChangeIbanModal onHide={resetAction} show={action === 'changeIban'} />
    </>
  );
};

export default ChangeIbanRequest;

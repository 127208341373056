import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import MandateReturn from 'signup/components/MandateReturn';
import { loadTwikeySession } from 'signup/actions/MandateReturn';
import { ReduxState } from 'types';

const MandateReturnContainer = () => {
  const dispatch = useDispatch();
  const mandateReturn = useSelector((state: ReduxState) => state.mandateReturn);

  useEffect(() => {
    dispatch(loadTwikeySession());
  }, []);

  if (mandateReturn.status !== 'loaded') {
    return <LoadingAnimation />;
  }

  const { iban, status } = mandateReturn.payload;

  return <MandateReturn iban={iban} twikeyStatus={status} />;
};

export default MandateReturnContainer;

import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { validateEmail, validatePresence } from 'shared/utils/validation';
import resolver from 'hookForm/resolver';
import { UltimateBeneficialOwner } from 'types';
import client from 'shared/utils/client';
import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';

interface FormValues {
  email: string;
}

const validate = ({ email }: FormValues) => ({
  email: validatePresence(email) || validateEmail(email),
});

interface Parameters {
  ubo: UltimateBeneficialOwner;
  updateUboInState: (ubo: UltimateBeneficialOwner) => void;
  resetActionAndUbo: () => void;
}

export default ({ ubo, updateUboInState, resetActionAndUbo }: Parameters) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setError,
  } = useForm<FormValues>({
    defaultValues: {
      email: ubo.email,
    },
    resolver: resolver(validate),
  });
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);

  const submit = async (values: FormValues) => {
    const response = await client(
      'POST',
      `/api/ultimate_beneficial_owners/${ubo.id}/invite_for_id_verification`,
      values,
      {
        raiseError: false,
      }
    );

    if (response.error) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        response,
        setError
      );
      setServerErrorMessages(generalErrorMessages);
      return;
    }

    updateUboInState(response.payload);
    resetActionAndUbo();
  };

  const cancel = () => resetActionAndUbo();

  return {
    cancel,
    control,
    isSubmitting,
    serverErrorMessages,
    submit: handleSubmit(submit),
  };
};

import React from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { Button } from 'shared/bootstrap';

import { PARTNER_SIGNUP_BANK_STATEMENTS } from 'app/routes';
import * as I18n from 'shared/utils/I18n';
import {
  getStatusFromQueryStringOrPath,
  ConsentFeedbackStatus,
} from 'shared/components/Consent/FeedbackHelpers';
import { Consent, Partner, Review } from 'types';
import useAppDispatch from 'shared/hooks/useAppDispatch';

import { finish } from 'PartnerSignup/modules/partnerSignupSlice';
import BankAccount from './BankAccount';
import PdfStatement from './PdfStatement';
import { BankStatementAttachment } from 'types/Attachment';

const ns = 'app.partner_signup.overview_step';

interface Props {
  bankStatementAttachments: BankStatementAttachment[];
  partner: Partner;
  review: Review;
}

const OverviewStep = ({ bankStatementAttachments, partner, review }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const status = getStatusFromQueryStringOrPath(location);
  if (status !== ConsentFeedbackStatus.Success) {
    const path = PARTNER_SIGNUP_BANK_STATEMENTS + location.search;
    return <Redirect to={path} />;
  }

  const psd2Accounts = review.aisConsents.map((consent: Consent) => (
    <BankAccount key={consent.iban} consent={consent} />
  ));

  const pdfAccounts = bankStatementAttachments.map(
    (attachment: BankStatementAttachment) => (
      <PdfStatement key={attachment.id} bankStatement={attachment} />
    )
  );

  const onShareMore = (e) => {
    e.preventDefault();
    return history.push(PARTNER_SIGNUP_BANK_STATEMENTS);
  };
  const onSubmit = () => dispatch(finish());

  return (
    <div className="PartnerSignupOverviewStep">
      <h1>{I18n.nt(ns, 'title')}</h1>
      {review.status === 'open' ? (
        <p>{I18n.nt(ns, 'introduction')}</p>
      ) : (
        <p>{I18n.nt(ns, 'introduction_finished')}</p>
      )}
      <label>{I18n.nt(ns, 'label')}</label>
      {psd2Accounts}
      {pdfAccounts}
      {review.status === 'open' ? (
        <>
          <a className="share-more" href="#" onClick={onShareMore}>
            <span className="plus">+</span>
            <span>{I18n.nt(ns, 'share_more')}</span>
          </a>
          <div className="status-open">
            <p>{I18n.nt(ns, 'status_open')}</p>
            <Button className="button-primary" onClick={onSubmit} type="submit">
              {I18n.nt(ns, 'submit')}
            </Button>
          </div>
        </>
      ) : (
        <div className="status-received">
          <i className="icon-checkmark" />
          {I18n.nt(ns, 'status_received', { partner: partner.companyName })}
        </div>
      )}
    </div>
  );
};

export default OverviewStep;

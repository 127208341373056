import React from 'react';
import { AlfaSeller, Remote, Seller } from 'types';
import QuestionsIntroduction from './QuestionsIntroduction';
import SellerActions from '../SellerActions';
import QuestionsAction from '../Questions/QuestionsAction';
import StatementsAction from './StatementsAction';
import ExampleInvoices from '../ExampleInvoices';
import {
  deliveriesStatus,
  exampleInvoicesStatus,
  statementsStatus,
} from 'models/Onboarding';
import { isAlfa, isCharlie } from 'models/Seller';
import SubmittedVideo from './SubmittedVideo';
import FinancialDashboardStatus from '../FinancialDashboardStatus';
import GoogleReviews from '../GoogleReviews';
import { nt } from '../utils';
import InternalAccountDetection from './InternalAccountDetection';
import DeliveriesAction from '../Deliveries/DeliveriesAction';
import { DeliveryRequest } from 'app/components/seller/profile/DeliveriesPage/types';

const ns = 'signup.onboarding.without_contract';
const t = nt(ns);

interface Props {
  basePath: string;
  deliveryRequests: Remote<DeliveryRequest[]>;
  seller: Seller;
}

const WithQuestions = ({ basePath, deliveryRequests, seller }: Props) => {
  const openInquiryQuestions =
    seller.questions.inquiry.status === 'unanswered_questions';
  const openRequirementQuestions =
    seller.questions.requirement.status === 'unanswered_questions';
  const hasDeliveries =
    deliveryRequests.type === 'success' && deliveryRequests.response.length > 0;

  return (
    <>
      <QuestionsIntroduction questions={seller.questions} />
      <SellerActions>
        {hasDeliveries && (
          <DeliveriesAction
            basePath={basePath}
            status={deliveriesStatus(deliveryRequests)}
          />
        )}
        {openInquiryQuestions && (
          <QuestionsAction basePath={basePath} status="Todo" kind="inquiry" />
        )}
        {openRequirementQuestions && (
          <QuestionsAction
            basePath={basePath}
            status="Todo"
            kind="requirement"
          />
        )}
        <StatementsAction
          basePath={basePath}
          status={statementsStatus(seller)}
        />
        {isAlfa(seller) && (
          <ExampleInvoices
            basePath={basePath}
            status={exampleInvoicesStatus(seller as AlfaSeller)}
          />
        )}
      </SellerActions>

      <InternalAccountDetection />

      <SubmittedVideo seller={seller} />
      <div className="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2">
        <FinancialDashboardStatus />
        <GoogleReviews />
      </div>
      <div>
        {t('optional_steps')} {isAlfa(seller) && t('optional_steps_alfa')}
        {isCharlie(seller) && t('optional_steps_charlie')}
      </div>
    </>
  );
};

export default WithQuestions;

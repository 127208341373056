import React from 'react';
import { Button } from 'shared/bootstrap';
import TextInput from 'hookForm/TextInput';
import useConfirmForm from './useConfirmForm';
import GeneralErrors from 'hookForm/GeneralErrors';
import { nt } from '../utils';

const t = nt('signup.onboarding.ultimate_beneficial_owners.confirm_form');

const ConfirmForm = () => {
  const { control, isSubmitting, name, serverErrorMessages, submit } =
    useConfirmForm();

  return (
    <div>
      <form onSubmit={submit} className="ConfirmForm">
        <TextInput
          control={control}
          hideLabel
          label={t('name')}
          name="name"
          placeholder={t('name')}
        />
        <span>{t('message')}</span>{' '}
        <Button
          type="submit"
          disabled={!name?.trim() || isSubmitting}
          className="button-primary"
        >
          {t('sign')}
        </Button>
      </form>
      <GeneralErrors
        className="tw-mt-4 tw-text-left"
        generalErrors={serverErrorMessages}
      />
    </div>
  );
};

export default ConfirmForm;

import client from 'shared/utils/client';
import updateUserInState from 'shared/modules/updateUserInState';

import { SIGNUP } from 'signup/actions/GuestSignup';

export const LOAD = 'session.LOAD';
export const LOAD_SUCCESS = 'session.LOAD_SUCCESS';
export const LOAD_FAILURE = 'session.LOAD_FAILURE';

export const ACTIVATE_ACCOUNT = 'session.ACTIVATE_ACCOUNT';

export const AUTHENTICATE = 'session.AUTHENTICATE';
export const AUTHENTICATE_SUCCESS = 'session.AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILURE = 'session.AUTHENTICATE_FAILURE';

export const DEAUTHENTICATE = 'session.DEAUTHENTICATE';
export const DEAUTHENTICATE_SUCCESS = 'session.DEAUTHENTICATE_SUCCESS';
export const DEAUTHENTICATE_FAILURE = 'session.DEAUTHENTICATE_FAILURE';

export const DISABLE_TWO_FACTOR = 'session.DISABLE_TWO_FACTOR';
export const DISABLE_TWO_FACTOR_SUCCESS = 'session.DISABLE_TWO_FACTOR_SUCCESS';
export const DISABLE_TWO_FACTOR_FAILURE = 'session.DISABLE_TWO_FACTOR_FAILURE';

export const SESSION_EXPIRED = 'session.SESSION_EXPIRED';

export const AUTHENTICATE_WITH_TOKEN = 'session.AUTHENTICATE_WITH_TOKEN';
export const AUTHENTICATE_WITH_TOKEN_SUCCESS =
  'session.AUTHENTICATE_WITH_TOKEN_SUCCESS';
export const AUTHENTICATE_WITH_TOKEN_FAILURE =
  'session.AUTHENTICATE_WITH_TOKEN_FAILURE';

export const UPDATE_CURRENT_SELLER_ID = 'session.UPDATE_CURRENT_SELLER_ID';

const initialState = {
  error: null,

  loading: false,
  loaded: false,

  authenticating: false,
  deauthenticating: false,
  expired: false,

  currentSellerId: null,
  customerAccount: null,
};

const getSessionFromPayload = ({ authenticationMethod, entities }) => ({
  authenticationMethod,
  customerAccount: entities
    ? Object.values(entities.customerAccounts)[0].id
    : null,
});

const getCurrentSellerIdFromPayload = (payload) => {
  const { customerAccount: customerAccountId } = getSessionFromPayload(payload);
  if (!customerAccountId) {
    return null;
  }

  const customerAccount = payload.entities.customerAccounts[customerAccountId];
  const sellerIds = customerAccount?.sellers;

  if (!sellerIds || !sellerIds.length) {
    return null;
  }

  return sellerIds[0];
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case LOAD_SUCCESS: {
      const session = getSessionFromPayload(action.payload);
      const currentSellerId = getCurrentSellerIdFromPayload(action.payload);

      return {
        ...state,
        ...session,
        currentSellerId,
        error: null,
        loaded: true,
        loading: false,
      };
    }
    case LOAD_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: false,
      };

    case AUTHENTICATE:
      return {
        ...state,
        error: null,
        authenticating: true,
        user: null,
        authenticationMethod: 'login',
      };

    case AUTHENTICATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        authenticating: false,
      };

    case AUTHENTICATE_SUCCESS: {
      const authenticatedSession = getSessionFromPayload(action.payload);
      const currentSellerId = getCurrentSellerIdFromPayload(action.payload);

      return {
        ...state,
        ...authenticatedSession,
        authenticationMethod: 'login',
        authenticating: false,
        currentSellerId,
        error: null,
        otpIntermediaryToken: undefined,
        otpLastThreeNumbers: undefined,
        otpStep: false,
      };
    }

    case AUTHENTICATE_WITH_TOKEN:
      return {
        ...state,
        error: null,
        authenticating: true,
        user: null,
        authenticationMethod: 'token',
        tokenError: null,
      };

    case AUTHENTICATE_WITH_TOKEN_SUCCESS: {
      const session = getSessionFromPayload(action.payload);
      const currentSellerId = getCurrentSellerIdFromPayload(action.payload);

      return {
        ...state,
        ...session,
        authenticating: false,
        currentSellerId,
        error: null,
        loaded: true,
      };
    }

    case AUTHENTICATE_WITH_TOKEN_FAILURE: {
      return {
        ...state,
        authenticating: false,
        tokenError: action.tokenError,
      };
    }

    case DEAUTHENTICATE:
      return {
        ...state,
        error: null,
        deauthenticating: true,
      };

    case DEAUTHENTICATE_SUCCESS:
      return {};

    case DEAUTHENTICATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        deauthenticating: false,
      };

    // From the signup and activation store, log in
    case SIGNUP.SUCCESS:
    case ACTIVATE_ACCOUNT: {
      const session = getSessionFromPayload(action.payload);
      const currentSellerId = getCurrentSellerIdFromPayload(action.payload);

      return {
        ...state,
        ...session,
        authenticationMethod: 'login',
        currentSellerId,
        error: null,
        loading: false,
        loaded: true,
      };
    }

    case SESSION_EXPIRED:
      return {
        ...state,
        expired: true,
      };

    case UPDATE_CURRENT_SELLER_ID:
      return {
        ...state,
        currentSellerId: action.payload,
      };

    default:
      return state;
  }
};

export const load = (sellerId) => {
  const params = sellerId ? { seller_id: sellerId } : {};

  return {
    entityKey: 'customerAccount',
    normalize: true,
    promise: (client) => client('GET', '/api/session', params),
    type: [LOAD, LOAD_SUCCESS, LOAD_FAILURE],
  };
};

export const authenticateWithToken = (token) => async (dispatch) => {
  dispatch({ type: AUTHENTICATE_WITH_TOKEN });

  const response = await client(
    'post',
    '/api/session/authenticate_with_token',
    {
      token,
    },
    { raiseError: false }
  );

  if (response.error) {
    const getTokenError = () => {
      switch (response.statusCode) {
        case 401: {
          return 'expired_token';
        }
        case 404: {
          return 'invalid_token';
        }
        default: {
          return 'error';
        }
      }
    };

    dispatch({
      type: AUTHENTICATE_WITH_TOKEN_FAILURE,
      error: true,
      tokenError: getTokenError(),
    });
    return;
  }

  dispatch({
    entityKey: 'customerAccount',
    type: AUTHENTICATE_WITH_TOKEN_SUCCESS,
    normalize: true,
    ...response,
  });
};

export const disableTwoFactor = () => (dispatch) => {
  const handleSuccess = (response) => {
    const action = dispatch({
      type: DISABLE_TWO_FACTOR_SUCCESS,
      ...response,
    });
    dispatch(updateUserInState({ twoFactorEnabled: false }));

    return action;
  };

  dispatch({ type: DISABLE_TWO_FACTOR });

  return client('DELETE', `/api/users/disable_two_factor`).then(handleSuccess);
};

export const authenticateContact = (accessToken) => ({
  type: [AUTHENTICATE, AUTHENTICATE_SUCCESS, AUTHENTICATE_FAILURE],
  normalize: true,
  promise: (client) =>
    client('POST', '/api/session/authenticate_contact', { accessToken }),
});

export const deauthenticate = () => (dispatch) => {
  dispatch({ type: DEAUTHENTICATE });

  client('DELETE', '/api/session').then(
    (response) => {
      dispatch({ type: DEAUTHENTICATE_SUCCESS, ...response });
      dispatch(load());
    },
    (e) => {
      dispatch({ type: DEAUTHENTICATE_FAILURE, error: true, ...e });
    }
  );
};

export const updateCurrentSellerId = (currentSellerId) => ({
  type: UPDATE_CURRENT_SELLER_ID,
  payload: currentSellerId,
});

export const sessionExpired = () => ({ type: SESSION_EXPIRED });

export const activateAccount = (payload) => ({
  entityKey: 'customerAccount',
  type: ACTIVATE_ACCOUNT,
  normalize: true,
  payload,
});

export const authenticateSuccess = (payload) => ({
  entityKey: 'customerAccount',
  type: AUTHENTICATE_SUCCESS,
  normalize: true,
  payload,
});

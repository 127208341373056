import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { map } from 'lodash';

import * as I18n from 'shared/utils/I18n';
import * as routes from 'app/routes';
import ChevronLeft from 'svg/chevron_left.svg';
import ChevronRight from 'svg/chevron_right.svg';
import { frontpageRoute } from 'shared/routes';
import { useCustomerAccount, useCurrentSeller, useSellers } from 'shared/hooks';
import Logo from './Logo';
import Hamburger from './Hamburger';
import Cross from './Cross';

export interface LinkDefinition {
  icon?: string;
  path: string;
  title: string;
}

interface Props {
  links: LinkDefinition[];
}

const ns = 'app.nav';
const nsAccount = 'app.profilePage';

const NavigationBarApproved = ({ links }: Props) => {
  const customerAccount = useCustomerAccount();
  const mainSeller = useCurrentSeller();
  const sellers = useSellers();

  const accountSubLinks: LinkDefinition[] = [
    { title: I18n.nt(nsAccount, 'profile'), path: routes.PROFILE_PAGE },
    {
      title: I18n.nt(nsAccount, 'consents'),
      path: routes.PROFILE_PAGE_CONSENTS,
    },
    {
      title: I18n.nt(nsAccount, 'invoices'),
      path: routes.PROFILE_PAGE_INVOICES,
    },
    {
      title: I18n.nt(nsAccount, 'users'),
      path: routes.PROFILE_PAGE_USER_INFORMATION,
    },
    {
      title: I18n.nt(nsAccount, 'deliveries'),
      path: routes.PROFILE_PAGE_DELIVERIES,
    },
    {
      title: I18n.nt(nsAccount, 'service'),
      path: routes.PROFILE_PAGE_SERVICE,
    },
  ];
  if (sellers.some((seller) => seller.clientBankAccount)) {
    accountSubLinks.push({
      title: I18n.nt(nsAccount, 'exact_online_connection.title'),
      path: routes.PROFILE_PAGE_EXACT_ONLINE_CONNECTION,
    });
  }

  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const toggleHamburgerMenu = () => setHamburgerMenuOpen(!hamburgerMenuOpen);
  const toggleSubMenu = () => setSubMenuOpen(!subMenuOpen);

  const closeMenu = () => {
    setHamburgerMenuOpen(false);
    setSubMenuOpen(false);
  };
  const frontpageUrl = frontpageRoute(customerAccount, mainSeller, null);

  return (
    <>
      <nav className="DesktopOnly">
        <div className="tw-bg-floryn-bleen">
          <div className="container">
            <div className="tw-flex tw-h-[72px] tw-justify-between">
              <div className="tw-flex tw-items-center tw-gap-10">
                <Logo url={frontpageUrl} />
                {map(links, (link, index) => (
                  <Link
                    key={index}
                    to={link.path}
                    className="tw-navbar-link tw-navbar-hover"
                  >
                    <span
                      className={
                        link.icon && classNames('icon', `icon-${link.icon}`)
                      }
                    />
                    {link.title}
                  </Link>
                ))}
                {mainSeller.canViewFinancialDashboard && (
                  <Link
                    to={routes.DASHBOARD}
                    className="tw-navbar-link tw-navbar-hover"
                  >
                    <span className="icon icon-dashboard" />
                    {I18n.nt(ns, 'dashboard')}
                  </Link>
                )}
              </div>
              <div className="tw-flex tw-items-center tw-gap-10">
                <Link
                  to={routes.PROFILE_PAGE}
                  className="tw-navbar-link tw-navbar-hover"
                >
                  <span className="icon icon-user1" />
                  {I18n.nt(ns, 'account')}
                </Link>
                <Link
                  to={routes.LOGOUT}
                  className="tw-navbar-link tw-navbar-hover"
                >
                  <span className="icon icon-logout" />
                  {I18n.nt(ns, 'logout')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <nav className="MobileOnly">
        <div className="tw-bg-floryn-bleen">
          <div className="tw-mx-4 tw-flex tw-h-[60px] tw-items-center tw-justify-between sm:tw-mx-8">
            <Logo url={frontpageUrl} />
            <Hamburger onClick={toggleHamburgerMenu} />
            <div
              className={classNames(
                'tw-fixed tw-left-0 tw-top-0 tw-z-50 tw-h-screen tw-w-screen tw-flex-col tw-bg-floryn-bleen',
                hamburgerMenuOpen ? 'tw-flex' : 'tw-hidden'
              )}
            >
              <div className="tw-mx-4 tw-flex tw-h-[60px] tw-items-center tw-justify-between sm:tw-mx-8">
                <Logo url={frontpageUrl} />
                <Cross onClick={closeMenu} />
              </div>
              <div className="tw-flex tw-flex-col tw-divide-y tw-divide-[#798891]">
                {map(links, (link, index) => (
                  <Link
                    key={index}
                    to={link.path}
                    className="tw-navbar-link tw-p-4 tw-duration-0 active:tw-bg-[#364D5B] sm:tw-px-8"
                    onClick={closeMenu}
                  >
                    <span
                      className={
                        link.icon && classNames('icon', `icon-${link.icon}`)
                      }
                    />
                    {link.title}
                  </Link>
                ))}
                {mainSeller.canViewFinancialDashboard && (
                  <Link
                    to={routes.DASHBOARD}
                    className="tw-navbar-link tw-p-4 tw-duration-0 active:tw-bg-[#364D5B] sm:tw-px-8"
                    onClick={closeMenu}
                  >
                    <span className="icon icon-dashboard" />
                    {I18n.nt(ns, 'dashboard')}
                  </Link>
                )}
                <div>
                  <a
                    className="tw-navbar-link tw-flex tw-justify-between tw-p-4 tw-duration-0 active:tw-bg-[#364D5B] sm:tw-px-8"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleSubMenu();
                    }}
                    href="#"
                  >
                    <span>
                      <span className="icon icon-user1 tw-mr-2" />
                      {I18n.nt(ns, 'account')}
                    </span>
                    <ChevronRight className="tw-h-4 tw-w-4" />
                  </a>
                  <div
                    className={classNames(
                      'tw-absolute tw-left-0 tw-top-0 tw-mt-[60px] tw-flex tw-h-full tw-w-full tw-flex-col tw-divide-y tw-divide-[#798891] tw-bg-floryn-bleen tw-transition tw-duration-200',
                      subMenuOpen ? 'tw-translate-x-0' : 'tw-translate-x-full'
                    )}
                  >
                    <a
                      className="tw-navbar-link tw-p-4 tw-duration-0 active:tw-bg-[#364D5B] sm:tw-px-8"
                      onClick={toggleSubMenu}
                      href="#"
                    >
                      <ChevronLeft className="tw-h-4 tw-w-4" />
                      {I18n.nt(ns, 'back')}
                    </a>
                    {map(accountSubLinks, (link, index) => (
                      <Link
                        key={`account-sub-link-${index}`}
                        to={link.path}
                        className="tw-navbar-link tw-p-4 tw-duration-0 active:tw-bg-[#364D5B] sm:tw-px-8"
                        onClick={closeMenu}
                      >
                        {link.title}
                      </Link>
                    ))}
                  </div>
                </div>
                <Link
                  to={routes.LOGOUT}
                  className="tw-navbar-link tw-p-4 tw-duration-0 active:tw-bg-[#364D5B] sm:tw-px-8"
                >
                  <span className="icon icon-logout" />
                  {I18n.nt(ns, 'logout')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavigationBarApproved;

import React from 'react';
import { Redirect } from 'react-router';
import useFrontpageRoute from 'shared/utils/routing/useFrontpageRoute';

const RedirectToFrontpage = () => {
  const getFrontpageRoute = useFrontpageRoute();

  return <Redirect to={getFrontpageRoute()} />;
};

export default RedirectToFrontpage;

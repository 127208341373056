import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { scrollToTop } from 'shared/utils/scroll';
import { contractSelector } from 'shared/selectors';
import { SELLER_ONBOARDING } from 'app/routes';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import WithContract from './WithContract';
import Submitted from './Submitted';

import { Seller } from 'types';

const OnboardingStep = ({ deliveryRequests }) => {
  useEffect(scrollToTop, []);

  const seller = useCurrentSeller() as Seller;
  const contract = useSelector(contractSelector);

  return (
    <>
      {contract ? (
        <WithContract
          basePath={SELLER_ONBOARDING}
          contract={contract}
          deliveryRequests={deliveryRequests}
          seller={seller}
        />
      ) : (
        <Submitted
          basePath={SELLER_ONBOARDING}
          deliveryRequests={deliveryRequests}
          seller={seller}
        />
      )}
    </>
  );
};

export default OnboardingStep;

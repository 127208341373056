export enum Role {
  SHAREHOLDER = 'shareholder',
  SHAREHOLDER_AND_DIRECTOR = 'shareholder_and_director',
  DIRECTOR = 'director',
  OWNER = 'owner',
  PARTNER = 'partner',
  OTHER = 'other',
}

export interface UltimateBeneficialOwner {
  birthDate: string;
  countryOfResidence?: string;
  email?: string;
  firstName: string;
  id: number;
  identifyingPersonId?: number;
  lastName: string;
  onfidoIdentificationStatus?: 'not_started' | 'invitation_sent' | 'done';
  otherRole: string;
  role: Role;
  shareholderPercentage?: number;
}

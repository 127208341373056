import React from 'react';
import classNames from 'classnames';

import * as I18n from 'shared/utils/I18n';
import { isAlfa } from 'models/Seller';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import { Tabs } from 'shared/bootstrap';
import LoadingError from 'shared/components/LoadingError';
import AccountInformation from './profile/AccountInformation';
import UserInformation from './profile/UserInformation';
import FlorynInvoices from './profile/FlorynInvoices';
import ConsentsPage from './profile/ConsentsPage';
import ExactOnlineConnection from './profile/ExactOnlineConnection';
import DeliveriesPage from './profile/DeliveriesPage';
import useProfilePage from './useProfilePage';
import ServicePage from './profile/ServicePage';

const ns = 'app.profilePage';

const ProfilePage = () => {
  const {
    currentTab,
    displayExactOnlineTab,
    mainSeller,
    selectTab,
    sellers,
    status,
  } = useProfilePage();

  const tabs = [
    {
      title: I18n.nt(ns, 'profile'),
      key: 'profiel',
      content: <AccountInformation sellers={sellers} />,
    },
    {
      title: I18n.nt(ns, 'consents'),
      key: 'rekeningen',
      content: <ConsentsPage />,
    },
    {
      title: I18n.nt(ns, 'invoices'),
      key: 'facturen',
      content: <FlorynInvoices />,
    },
    {
      title: I18n.nt(ns, 'users'),
      key: 'gebruikers',
      content: <UserInformation />,
    },
    {
      title: I18n.nt(ns, 'deliveries'),
      key: 'deliveries',
      content: <DeliveriesPage />,
    },
    {
      title: I18n.nt(ns, 'service'),
      key: 'service',
      content: <ServicePage />,
    },
  ];

  if (displayExactOnlineTab) {
    tabs.push({
      title: I18n.nt(ns, 'exact_online_connection.title'),
      key: 'exact-online-koppeling',
      content: <ExactOnlineConnection />,
    });
  }

  if (status === 'error') {
    return (
      <div className="c-profile-page">
        <div className="profile-container error-message">
          <LoadingError />
        </div>
      </div>
    );
  }

  if (status !== 'loaded' || !mainSeller) {
    return <LoadingAnimation />;
  }

  return (
    <div
      className={classNames(
        'c-profile-page',
        !isAlfa(mainSeller) && 'tw-mt-4 md:tw-mt-8'
      )}
    >
      <div className="profile-container">
        <Tabs
          currentTab={currentTab}
          hideTabListOnMobile
          onSelectTab={selectTab}
          tabs={tabs}
        />
      </div>
    </div>
  );
};

export default ProfilePage;

import React, { SyntheticEvent, useEffect, useRef } from 'react';
import { Alert, Button, Modal, Well } from 'shared/bootstrap';

import { nt } from '../utils';
import TextInput from 'hookForm/TextInput';
import { trackFormSubmit } from 'shared/utils/tracker';
import useVerifyAccountDialog from './useVerifyAccountDialog';

const t = nt('signup.onboarding.verify_account');

const FlorynAccountInfo = () => (
  <Well className="VerifyBankAccount__description">
    <div className="VerifyBankAccount__iban">
      {t('dialog.description.iban')}
    </div>
    <div className="VerifyBankAccount__in-name-of">
      {t('dialog.description.in_name_of')}
    </div>
    <div className="VerifyBankAccount__name">
      {t('dialog.description.name')}
    </div>
  </Well>
);

const Description = ({
  isDigital,
  isValid,
}: {
  isDigital: boolean;
  isValid: boolean;
}) => {
  if (!isValid) {
    return null;
  }

  if (isDigital) {
    return <span>{t('dialog.description.digital')}</span>;
  }

  return (
    <div>
      <div>{t('dialog.description.analog')}</div>
      <FlorynAccountInfo />
    </div>
  );
};

const PendingCentVerification = () => (
  <div>
    <div>{t('dialog.confirmation_manual.intro')}</div>
    <FlorynAccountInfo />
    <div>{t('dialog.confirmation_manual.awaiting_verification')}</div>
  </div>
);

const SubmitButton = ({
  digitalBank,
  isSubmitting,
}: {
  digitalBank: boolean;
  isSubmitting: boolean;
}) => (
  <Button type="submit" className="button-primary" disabled={isSubmitting}>
    {digitalBank ? t('verify') : t('confirm_transfer')}
  </Button>
);

const CloseButton = ({ onHide }: Pick<Props, 'onHide'>) => (
  <Button onClick={onHide} className="button-light">
    {t('close')}
  </Button>
);

interface Props {
  initialValues: { iban?: string };
  serverError: string | null;
  onHide: () => void;
  onSubmit: (isDigitalBank: boolean) => void;
}

export default ({ initialValues, serverError, onSubmit, onHide }: Props) => {
  const {
    control,
    digitalBank,
    isSubmitting,
    isValid,
    submitForm,
    pendingCentVerification,
  } = useVerifyAccountDialog(initialValues);

  const submit = async (event: SyntheticEvent) => {
    event.preventDefault();

    await submitForm();
    onSubmit(digitalBank);
    trackFormSubmit('verify-account');
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <form onSubmit={submit}>
      <Modal.Header closeButton>
        <Modal.Title>{t('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {pendingCentVerification ? (
          <PendingCentVerification />
        ) : (
          <>
            {serverError && <Alert type="danger">{serverError}</Alert>}
            <TextInput
              autoFocus
              control={control}
              inputRef={inputRef}
              label={t('dialog.iban_label')}
              name="iban"
            />
            <Description isDigital={digitalBank} isValid={isValid} />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {pendingCentVerification || !isValid ? (
          <CloseButton onHide={onHide} />
        ) : (
          <SubmitButton isSubmitting={isSubmitting} digitalBank={digitalBank} />
        )}
      </Modal.Footer>
    </form>
  );
};

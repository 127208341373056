import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Partner, Review, ReduxState, Seller } from 'types';
import { currentSellerSelector } from 'shared/selectors';
import { LoginStatus } from './util';
import { load } from './modules/partnerSignupSlice';
import useAppDispatch from 'shared/hooks/useAppDispatch';

interface Result {
  loginStatus: LoginStatus;
  review: Review | null;
  partner: Partner | null;
  seller: Seller | null;
}

const usePartnerSignup = (): Result => {
  const dispatch = useAppDispatch();

  const authenticated = useSelector(
    (state: ReduxState) =>
      !state.session.authenticating && state.session.customerAccount
  );

  const loginStatus = useSelector((state: ReduxState) => {
    const error = state.session.tokenError;
    if (error) {
      return error as LoginStatus;
    }

    const loadStatus = state.partnerSignup.loadStatus;
    if (!authenticated || loadStatus === 'loading') {
      return 'loading';
    }

    if (loadStatus === 'error') {
      return 'error';
    }

    return 'logged_in';
  });

  const review = useSelector(
    (state: ReduxState) => state.partnerSignup.review as Review
  );

  const partner = useSelector(
    (state: ReduxState) => state.partnerSignup.partner as Partner
  );

  const seller = useSelector(currentSellerSelector) as Seller;

  useEffect(() => {
    if (authenticated) {
      dispatch(load());
    }
  }, [authenticated]);

  return {
    loginStatus,
    review,
    partner,
    seller,
  };
};

export default usePartnerSignup;

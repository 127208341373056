import React from 'react';
import { Link } from 'react-router-dom';
import { Glyphicon } from 'shared/bootstrap';
import { nt } from 'shared/utils/I18n';
import { ns } from './Questions/utils';

const BackButton = ({ to }) => (
  <Link
    to={to}
    className="btn btn-default btn-light-inverted tw-inline-flex tw-items-center tw-gap-[8px]"
  >
    <Glyphicon glyph="arrow-left" />
    {nt(ns, 'back')}
  </Link>
);

export default BackButton;

import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { unionWith } from 'lodash';
import { parseISO } from 'date-fns';

import { actionCreators } from 'app/components/seller/Invoice/module';
import * as routes from 'app/routes';

import { ContactsInputValue } from 'hookForm/ContactsInput/types';
import { ContactsInputContext } from 'hookForm/ContactsInput/ContactsInputContext';
import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';
import resolver from 'hookForm/resolver';

import { daysToEndOfMonth } from './invoiceFormUtil';

import useAppDispatch from 'shared/hooks/useAppDispatch';
import useAttachments from 'shared/hooks/useAttachments';
import useInvoiceContact from 'shared/hooks/useInvoiceContact';
import useSelectInvoice from 'shared/hooks/useSelectInvoice';
import updateEntities from 'shared/modules/updateEntities';
import { companyWithIdSelector } from 'shared/selectors';
import client from 'shared/utils/client';
import { compilePath } from 'shared/utils/routing';
import { trackFormSubmit } from 'shared/utils/tracker';
import {
  validateAmountGreaterThanOrEqualToGAmount,
  validateAmountGreaterThan,
  validateGreaterThanOrEqualTo,
  validateAmountLessThan,
  validateLessThanOrEqualTo,
  validateNumericality,
  validatePresence,
} from 'shared/utils/validation';
import { formatISODate } from 'shared/utils/Helpers';
import {
  Attachment,
  Company,
  Invoice,
  InvoiceContact,
  ReduxState,
} from 'types';

interface RouteParams {
  id: string;
  tabKey: string;
}

type LoadStatus = '' | 'loading' | 'loaded';

export interface FormValues {
  amount: string;
  dateSent: Date;
  debtor: Company;
  gAmount: string;
  invoiceContacts?: ContactsInputValue[];
  invoiceFile: Attachment[];
  paymentPeriod: string;
  reference: string;
  supportingFiles: Attachment[];
}

const compareInvoiceContacts = (
  contact1: InvoiceContact,
  contact2: InvoiceContact
) => {
  const emailA = contact1.email ?? '';
  const emailB = contact2.email ?? '';
  return emailA.localeCompare(emailB);
};

const validateForm = ({
  amount,
  dateSent,
  debtor,
  gAmount,
  invoiceFile,
  paymentPeriod,
  reference,
}: FormValues) => {
  const parsedAmount = parseFloat(amount);
  const parsedGAmount = parseFloat(gAmount) || 0;

  return {
    amount:
      validatePresence(parsedAmount) ||
      validateNumericality(parsedAmount) ||
      validateAmountGreaterThan(0)(parsedAmount) ||
      validateAmountLessThan(100_000_000)(parsedAmount) ||
      validateAmountGreaterThanOrEqualToGAmount(parsedAmount, parsedGAmount),
    dateSent: validatePresence(dateSent),
    debtor: validatePresence(debtor),
    gAmount:
      validateNumericality(parsedGAmount) ||
      validateAmountGreaterThanOrEqualToGAmount(parsedAmount, parsedGAmount),
    invoiceFile: validatePresence(invoiceFile),
    paymentPeriod:
      validatePresence(paymentPeriod) ||
      validateNumericality(paymentPeriod) ||
      validateGreaterThanOrEqualTo(0)(paymentPeriod) ||
      validateLessThanOrEqualTo(120)(paymentPeriod),
    reference: validatePresence(reference),
  };
};

const updateFormData = (data: FormValues) => ({
  ...data,
  dateSent: formatISODate(data.dateSent),
  supportingFiles: data.supportingFiles?.map((file) => file.id),
  invoiceFile: data.invoiceFile[0]?.id,
});

const useInvoiceForm = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);
  const [allDebtorContacts, setAllDebtorContacts] = useState<
    InvoiceContact[] | null
  >(null);
  const { id: invoiceIdParam } = useParams<RouteParams>();

  const invoice = useSelectInvoice(invoiceIdParam);
  const invoiceContacts = useInvoiceContact(invoice?.invoiceContacts);

  const debtor = useSelector((state: ReduxState) =>
    companyWithIdSelector(state, invoice?.debtor)
  );
  const invoiceFile = useAttachments([invoice?.invoiceFile]);
  const supportingFiles = useAttachments(invoice?.supportingFiles);

  const [loadContactsStatus, setLoadContactsStatus] = useState<LoadStatus>('');

  const defaultValues = invoice
    ? {
        amount: invoice.amount,
        dateSent: invoice.dateSent ? parseISO(invoice.dateSent) : undefined,
        debtor: { ...debtor },
        gAmount: invoice.gAmount,
        invoiceContacts: invoiceContacts,
        invoiceFile: invoiceFile,
        paymentPeriod: `${invoice.paymentPeriod}`,
        reference: invoice.reference,
        supportingFiles: supportingFiles,
      }
    : {
        dateSent: new Date(),
      };

  const {
    control,
    getValues,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = useForm<FormValues>({
    defaultValues: defaultValues,
    resolver: resolver(validateForm),
  });

  const contactsInputContext = useContext(ContactsInputContext);

  const amount = watch('amount');
  const gAmount = watch('gAmount');
  const isDebtorValid = !errors.debtor;

  const calculateEndOfMonth = () => {
    const { dateSent, paymentPeriod } = getValues();

    if (dateSent) {
      const newValue = daysToEndOfMonth(
        dateSent,
        paymentPeriod ? parseInt(paymentPeriod) : 0
      );

      setValue('paymentPeriod', `${newValue}`);
    }
  };

  const getDebtorId = (invoice) => {
    if (!invoice || invoice.debtor === null) {
      return undefined;
    }

    if (typeof invoice.debtor === 'number') {
      return invoice.debtor;
    }

    if (typeof invoice.debtor === 'object') {
      return invoice.debtor.id;
    }

    return undefined;
  };

  const loadDebtorContacts = async (invoice: Invoice) => {
    setLoadContactsStatus('loading');

    const debtorId = getDebtorId(invoice);
    const response = await client(
      'GET',
      `/api/invoice_contacts?debtor_id=${debtorId}`,
      {},
      { raiseError: false }
    );

    if (response.error) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        response,
        setError
      );
      setServerErrorMessages(generalErrorMessages);
    } else {
      const loadedInvoiceContacts = response.payload as InvoiceContact[];
      const sortedContacts = unionWith(
        loadedInvoiceContacts,
        invoiceContacts,
        (contact1, contact2) => contact1.id === contact2.id
      ).sort(compareInvoiceContacts);
      setAllDebtorContacts(sortedContacts);
    }

    setLoadContactsStatus('loaded');
  };

  useEffect(() => {
    if (invoice) {
      loadDebtorContacts(invoice);
    }
  }, []);

  const submitInvoiceform = async (values: FormValues) => {
    if (!invoice?.id) {
      const updatedFormData = updateFormData(values);

      const response = await client(
        'POST',
        '/api/invoices',
        { ...updatedFormData },
        { raiseError: false }
      );

      if (response.error) {
        const generalErrorMessages = handleFormErrorsFromResponse(
          response,
          setError
        );
        setServerErrorMessages(generalErrorMessages);
      } else {
        history.push(
          compilePath(routes.INVOICE, {
            id: `${response.payload.id}`,
          })
        );
      }
    } else {
      trackFormSubmit('invoice');
      const response = await client(
        'PUT',
        `/api/invoices/${invoice.id}`,
        updateFormData(values),
        { raiseError: false }
      );

      if (response.error) {
        const generalErrorMessages = handleFormErrorsFromResponse(
          response,
          setError
        );
        setServerErrorMessages(generalErrorMessages);
      } else {
        const updatedInvoice = response.payload as Invoice;
        await loadDebtorContacts(updatedInvoice);
        await dispatch(updateEntities({ ...updatedInvoice }));
        dispatch(actionCreators.saveInvoice(updatedInvoice));
      }
    }
  };

  const handleSubmitInvoiceForm = handleSubmit(submitInvoiceform);

  const submit = async () => {
    if (contactsInputContext?.submit) {
      const valid = await contactsInputContext.submit();

      if (!valid) {
        return;
      }
    }

    handleSubmitInvoiceForm();
  };

  return {
    allDebtorContacts,
    amount,
    calculateEndOfMonth,
    control,
    gAmount,
    invoice,
    isDebtorValid,
    isValid,
    serverErrorMessages,
    submit,
    submitEnabled: !(isSubmitting || loadContactsStatus === 'loading'),
  };
};

export default useInvoiceForm;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { nt } from 'shared/utils/I18n';
import * as routes from 'app/routes';
import { ReduxState } from 'types';
import IconPieLineGraphSvg from 'svg/icon_pie_line_graph.svg';
import ChevronRighthSvg from 'svg/chevron_right.svg';
import ShadowBox from 'shared/components/tailwind/ShadowBox';
import usePollFinancialDashboard from 'FinancialDashboard/usePollFinancialDashboard';

const ns = 'signup.onboarding.financial_dashboard_status';

const FinancialDashboardStatus = () => {
  const pollFinancialDashboard = usePollFinancialDashboard();
  const financialDashboardState = useSelector(
    (state: ReduxState) => state.financialDashboard
  );

  useEffect(() => {
    let isCancelled = false;

    pollFinancialDashboard(() => isCancelled);

    return () => {
      isCancelled = true;
    };
  }, [financialDashboardState.loadCounter]);

  if (financialDashboardState.status !== 'available') {
    return null;
  }

  return (
    <ShadowBox className="tw-flex tw-flex-col tw-justify-between tw-gap-5 tw-p-4 sm:tw-pb-5 sm:tw-pl-6 sm:tw-pr-8 sm:tw-pt-6">
      <div className="tw-flex tw-items-center tw-gap-4">
        <div>{nt(ns, 'intro')}</div>
        <div>
          <IconPieLineGraphSvg className="tw-h-12 tw-w-12 tw-text-floryn-blue" />
        </div>
      </div>
      <a
        className="tw-flex tw-items-center tw-gap-2 tw-font-bold tw-text-floryn-green"
        href={routes.FINANCIAL_DASHBOARD}
      >
        <span>{nt(ns, 'link')}</span>
        <ChevronRighthSvg className="tw-mt-px tw-h-3 tw-w-3" />
      </a>
    </ShadowBox>
  );
};

export default FinancialDashboardStatus;

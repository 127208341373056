import { Alert, Button } from 'shared/bootstrap';
import React from 'react';
import { nt, ut } from 'shared/utils/I18n';
import useConnection from './useConnection';

const ns = 'app.profilePage.exact_online_connection.connection_information';

const ConnectionInformation = () => {
  const { username, disconnect, disconnectStatus } = useConnection();

  const handleDisconnect = () => {
    if (confirm(nt(ns, 'confirm_disconnect'))) {
      disconnect();
    }
  };

  return (
    <div className="connection">
      <div className="title">
        <h4>{nt(ns, 'title')}</h4>
      </div>
      <div className="connection-info">
        <div className="connection-state">
          <i className="icon-checkmark" />
          {username}
        </div>
        <div>
          <Button
            className="button-light"
            onClick={handleDisconnect}
            disabled={disconnectStatus === 'updating'}
          >
            {nt(ns, 'remove_connection')}
          </Button>
        </div>
        {disconnectStatus === 'error' && (
          <Alert type="danger">{ut('common.server_error')}</Alert>
        )}
      </div>
    </div>
  );
};

export default ConnectionInformation;

import React from 'react';
import { Route } from 'react-router-dom';

import Action, { ActionButton } from '../Action';
import BankTransactionsImportModal from 'Onboarding/components/BankTransactionsImportModal';
import { OnboardingActionStatus } from 'types';
import { nt } from '../utils';

const t = nt('signup.onboarding.statements');

interface Props {
  basePath: string;
  status: OnboardingActionStatus;
}

const StatementsAction = ({ basePath, status }: Props) => {
  const statementsPath = `${basePath}/bankafschriften`;

  const Button = () => (
    <ActionButton to={statementsPath}>{t('button')}</ActionButton>
  );

  return (
    <>
      <Action
        button={Button}
        description={t('description')}
        path={statementsPath}
        status={status}
        title={t('title')}
      />
      <Route path={statementsPath}>
        <BankTransactionsImportModal />
      </Route>
    </>
  );
};

export default StatementsAction;

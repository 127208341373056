import React from 'react';
import { Button } from 'shared/bootstrap';
import Panel from './Panel';
import Badge from './Badge';
import * as DebtorList from './modules/DebtorList';
import { translate } from 'shared/utils/I18n';
import { toEuro, dateFormat } from 'shared/utils/Helpers';
import { preventDefault } from 'shared/utils/functions';

const t = translate('review_wizard.debtor_list_panel');

interface Props {
  debtorList: DebtorList.DebtorList;
  onOpenDebtorList: () => void;
}

const DebtorListPanel = ({ debtorList, onOpenDebtorList }: Props) => {
  if (!DebtorList.isApplicable(debtorList)) return null;

  const isValid = DebtorList.isValid(debtorList);
  let badge;
  let meta: string;
  let label: string;

  if (isValid) {
    meta = t('meta', {
      total: toEuro(debtorList.debtorListTotal),
      date: dateFormat(debtorList.debtorListDate),
    });
    label = t('edit');
    badge = <Badge status="ok" />;
  } else if (DebtorList.isInvalid(debtorList)) {
    meta = t('invalid');
    label = t('elaborate');
    badge = <Badge status="warning" />;
  } else {
    meta = t('description');
    label = t('provide');
    badge = <Badge status="pending" />;
  }

  return (
    <Panel
      className="rw-sidebar rw-sidebar--left rw-gap-12"
      data-testid="debtor-list-panel"
    >
      <div>{badge}</div>
      <div className="rw-sidebar rw-sidebar--right rw-items-center">
        <div>
          <div className="rw-panel__title">{t('debtor_list')}</div>
          {isValid && (
            <div>
              {meta} (
              <a href="#" onClick={preventDefault(onOpenDebtorList)}>
                bewerken
              </a>
              )
            </div>
          )}
          {isValid || <div>{meta}</div>}
        </div>
        {DebtorList.isValid(debtorList) || (
          <div>
            <Button className="button-light" onClick={onOpenDebtorList}>
              {label}
            </Button>
          </div>
        )}
      </div>
    </Panel>
  );
};

export default DebtorListPanel;

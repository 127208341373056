import { User } from 'types';
import updateEntities from './updateEntities';
import { customerAccountSelector } from 'shared/selectors';

const updateUserInState = (values: Partial<User>) => (dispatch, getState) => {
  const customerAccount = customerAccountSelector(getState());
  if (!customerAccount) {
    return;
  }

  const user = { ...customerAccount.user, ...values };

  dispatch(updateEntities({ ...customerAccount, user }));
};

export default updateUserInState;

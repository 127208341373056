import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'shared/components/tailwind/Button';
import PlusSvg from 'svg/plus.svg';
import { Translator } from 'types';
import { isCancelled } from 'models/Seller';
import Feedback from 'shared/components/Consent/Feedback';
import { MinimalSeller, Seller } from 'types/Seller';

interface Props {
  children: ReactNode;
  t: Translator;
  connectPath: string;
  seller: MinimalSeller | Seller;
}

const Layout = ({ t, children, connectPath, seller }: Props) => {
  const history = useHistory();

  const titleKey = seller.aisConnected ? 'accounts' : 'connect_accounts';
  const subTitleKey = seller.aisConnected ? 'manageAtBank' : 'psd2_intro_html';

  return (
    <div>
      <Feedback t={(key) => t(`consentFeedback.${key}`)} />
      <div className="sm:tw-flex sm:tw-items-center sm:tw-justify-between">
        <div>
          <h3>{t(titleKey)}</h3>
          <p className="tw-max-w-3xl">{t(subTitleKey)}</p>
        </div>
        <div className="tw-mt-3 tw-self-start sm:tw-mt-5">
          <Button
            className="button-primary"
            onClick={() => history.push(connectPath)}
            disabled={isCancelled(seller)}
          >
            <div className="tw-flex tw-items-center tw-gap-3">
              <PlusSvg className="tw-h-4" />
              {t('addAccount')}
            </div>
          </Button>
        </div>
      </div>
      <hr />
      {children}
    </div>
  );
};

export default Layout;

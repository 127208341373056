import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';

import { referrerSelector } from 'shared/selectors';
import Container from 'shared/components/tailwind/Container';
import useContactInformation from 'shared/hooks/useContactInformation';
import * as I18n from 'shared/utils/I18n';
import * as routes from 'app/routes';
import ReferrerBanner from './ReferrerBanner';

const ns = 'app.nav';

const Logo = () => {
  const today = new Date();
  const christmas = today.getMonth() === 11 && today.getDate() >= 14;
  const logo = christmas ? 'logo-christmas' : 'logo';

  return (
    <img alt="Floryn" src={`/images/${logo}.svg`} className="tw-w-[130px]" />
  );
};

const NavigationBarDraftAndSubmitted = () => {
  const { phoneLink, phoneNumber } = useContactInformation();
  const referrer = useSelector(referrerSelector);

  const session = useSelector((state: ReduxState) => state.session);
  const isLoggedIn = Boolean(session.customerAccount);

  return (
    <>
      {referrer && <ReferrerBanner referrer={referrer} />}
      <nav
        id="navigation-bar"
        className="tw-border-gray-300 tw-bg-white md:tw-border-b"
      >
        <Container>
          <div className="tw-flex tw-min-h-[80px] tw-items-center tw-justify-between">
            <Link to={routes.APP_ROOT}>
              <Logo />
            </Link>
            <div className="tw-flex tw-flex-col tw-items-center md:tw-flex-row md:tw-gap-2">
              <span>{I18n.nt(ns, 'questions')}</span>
              <a
                className="tw-mt-px tw-font-heading tw-font-bold tw-text-black"
                href={phoneLink}
              >
                {I18n.nt(ns, 'call')} {phoneNumber}
              </a>
            </div>
            {isLoggedIn && (
              <Link
                to={routes.LOGOUT}
                data-testid="logout-link"
                className="button-light button-small tw-flex tw-h-fit"
              >
                <span className="icon icon-logout tw-p-0.5" />
                <div className="DesktopOnly tw-ml-1">
                  {I18n.nt(ns, 'logout')}
                </div>
              </Link>
            )}
          </div>
        </Container>
      </nav>
    </>
  );
};

export default NavigationBarDraftAndSubmitted;

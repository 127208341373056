import { combineReducers } from 'redux';
import sharedReducers from 'shared/reducers';
import session from './modules/session';

import loadEntities from 'shared/redux/loadEntities';
import { checkBundleVersionReducer } from 'shared/redux/middleware/checkBundleVersion';

const entities = [
  'attachments',
  'contracts',
  'customerAccounts',
  'invoiceContacts',
  'invoices',
  'sellers',
  'users',
  'improvedContracts',
];

const entityReducers = entities.reduce(
  (reducers, entityName) => ({
    ...reducers,
    [entityName]: (state = {}, action = {}) =>
      loadEntities(entityName, state, action),
  }),
  {}
);

export default {
  shared: combineReducers(sharedReducers),
  session,
  entities: combineReducers(entityReducers),
  version: checkBundleVersionReducer,
};

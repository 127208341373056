import React, { useState, ReactNode } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Modal } from 'shared/bootstrap';

import { OnboardingActionStatus } from 'types';

interface ActionProps {
  button: React.ElementType | null;
  description: React.ReactNode;
  errorMessage?: string;
  onClickButton?: (e: any) => void;
  path?: string;
  status: OnboardingActionStatus;
  title: string;
}

const Action = ({
  button,
  description,
  errorMessage,
  onClickButton,
  path,
  status,
  title,
}: ActionProps) => {
  const history = useHistory();
  const handleClick = (e) => {
    if (onClickButton) {
      onClickButton(e);
    } else if (path) {
      history.push(path);
    }
  };

  const bodyProps = { button, description, errorMessage, status, title };

  if (status === 'Pending') {
    return <ActionBody {...bodyProps} />;
  } else {
    return (
      <>
        <div className="DesktopOnly">
          <ActionBody {...bodyProps} />
        </div>
        <div className="MobileOnly" onClick={handleClick}>
          <ActionBody {...bodyProps} />
        </div>
      </>
    );
  }
};

interface ActionBodyProps {
  button: React.ElementType | null;
  description: React.ReactNode;
  errorMessage?: string;
  status: OnboardingActionStatus;
  title: string;
}

const ActionBody = ({
  button: Button,
  description,
  errorMessage,
  status,
  title,
}: ActionBodyProps) => {
  return (
    <div className={`SellerAction SellerAction--${status}`}>
      <div className="SellerActionStatus">
        <span className={`SellerActionStatus${status}`} />
      </div>
      <div className="SellerActionText">
        <div className="SellerActionTitle">{title}</div>
        {errorMessage && <span className="text-danger">{errorMessage}</span>}
        <div className="SellerActionDescription">{description}</div>
      </div>
      <div className="SellerActionButton">{Button && <Button />}</div>
    </div>
  );
};

interface ActionButtonProps {
  children: ReactNode;
  to: string;
}

export const ActionButton = ({ children, to }: ActionButtonProps) => (
  <Link className="button-primary-inverted button-small tw-min-w-36" to={to}>
    {children}
  </Link>
);

interface ActionClosingModalProps {
  children: ReactNode;
  basePath: string;
  className?: string;
}

export const ActionClosingModal = ({
  basePath,
  children,
  className,
}: ActionClosingModalProps) => {
  const [closed, setClosed] = useState(false);

  if (closed) {
    return <Redirect to={basePath} />;
  }

  const onHide = () => setClosed(true);

  return (
    <Modal show onHide={onHide} className={className}>
      {children}
    </Modal>
  );
};

export default Action;

import { useForm, useWatch } from 'react-hook-form';

import resolver from 'hookForm/resolver';
import { validatePresence } from 'shared/utils/validation';
import { updateCustomerSignup } from 'signup/actions/CustomerSignup';
import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { SELLER_ONBOARDING } from 'app/routes';

interface FormValues {
  google: boolean;
  instagram: boolean;
  linkedin: boolean;
  radio: boolean;
  outdoorAd: boolean;
  newspaperMagazine: boolean;
  tv: boolean;
  youtube: boolean;
  podcast: boolean;
  email: boolean;
  facebook: boolean;
  event: boolean;
  financialAdvisor: boolean;
  onlineAd: boolean;
  wordOfMouth: boolean;
  other: boolean;
  otherReferral: string;
}

export default () => {
  const dispatch = useAppDispatch();
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  const validate = ({ other, otherReferral }: FormValues) => ({
    otherReferral: other ? validatePresence(otherReferral) : null,
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      google: false,
      instagram: false,
      linkedin: false,
      radio: false,
      outdoorAd: false,
      newspaperMagazine: false,
      tv: false,
      youtube: false,
      podcast: false,
      email: false,
      facebook: false,
      event: false,
      financialAdvisor: false,
      onlineAd: false,
      wordOfMouth: false,
      other: false,
    },
    resolver: resolver(validate),
  });

  const other = useWatch({ control, name: 'other' });
  const referralSources = Object.entries(watch())
    .filter(([key, value]) => value === true && key !== 'other')
    .map(([key]) => key);

  const submit = async (values: FormValues) => {
    const selectedReferrals = Object.entries(values)
      .filter(([key, value]) => value === true && key !== 'other')
      .map(([key]) => key);

    if (values.otherReferral) {
      selectedReferrals.push(`other: ${values.otherReferral}`);
    }

    const response = await dispatch(
      updateCustomerSignup({
        customerSignup: { referral: selectedReferrals },
      })
    );

    if (response.error) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        response,
        setError
      );
      setServerErrorMessages(generalErrorMessages);
    } else {
      history.push(SELLER_ONBOARDING);
    }
  };

  return {
    control,
    isSubmitting,
    other,
    referralSources,
    serverErrorMessages,
    submitForm: handleSubmit(submit),
    watch,
  };
};

import client, { Result } from 'shared/utils/client';
import * as DebtorList from './DebtorList';
import * as ReviewSheet from '../modules/ReviewSheet';

type FailedValidationReason =
  | 'iban_task'
  | 'iban_task_with_override'
  | 'debtor_list'
  | 'iban_task_and_debtor_list';

type ValidationResult =
  | { type: 'valid' }
  | {
      type: 'invalid';
      reason: FailedValidationReason;
    };

export type SubmitInput = {
  debtorList: DebtorList.DebtorList;
  reviewSheet: ReviewSheet.ReviewSheet;
  sellerComment: string;
  sellerId: number;
};

export type FailedSubmitReason = FailedValidationReason | 'server' | 'other';

export type SubmitStatus =
  | { type: 'notStarted' }
  | { type: 'failed'; reason: FailedSubmitReason }
  | { type: 'pending' };

export function submit(submitInput: SubmitInput): Promise<Result> {
  return client('POST', '/charlie/review/submit', getSubmitData(submitInput));
}

export function validateSubmittable({
  debtorList,
  reviewSheet,
}: SubmitInput): ValidationResult {
  const invalidReviewSheet = !ReviewSheet.isSubmittable(reviewSheet);
  const invalidDebtorList = !DebtorList.isSubmittable(debtorList);

  if (invalidReviewSheet && invalidDebtorList) {
    return { type: 'invalid', reason: 'iban_task_and_debtor_list' };
  } else if (invalidReviewSheet && ReviewSheet.isOverridable(reviewSheet)) {
    return { type: 'invalid', reason: 'iban_task_with_override' };
  } else if (invalidReviewSheet) {
    return { type: 'invalid', reason: 'iban_task' };
  } else if (invalidDebtorList) {
    return { type: 'invalid', reason: 'debtor_list' };
  } else {
    return { type: 'valid' };
  }
}

function getSubmitData({
  debtorList,
  reviewSheet,
  sellerComment,
  sellerId,
}: SubmitInput): object {
  return {
    ...DebtorList.getSubmitData(debtorList),
    ...ReviewSheet.getSubmitData(reviewSheet),
    commentsBySeller: sellerComment,
    sellerId,
  };
}

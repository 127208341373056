import React, { useState } from 'react';

import { Button, Modal } from 'shared/bootstrap';
import OnfidoModal from 'shared/components/OnfidoModal';
import { capitalizeFirstLetter } from 'shared/utils/Helpers';
import { nt } from 'Onboarding/components/utils';
import { UltimateBeneficialOwner } from 'types';
import TextInput from 'hookForm/TextInput';
import GeneralErrors from 'hookForm/GeneralErrors';
import useInvitationStep from './useInvitationStep';
import useOnfidoModal from './useOnfidoModal';

const t = nt('signup.onboarding.ultimate_beneficial_owners.ubo_form');

interface Props {
  ubo: UltimateBeneficialOwner;
  updateUboInState: (ubo: UltimateBeneficialOwner) => void;
  resetActionAndUbo: () => void;
}

export default ({ ubo, updateUboInState, resetActionAndUbo }: Props) => {
  const [showOnfidoModal, setShowOnfidoModal] = useState(false);
  const { cancel, control, isSubmitting, serverErrorMessages, submit } =
    useInvitationStep({
      ubo,
      updateUboInState,
      resetActionAndUbo,
    });
  const {
    completeSubmission,
    onClose: onModalClose,
    phone,
    identifyingPerson,
    status: onfidoModalStatus,
  } = useOnfidoModal({ ubo, updateUboInState, resetActionAndUbo });

  const name = capitalizeFirstLetter(ubo.firstName);
  const onOnfidoModalClose = () => {
    setShowOnfidoModal(false);
    onModalClose();
  };

  return (
    <>
      <form onSubmit={submit}>
        <Modal.Header closeButton>
          <Modal.Title>{t('title.invite', { name })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('body.intro', { name })}</p>
          {ubo.identifyingPersonId && (
            <p>
              <a
                className="tw-cursor-pointer tw-text-black tw-underline"
                onClick={() => setShowOnfidoModal(true)}
              >
                {t('this_is_me', { name })}
              </a>
            </p>
          )}
          <GeneralErrors generalErrors={serverErrorMessages} />
          <TextInput
            autoFocus
            control={control}
            label="E-mailadres"
            name="email"
            type="email"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="button-light pull-left" onClick={cancel}>
            {t('not_now')}
          </Button>
          <Button
            type="submit"
            className="button-primary"
            disabled={isSubmitting}
          >
            {t('invite')}
          </Button>
        </Modal.Footer>
      </form>
      {showOnfidoModal && (
        <OnfidoModal
          closeModal={onOnfidoModalClose}
          identifyingPerson={identifyingPerson}
          status={onfidoModalStatus}
          phone={phone}
          onComplete={completeSubmission}
        />
      )}
    </>
  );
};

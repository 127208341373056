import React from 'react';
import { Row } from 'shared/bootstrap';
import Button from 'shared/components/tailwind/Button';
import * as Helpers from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import { isExactInvoice } from 'shared/utils/invoice';
import InvoiceCostsSummary from 'shared/components/InvoiceCostsSummary';
import SubmittedAlert from './InvoiceSubmittedAlert';
import FinancedAlert from './InvoiceFinancedAlert';
import GAmountSummary from './InvoiceGAmountSummary';
import InvoiceAttachments from './InvoiceAttachments';
import InvoiceSubmission from './InvoiceSubmission';
import InvoiceContacts from './InvoiceContacts';
import useInvoiceSummary from './useInvoiceSummary';

import { Invoice, InvoiceContact } from 'types';

const ns = 'app.invoiceSummary';

interface Props {
  invoice: Invoice;
  invoiceContactsMap: Record<number, InvoiceContact>;
}

const InvoiceSummary = ({ invoice, invoiceContactsMap }: Props) => {
  const {
    control,
    mailContact,
    onMailInvoiceContact,
    performingAction,
    readonly,
    seller,
    showAlert,
    showForm,
    submitInvoice,
    submitInvoiceWithoutValidation,
  } = useInvoiceSummary();
  const debtor = invoice.debtor;

  const mustDisplayAddMoreButton =
    isExactInvoice(invoice) && invoice.state === 'draft';

  const allowedToSendInvoices =
    seller.sendInvoices && invoice.invoiceContacts.length > 0;

  return (
    <div className="InvoiceSummary">
      <SubmittedAlert
        requestedFinancing={invoice.requestedFinancing}
        allowedToSendInvoices={allowedToSendInvoices}
        showAlert={showAlert}
      />
      <FinancedAlert invoice={invoice} showAlert={showAlert} />

      <div className="fieldset">
        <dl>
          <Row>
            <div>
              <dt>{I18n.attribute('invoice', 'amount')}</dt>
              <dd>{Helpers.toEuro(invoice.amount)}</dd>
            </div>
            <div>
              <dt>{I18n.attribute('invoice', 'reference')}</dt>
              <dd>{invoice.reference}</dd>
            </div>
          </Row>
          <Row>
            <div>
              <dt>{I18n.attribute('invoice', 'dateSent')}</dt>
              <dd>{Helpers.dateFormat(invoice.dateSent)}</dd>
            </div>
            <div>
              <dt>{I18n.attribute('invoice', 'paymentPeriod')}</dt>
              <dd>{invoice.paymentPeriod} dagen</dd>
            </div>
          </Row>
          {seller.useGAccount ? (
            <GAmountSummary amount={invoice.amount} gAmount={invoice.gAmount} />
          ) : null}
        </dl>
      </div>

      <hr />

      <div className="fieldset">
        <h4>{I18n.nt(ns, 'attachmentFields.title')}</h4>

        <dl className="attachments">
          <dt>{I18n.nt(ns, 'attachmentFields.invoiceToFinance')}</dt>
          <dd>
            <InvoiceAttachments attachments={[invoice.invoiceFile]} />
          </dd>

          {invoice.supportingFiles.length === 0 ? null : (
            <div>
              <dt>{I18n.attribute('invoice', 'supportingFiles')}</dt>
              <dd>
                <InvoiceAttachments attachments={invoice.supportingFiles} />
              </dd>
            </div>
          )}
        </dl>

        {mustDisplayAddMoreButton && (
          <Button className="back" onClick={showForm}>
            {I18n.nt(ns, 'attachmentFields.addMoreAttachments')}
          </Button>
        )}
      </div>

      <hr />

      <div className="fieldset">
        <h4>{I18n.nt(ns, 'debtorFields.title')}</h4>

        <dl>
          {allowedToSendInvoices && (
            <dt>{I18n.nt(ns, 'debtorFields.company')}</dt>
          )}
          {debtor ? (
            <dd>
              {debtor.name}
              <br />
              {debtor.street} {debtor.houseNumber}
              <br />
              {debtor.postalCode} {debtor.city}
              <br />
              {I18n.attribute('company', 'kvk')} {debtor.kvk}
            </dd>
          ) : (
            <dd>-</dd>
          )}

          {allowedToSendInvoices && (
            <div>
              <dt>{I18n.nt(ns, 'debtorFields.contacts')}</dt>
              <dd className="contacts">
                <InvoiceContacts
                  invoice={invoice}
                  invoiceContactIds={invoice.invoiceContacts}
                  invoiceContactsMap={invoiceContactsMap}
                  mailContact={mailContact}
                  onMailInvoiceContact={onMailInvoiceContact}
                  readonly={readonly}
                />
              </dd>
            </div>
          )}
        </dl>
      </div>

      <hr />

      <InvoiceCostsSummary invoice={invoice} seller={seller} />
      {!readonly && (
        <InvoiceSubmission
          control={control}
          invoice={invoice}
          performingAction={performingAction}
          seller={seller}
          showForm={showForm}
          submitInvoice={submitInvoice}
          submitInvoiceWithoutValidation={submitInvoiceWithoutValidation}
        />
      )}
    </div>
  );
};

export default InvoiceSummary;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'shared/bootstrap';
import { Link } from 'react-router-dom';
import * as I18n from 'shared/utils/I18n';
import * as routes from 'app/routes';
import { compilePath } from 'shared/utils/routing';
import { SUBMITTED_ALERT } from '../module';

class SubmittedAlert extends Component {
  static i18nNamespace = 'app.invoiceSummary.submittedAlert';

  static propTypes = {
    allowedToSendInvoices: PropTypes.bool.isRequired,
    requestedFinancing: PropTypes.bool.isRequired,
    showAlert: PropTypes.string,
  };

  render() {
    const { showAlert, requestedFinancing, allowedToSendInvoices } = this.props;

    if (showAlert !== SUBMITTED_ALERT) return null;

    const scope = requestedFinancing
      ? 'financed'
      : allowedToSendInvoices
        ? 'notFinanced'
        : 'notFinancedAndNotSent';

    return (
      <Alert type="info">
        <h3>{I18n.nt(this, `${scope}.title`)}</h3>
        {I18n.nt(this, `${scope}.bodyHtml`)}
        <Link
          to={compilePath(routes.INVOICE, { id: 'new' })}
          className="button-primary !tw-text-white"
        >
          {I18n.nt(this, 'uploadAnotherInvoice')}
        </Link>
      </Alert>
    );
  }
}

export default SubmittedAlert;

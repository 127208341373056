import React from 'react';
import { Modal } from 'shared/bootstrap';

import * as I18n from 'shared/utils/I18n';
import { toEuro } from 'shared/utils/Helpers';
import useIsComplexAccount from 'shared/hooks/useIsComplexAccount';
import Button from 'shared/components/tailwind/Button';
import CurrencyInput from 'hookForm/CurrencyInput';
import TextArea from 'hookForm/TextArea';
import UploadBankstatementLink from 'charlie/components/DashboardAlerts/UploadBankstatementLink';
import GeneralErrors from 'hookForm/GeneralErrors';
import useIncreaseLimit from './useIncreaseLimit';

const ns = 'charlie.frontpage.increase_limit_modal';

interface Props {
  parentPath: string;
}

const IncreaseLimitModal = ({ parentPath }: Props) => {
  const {
    close,
    control,
    creditLimit,
    handleSubmit,
    increment,
    isSubmitting,
    seller,
    serverErrorMessages,
    status,
  } = useIncreaseLimit(parentPath);

  const complexAccount = useIsComplexAccount();
  const companyName = seller.companyName || '';

  const renderIncreaseForm = (
    <>
      <p>{I18n.nt(ns, 'intro')}</p>
      <p>{I18n.nt(ns, 'current_value_html', { value: toEuro(creditLimit) })}</p>
      <form onSubmit={handleSubmit} autoComplete="off">
        <GeneralErrors generalErrors={serverErrorMessages} />
        <CurrencyInput
          autoFocus
          control={control}
          label={I18n.nt(ns, 'amount_label')}
          name="amount"
        />
        {increment > 0 && (
          <p className="tw-mb-6">
            {I18n.nt(ns, 'increment_html', { increment: toEuro(increment) })}
          </p>
        )}
        <TextArea
          control={control}
          label={I18n.nt(ns, 'comment_label')}
          name="comment"
        />
        <div className="inner-modal-footer">
          <Button
            type="submit"
            className="button-primary"
            disabled={isSubmitting}
          >
            {I18n.nt(ns, 'action')}
          </Button>
        </div>
      </form>
    </>
  );

  const renderOpenReviewText = (
    <>
      <p>{I18n.nt(ns, 'open_review_html')}</p>
      <div className="inner-modal-footer">
        <UploadBankstatementLink asButton={true} />
      </div>
    </>
  );

  const renderSuccessText = (
    <>
      <p>{I18n.nt(ns, 'success')}</p>
      <div className="inner-modal-footer">
        <Button onClick={close} className="button-primary">
          {I18n.nt(ns, 'close')}
        </Button>
      </div>
    </>
  );

  return (
    <Modal show onHide={close} className="IncreaseLimitModal">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
        {complexAccount && <h5 className="subtitle">{companyName}</h5>}
      </Modal.Header>
      <Modal.Body>
        {status === 'open_review' && renderOpenReviewText}
        {status === 'success' && renderSuccessText}
        {status === '' && renderIncreaseForm}
      </Modal.Body>
    </Modal>
  );
};

export default IncreaseLimitModal;

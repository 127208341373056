import React from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';

import { pushToPath } from 'shared/utils/routing';
import OnfidoModal from 'shared/components/OnfidoModal';
import Action, { ActionButton } from '../Action';
import { OnboardingActionStatus } from 'types';
import useLoadFullSeller from 'shared/hooks/useLoadFullSeller';
import useVerifyIdentity from './useVerifyIdentity';

import {
  SELLER_ONBOARDING,
  SELLER_ONBOARDING_VERIFY_IDENTITY,
} from 'app/routes';
import { nt } from '../utils';

const t = nt('signup.onboarding.verify_identity.action');

interface Props {
  status: OnboardingActionStatus;
}

export default ({ status }: Props) => {
  const dispatch = useDispatch();
  const loadFullSeller = useLoadFullSeller();
  const {
    completeSubmission,
    phone,
    identifyingPerson,
    status: modalStatus,
  } = useVerifyIdentity();

  const closeModal = () => {
    loadFullSeller(); // so we update action status
    dispatch(pushToPath(SELLER_ONBOARDING));
  };
  const Button = () => (
    <ActionButton to={SELLER_ONBOARDING_VERIFY_IDENTITY}>
      {t('button')}
    </ActionButton>
  );

  return (
    <>
      <Action
        button={status === 'Todo' ? Button : null}
        description={t(status.toLowerCase() + '_description')}
        path={status === 'Todo' ? SELLER_ONBOARDING_VERIFY_IDENTITY : undefined}
        status={status}
        title={t('title')}
      />
      <Route path={SELLER_ONBOARDING_VERIFY_IDENTITY}>
        <OnfidoModal
          closeModal={closeModal}
          status={modalStatus}
          phone={phone}
          identifyingPerson={identifyingPerson}
          onComplete={completeSubmission}
        />
      </Route>
    </>
  );
};

import { schema } from 'normalizr';
import { nanoid } from 'nanoid';

const generateOrReturnUid = (entity) => {
  if (!entity.uid) {
    entity.uid = nanoid();
  }

  return entity.uid;
};

// Resource schemas
export const Account = new schema.Entity('accounts');
export const Attachment = new schema.Entity('attachments');
export const BankEntry = new schema.Entity('bankEntries');
export const CampaignCompany = new schema.Entity('campaignCompanies');
export const Company = new schema.Entity('companies');
export const Contact = new schema.Entity('contacts');
export const Contract = new schema.Entity('contracts');
export const CustomerAccount = new schema.Entity('customerAccounts');
export const Email = new schema.Entity('emails');
export const Import = new schema.Entity('imports'); // Import is a reserved keyword
export const Invoice = new schema.Entity('invoices');
export const InvoiceContact = new schema.Entity('invoiceContacts');
export const Mutation = new schema.Entity('mutations');
export const Repayment = new schema.Entity('repayments');
export const Review = new schema.Entity('reviews');
export const Seller = new schema.Entity('sellers');
export const SignupDebtor = new schema.Entity('signupDebtors');
export const User = new schema.Entity('users');
export const ImprovedContract = new schema.Entity('improvedContracts');

SignupDebtor.define({
  company: Company,
});

Seller.define({
  bankStatementAttachments: [Attachment],
  company: Company,
  contract: Contract,
  improvedContracts: [ImprovedContract],
  signedContractFiles: [Attachment],
  signupDebtors: [SignupDebtor],
});

Invoice.define({
  seller: Seller,
  debtor: Company,
  email: [Email],
  invoiceContacts: [InvoiceContact],
  invoiceFile: Attachment,
  supportingFiles: [Attachment],
});

Review.define({
  debtorLists: [Attachment],
  bankStatementAttachments: [Attachment],
});

Account.define({
  debtor: Company,
});

Contact.define({
  invoiceContacts: [InvoiceContact],
});

// Pseudo-resource schemas (non-database backed, but still uniquely identified)
export const CandidateCompany = new schema.Entity(
  'candidateCompanies',
  {},
  { idAttribute: 'key' }
);

// Non-resource schemas
export const CompanySearch = new schema.Entity(
  'companySearches',
  {},
  {
    idAttribute: generateOrReturnUid,
  }
);

CustomerAccount.define({
  sellers: [Seller],
});

CompanySearch.define({
  companies: [Company],
  candidateCompanies: [CandidateCompany],
});

CampaignCompany.define({
  company: Company,
});

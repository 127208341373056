import React from 'react';

import ContainerWithoutPadding from 'shared/components/tailwind/ContainerWithoutPadding';
import Container from 'shared/components/tailwind/Container';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import useOnboardingUser from './useOnboardingUser';
import { capitalizeFirstLetter } from 'shared/utils/Helpers';
import { t } from './utils';
import VerifyIdentity from './VerifyIdentity';
import ErrorPage from './ErrorPage';
import Page from './Page';
import ExpiredTokenPage from './ExpiredTokenPage';

export default () => {
  const onboardingUserResult = useOnboardingUser();
  const { status } = onboardingUserResult;

  switch (status) {
    case 'failure':
      return (
        <ErrorPage>
          <div>{t('error.generic_message_1')}</div>
          <div>{t('error.generic_message_2')}</div>
        </ErrorPage>
      );

    case 'expired_token':
      return <ExpiredTokenPage canReset={onboardingUserResult.canReset} />;

    case 'pending':
    case 'not_asked':
      return (
        <Page>
          <LoadingAnimation />
        </Page>
      );

    case 'success': {
      const { mainApplicant, name, company, identifyingPerson } =
        onboardingUserResult.payload;

      return (
        <Page>
          <ContainerWithoutPadding>
            <div className="tw-mb-6 tw-bg-[#e0f0da] tw-px-4 tw-py-4 sm:tw-px-8 md:tw--mx-8 md:tw-mb-10 md:tw-mt-7 md:tw-py-10">
              <div className="tw-mb-2 tw-font-heading tw-text-lg tw-font-bold md:tw-text-bootstrap-h3">
                {t('welcome_banner.title', {
                  name: capitalizeFirstLetter(name),
                })}
              </div>
              <div className="tw-text-lg md:tw-text-2xl">
                {t('welcome_banner.message', {
                  mainApplicant: capitalizeFirstLetter(mainApplicant),
                  company,
                })}
              </div>
            </div>
          </ContainerWithoutPadding>
          <Container>
            <div className="tw-pb-4 md:tw-w-4/5">
              <h3 className="tw-mb-2 tw-text-lg md:tw-text-bootstrap-h3">
                {t('intro.title')}
              </h3>
              {t('intro.message', {
                mainApplicant: capitalizeFirstLetter(mainApplicant),
                company,
              })}
              <div className="tw-mt-6">
                <VerifyIdentity identifyingPerson={identifyingPerson} />
              </div>
            </div>
          </Container>
        </Page>
      );
    }

    default:
      return null;
  }
};

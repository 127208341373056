import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEnabled } from 'models/InstantPaymentAvailability';
import { MinimalSeller, ReduxState } from 'types';
import { useCurrentSeller } from 'namespaces/shared/hooks';
import { calculateNewDebtCollectionAmount } from 'charlie/model';
import { instantPaymentAvailability } from 'models/Seller';

const useWithdrawStatus = (parentPath: string) => {
  const history = useHistory();
  const seller = useCurrentSeller() as MinimalSeller;
  const withdrawalWillRequireApproval = seller.withdrawalWillRequireApproval;
  const instantPayment = instantPaymentAvailability(seller);

  const linkToAutomaticWithdrawal = () =>
    history.push(parentPath + '/automatisch-opnemen');

  const getCurrentHour = () => new Date().getHours();

  const automaticWithdrawalVisible = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard?.payload?.automaticWithdrawalPermitted ?? false
  );

  // Debt collection for new withdrawal
  const debtCollectionForNewWithdrawal = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.debtCollectionForNewWithdrawal
  );

  // Debt collection rate
  const debtCollectionRateFromState = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.debtCollectionRate || ''
  );
  const debtCollectionRate = parseFloat(debtCollectionRateFromState || '');

  // Minimum withdrawal amount
  const minimumWithdrawalAmountFromState = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.minimumWithdrawalAmount
  );
  const minimumWithdrawalAmount = parseFloat(
    minimumWithdrawalAmountFromState || ''
  );

  // Pending current limit
  const pendingCurrentLimitFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.pendingCurrentLimit
  );
  const pendingCurrentLimit = parseFloat(pendingCurrentLimitFromState || '');

  // Requested amount
  const requestedAmountFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.requestedAmount
  );
  const requestedAmount = parseFloat(requestedAmountFromState || '');

  const currentDebtCollectionAmount = debtCollectionForNewWithdrawal
    ? parseFloat(debtCollectionForNewWithdrawal?.amount)
    : 0;

  const newDebtCollectionAmount = calculateNewDebtCollectionAmount({
    currentDebtCollectionAmount,
    debtCollectionRate: debtCollectionRate,
    minimumWithdrawalAmount: minimumWithdrawalAmount,
    pendingCurrentLimit: pendingCurrentLimit,
    requestedAmount: requestedAmount,
    withdrawAmount: 0,
  });

  return {
    automaticWithdrawalVisible,
    isInstantPayment:
      isEnabled(instantPayment) && !withdrawalWillRequireApproval,
    linkToAutomaticWithdrawal,
    getCurrentHour,
    newDebtCollectionAmount,
  };
};

export default useWithdrawStatus;

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import {
  currentSellerSelector,
  customerAccountSelector,
} from 'shared/selectors';
import * as appRoutes from 'app/routes';
import { isCharlie, isDelta } from 'models/Seller';
import { CustomerAccount, MinimalSeller, Seller } from 'types';

const LOGIN_PATH = '/g/inloggen';

const frontpageRoute = (
  customerAccount: CustomerAccount | undefined,
  seller: MinimalSeller | Seller | null | undefined,
  currentLocation: string | null
): string => {
  if (!seller) {
    if (currentLocation) {
      return getLoginPath(currentLocation);
    } else {
      return appRoutes.SIGNUP;
    }
  } else {
    switch (seller.status) {
      case 'draft':
        return signupRoute(seller);

      case 'submitted':
        return submittedSellerRoute(customerAccount);

      case 'approved':
      case 'cancelled':
        return approvedSellerRoute(seller);

      case 'inactive':
        return appRoutes.INACTIVE;

      case 'lost':
      case 'rejected':
        return appRoutes.REACTIVATION;
    }

    return appRoutes.LOGIN;
  }
};

const signupRoute = (seller: MinimalSeller | Seller): string => {
  if (
    !seller.expectedFinancingAmount ||
    (seller.entityKind === 'Seller' && !seller.expectedRevenue)
  ) {
    return appRoutes.SELLER_SIGNUP_ACCOUNT_STEP;
  } else {
    return appRoutes.SELLER_SIGNUP_BANK_STATEMENTS_STEP;
  }
};

const submittedSellerRoute = (
  customerAccount: CustomerAccount | undefined
): string => {
  if (customerAccount && !customerAccount.hasCompletedAdditionalQuestions) {
    return appRoutes.SELLER_SIGNUP_ADDITIONAL_QUESTIONS_STEP;
  }

  if (customerAccount && !customerAccount.hasCompletedUboIdentification) {
    return appRoutes.SELLER_SIGNUP_UBO_IDENTIFICATION_STEP;
  }

  return appRoutes.SELLER_ONBOARDING;
};

const approvedSellerRoute = (seller: MinimalSeller | Seller): string => {
  if (isCharlie(seller) || isDelta(seller)) {
    return appRoutes.CUSTOMER_APP_ROOT;
  } else {
    return appRoutes.INVOICES;
  }
};

const getLoginPath = (currentPath: string) =>
  currentPath && ![LOGIN_PATH, '/'].includes(currentPath)
    ? `${LOGIN_PATH}?redirect=${currentPath}`
    : LOGIN_PATH;

/* Hook to get the default frontpage.
 * Returns a function that returns the frontpage.

 * Draft seller: signup page
 * Submitted seller: onboarding page
 * Approved or cancelled seller: dashboard page
 * Inactive seller: inactive page
 * Lost or rejected seller: reactivation page
 * No logged in seller:
   If `redirectToCurrentLocation` is true: login with redirect to the current location.
   If `redirectToCurrentLocation` is false: signup page.
*/
const useFrontpageRoute = () => {
  const seller = useSelector(currentSellerSelector);
  const customerAccount = useSelector(customerAccountSelector);
  const location = useLocation();

  const getRoute = (redirectToCurrentLocation = true) =>
    frontpageRoute(
      customerAccount,
      seller,
      redirectToCurrentLocation ? location.pathname : null
    );

  return getRoute;
};

export default useFrontpageRoute;

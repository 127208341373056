import React from 'react';
import * as I18n from 'shared/utils/I18n';
import Button from 'shared/components/tailwind/Button';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import TextInput from 'hookForm/TextInput';
import GuestModal from 'shared/components/tailwind/GuestModal';
import ServerError from 'shared/components/ServerError';
import useActivation from './useActivation';

const ns = 'app.activation';

const ActivationContainer = ({ children }) => (
  <GuestModal>
    <h3 className="tw-mb-4 tw-text-lg md:tw-text-bootstrap-h3">
      {I18n.nt(ns, 'header')}
    </h3>
    {children}
  </GuestModal>
);

const Activation = () => {
  const { control, email, navigateToFrontpage, status, submit } =
    useActivation();

  switch (status) {
    case 'activated':
      return (
        <ActivationContainer>
          <div className="tw-mt-8 tw-flex tw-flex-col tw-gap-8">
            <div>{I18n.nt(ns, 'activationSuccessful')}</div>
            <div>
              <Button className="button-primary" onClick={navigateToFrontpage}>
                {I18n.nt(ns, 'continue')}
              </Button>
            </div>
          </div>
        </ActivationContainer>
      );

    case 'loaded_token':
    case 'activation_failed':
    case 'activating':
      return (
        <ActivationContainer>
          <div className="tw-mb-4">{I18n.nt(ns, 'choosePassword')}</div>
          <form onSubmit={submit} className="tw-flex tw-flex-col tw-gap-4">
            {/* Hidden email field for password managers. */}
            <input
              autoComplete="username"
              className="tw-hidden"
              name="email"
              type="email"
              value={email}
              readOnly
            />
            <TextInput
              autoComplete="new-password"
              control={control}
              name="password"
              type="password"
              label={I18n.label('user', 'password')}
              includeLayoutClass={false}
              autoFocus
            />
            <TextInput
              autoComplete="new-password"
              control={control}
              name="passwordConfirmation"
              type="password"
              label={I18n.label('user', 'passwordConfirmation')}
              includeLayoutClass={false}
            />
            <div className="tw-mt-4">
              <Button
                className="button-primary"
                disabled={status === 'activating'}
                type="submit"
              >
                {I18n.nt(ns, 'activateButton')}
              </Button>
            </div>
            {status === 'activation_failed' && <ServerError />}
            <div className="tw-text-sm tw-text-gray-500">
              {I18n.nt(ns, 'acceptsConditionsHtml', {
                link_classes: 'tw-text-gray-500 tw-underline',
              })}
            </div>
          </form>
        </ActivationContainer>
      );

    case 'token_not_found':
      return (
        <ActivationContainer>
          {I18n.nt(ns, 'invalid_token')}
        </ActivationContainer>
      );

    default:
      return <LoadingAnimation />;
  }
};

export default Activation;

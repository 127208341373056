import React, { useState } from 'react';
import DeliveryItem from './DeliveryItem';
import { Transfer, DeliveryRequestItem } from './types';
import { translate } from 'shared/utils/I18n';
import classNames from 'classnames';
import TransferItem from './TransferItem';

const t = translate('deliveries_page.delivery_items');

interface Props {
  items: DeliveryRequestItem[];
  disabled?: boolean;
  onDelete: (file: DeliveryRequestItem) => void;
  newItems: Transfer[];
}

const DeliveryItems = (props: Props) => {
  const [expanded, setExpanded] = useState(false);
  const itemsShown = 3;
  const hasMoreItems = props.items.length > itemsShown;
  const listedItems = expanded ? props.items : props.items.slice(0, itemsShown);
  const nrOfHiddenItems = props.items.length - itemsShown;
  const isDisabled = !!props.disabled;
  return (
    <ul
      className={classNames('tw-m-0 tw-list-none tw-divide-y tw-border', {
        'tw-bg-white': !props.disabled,
        'tw-bg-gray-50': props.disabled,
      })}
    >
      {props.newItems.map((newItem) => (
        <TransferItem key={newItem.id} {...newItem} />
      ))}
      {listedItems.map((file) => (
        <DeliveryItem
          key={file.id}
          {...file}
          disabled={isDisabled}
          onDelete={() => props.onDelete(file)}
        />
      ))}
      {hasMoreItems && !expanded && (
        <li
          className="tw-space-y-2 tw-p-3 tw-text-sm"
          data-testid="delivery-items-more"
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setExpanded(true);
            }}
          >
            {t('show_more', { nrOfHiddenItems })}
          </a>
        </li>
      )}
    </ul>
  );
};

export default DeliveryItems;

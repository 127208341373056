import React from 'react';
import { concat, groupBy } from 'lodash';
import DeliveryPanel from './DeliveryPanel';
import { Uploader, DeliveryRequest, DeliveryRequestItem } from './types';
import { translate } from 'shared/utils/I18n';
import useSellers from 'shared/hooks/useSellers';

const t = translate('deliveries_page');

interface Props {
  deliveryRequests: DeliveryRequest[];
  onDelete: (file: DeliveryRequestItem) => void;
  onUpload: Uploader;
}

const DeliveriesPage = (props: Props) => {
  const sellers = useSellers();
  const multipleCompanies =
    new Set(sellers.map((seller) => seller.company)).size > 1;
  const groupedRequests = groupBy<DeliveryRequest>(
    props.deliveryRequests,
    (request: DeliveryRequest) => request.status
  );
  const orderedRequests = concat(
    groupedRequests.expired,
    groupedRequests.rejected,
    groupedRequests.open,
    groupedRequests.received,
    groupedRequests.deferred,
    groupedRequests.planned,
    groupedRequests.ignored,
    groupedRequests.accepted
  ).filter((x) => x !== undefined);

  return (
    <div>
      {props.deliveryRequests.length === 0 && (
        <div className="tw-max-w-prose">
          <h3>{t('title')}</h3>
          <p role="paragraph">{t('intro_empty')}</p>
        </div>
      )}
      {props.deliveryRequests.length > 0 && (
        <>
          <div className="tw-max-w-prose">
            <h3>{t('title')}</h3>
            <p role="paragraph">{t('intro')}</p>
          </div>
          <hr className="tw-my-6" />
          <div className="tw-space-y-6">
            {orderedRequests.map((request) => (
              <DeliveryPanel
                key={request.id}
                {...request}
                multipleCompanies={multipleCompanies}
                onDelete={props.onDelete}
                onUpload={props.onUpload}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default DeliveriesPage;

import { useState } from 'react';
import client from 'shared/utils/client';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import {
  selectUsername,
  setConnected,
  setConnectionStatus,
} from '../exactOnlineConnectionSlice';
import { useSelector } from 'react-redux';

type Status = '' | 'updating' | 'error';

const useConnection = () => {
  const [disconnectStatus, setDisconnectStatus] = useState<Status>('');
  const username = useSelector(selectUsername);
  const dispatch = useAppDispatch();

  const disconnect = async () => {
    setDisconnectStatus('updating');
    const response = await client(
      'POST',
      '/api/exact_online/disconnect',
      {},
      { acceptHtml: true, raiseError: false }
    );

    if (response.error) {
      setDisconnectStatus('error');
    } else {
      dispatch(setConnected(false));
      dispatch(setConnectionStatus('success'));
    }
  };

  return {
    username,
    disconnect,
    disconnectStatus,
  };
};

export default useConnection;

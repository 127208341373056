import React from 'react';
import { Control } from 'react-hook-form';
import { bankStatementStartDate } from 'shared/utils/bankStatementStartDate';
import * as I18n from 'shared/utils/I18n';
import BankstatementUploadInput from 'hookForm/BankstatementUploadInput';
import UploadStatusInfo from 'shared/components/UploadBankStatements/UploadStatusInfo';
import RequiredIbans from 'shared/components/UploadBankStatements/RequiredIbans';
import UploadStatementsWarning from 'shared/components/UploadBankStatements/UploadStatementsWarning';
import { Seller } from 'types';
import { BankStatementAttachment } from 'types/Attachment';
import { FormData } from './useBankTransactionsImportModal';

const ns = 'app.profile.bankStatementsStep.documentUpload';

interface Props {
  attachmentsWithWarning: BankStatementAttachment[];
  control: Control<FormData>;
  handleBankConnectionLink: () => void;
  bankStatementAttachments: BankStatementAttachment[];
  removeInvalidAttachments: () => void;
  seller: Seller;
  setUploading: (value: boolean) => void;
  submitting: boolean;
  uploading: boolean;
  warnings: boolean;
}

const Fieldset = ({
  attachmentsWithWarning,
  control,
  handleBankConnectionLink,
  bankStatementAttachments,
  removeInvalidAttachments,
  submitting,
  uploading,
  seller,
  setUploading,
  warnings,
}: Props) => {
  const saving = submitting || uploading;

  return (
    <div className="fieldset tw-mb-8 tw-border tw-p-6 tw-shadow-md">
      <div className="tw-flex tw-flex-row">
        <div className="tw-mb-6 tw-grow tw-text-right tw-text-sm">
          <a
            className="tw-font-bold tw-leading-none tw-text-gray-600"
            onClick={handleBankConnectionLink}
            href="#"
          >
            {I18n.nt(ns, 'bank_connection_link')}
          </a>
          <p className="tw-text-floryn-green">
            {I18n.nt(ns, 'bank_connection_endorsement_html')}
          </p>
        </div>
        <button
          type="button"
          className="close tw-ml-2 tw-flex tw-flex-col tw-items-start"
          onClick={handleBankConnectionLink}
        >
          <span aria-hidden="true" className="tw--mt-1 tw-leading-none">
            ×
          </span>
          <span className="sr-only">Close</span>
        </button>
      </div>
      <h4>{I18n.attribute('seller', 'bankStatementAttachments')}</h4>
      <p className="DocumentUploadInstructions">
        {I18n.nt(ns, 'download_bank_statements_start', {
          date: bankStatementStartDate(new Date()),
        })}
        &nbsp;
        {I18n.nt(ns, 'download_bank_statements')}
        &nbsp;
        <a
          href="https://www.floryn.com/nl/bankafschriften-downloaden"
          rel="noopener noreferrer"
          target="_blank"
          className="tw-underline"
        >
          {I18n.nt(ns, 'download_bank_statements_hint')}
        </a>
      </p>

      <RequiredIbans bankStatementAttachments={bankStatementAttachments} />

      <BankstatementUploadInput
        control={control}
        label={I18n.attribute('seller', 'bankStatementAttachments')}
        model="seller"
        modelId={seller.id}
        name="bankStatementAttachments"
        readOnly={saving}
        setUploading={setUploading}
      />
      <UploadStatusInfo
        bankStatementCount={bankStatementAttachments.length}
        saving={saving}
        warnings={warnings}
      />

      <UploadStatementsWarning
        attachmentsWithWarning={attachmentsWithWarning}
        disabled={saving}
        removeInvalidAttachments={removeInvalidAttachments}
      />
    </div>
  );
};

export default Fieldset;

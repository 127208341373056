import React from 'react';
import { Alert, Col, Row } from 'shared/bootstrap';
import { Link } from 'react-router-dom';
import { REQUEST_RESET_PASSWORD } from 'app/routes';
import * as I18n from 'shared/utils/I18n';

const ns = 'app.signup';

interface Props {
  email: string;
}

const DuplicateEmailError = ({ email }: Props) => (
  <Row>
    <Col md={12}>
      <Alert type="warning">
        <div
          dangerouslySetInnerHTML={{ __html: I18n.nt(ns, 'duplicateEmail') }}
        />
        <div>
          <Link
            className="button-primary button-small"
            to={{
              pathname: REQUEST_RESET_PASSWORD,
              state: { email },
            }}
          >
            {I18n.nt(ns, 'resetPassword')}
          </Link>
        </div>
      </Alert>
    </Col>
  </Row>
);

export default DuplicateEmailError;

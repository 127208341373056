import { asyncAction } from 'shared/utils/Helpers';
import { loadAction } from 'shared/actionCreators';
import qs from 'qs';

export const LOAD = asyncAction('charlie.reviews.LOAD');
export const LOAD_WITH_POTENTIAL_GAPS = asyncAction(
  'charlie.reviews.LOAD_WITH_GAPS'
);
export const SUBMIT = asyncAction('charlie.reviews.SUBMIT');

export const load = (sellerId) =>
  loadAction({
    type: LOAD,
    url: `/charlie/review/?seller_id=${sellerId}`,
  });

export const loadWithPotentialGaps = (sellerId, ids) =>
  loadAction({
    ids,
    type: LOAD_WITH_POTENTIAL_GAPS,
    url: `/charlie/review/show_with_potential_gaps?seller_id=${sellerId}&${qs.stringify(
      { bank_statement_attachments: ids },
      { arrayFormat: 'brackets' }
    )}`,
  });

const initialState = {
  status: '',
  payload: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD.REQUEST:
      return {
        ...state,
        status: 'loading',
      };

    case LOAD_WITH_POTENTIAL_GAPS.SUCCESS:
      return {
        ...state,
        payload: action.payload.review,
      };

    case LOAD.SUCCESS:
    case SUBMIT.SUCCESS:
      return {
        ...state,
        status: 'loaded',
        payload: action.payload.review,
      };

    default:
      return state;
  }
};

import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import * as I18n from 'shared/utils/I18n';
import GuestModal from 'shared/components/tailwind/GuestModal';
import { LOGIN } from 'app/routes';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import TextInput from 'hookForm/TextInput';
import Button from 'shared/components/tailwind/Button';
import ServerError from 'shared/components/ServerError';
import useResetPassword from './useResetPassword';

const ns = 'app.resetPassword';

interface ResetPasswordContainerProps {
  children?: ReactNode;
}

const ResetPasswordContainer = ({ children }: ResetPasswordContainerProps) => (
  <GuestModal>
    <h3 className="tw-mb-4 tw-text-lg md:tw-text-bootstrap-h3">
      {I18n.nt(ns, 'header')}
    </h3>
    {children}
  </GuestModal>
);

const ResetPassword = () => {
  const { control, email, status, submit } = useResetPassword();

  switch (status) {
    case 'reset_done':
      return (
        <ResetPasswordContainer>
          <div className="tw-mt-8 tw-flex tw-flex-col tw-gap-8">
            <div>{I18n.nt(ns, 'resetSuccessful')}</div>
            <Link to={LOGIN}>{I18n.ut('common.goForward')}</Link>
          </div>
        </ResetPasswordContainer>
      );

    case 'token_not_found':
      return (
        <ResetPasswordContainer>
          <div className="tw-mt-8 tw-flex tw-flex-col tw-gap-8">
            <div>{I18n.nt(ns, 'tokenInvalid')}</div>
            <Link to={LOGIN}>{I18n.ut('common.goBack')}</Link>
          </div>
        </ResetPasswordContainer>
      );

    case 'ready_for_reset':
    case 'resetting':
    case 'reset_failed':
      return (
        <ResetPasswordContainer>
          <form onSubmit={submit} className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-mb-4">
              {I18n.nt(ns, 'enterNewPasswordForHtml', {
                email,
              })}
            </div>
            {/* Hidden email field for password managers. */}
            <input
              autoComplete="username"
              className="tw-hidden"
              name="email"
              type="email"
              value={email}
              readOnly
            />
            <TextInput
              autoComplete="new-password"
              control={control}
              type="password"
              name="password"
              label={I18n.label('user', 'password')}
              includeLayoutClass={false}
              autoFocus
            />
            <TextInput
              autoComplete="new-password"
              control={control}
              type="password"
              name="passwordConfirmation"
              label={I18n.label('user', 'passwordConfirmation')}
              includeLayoutClass={false}
            />
            <div className="tw-mt-4">
              <Button
                className="button-primary"
                disabled={status === 'resetting'}
                type="submit"
              >
                {I18n.ut('common.save')}
              </Button>
            </div>
            {status === 'reset_failed' && (
              <ServerError className="tw-mb-0 tw-mt-2" />
            )}
          </form>
        </ResetPasswordContainer>
      );

    default:
      return <LoadingAnimation />;
  }
};

export default ResetPassword;

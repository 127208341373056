import React from 'react';
import { Button } from 'shared/bootstrap';

import { nt } from 'shared/utils/I18n';
import LoadingError from 'shared/components/LoadingError';
import useNotConnected from './useNotConnected';

const ns = 'app.profilePage.exact_online_connection.not_connected';

const NotConnected = () => {
  const { redirectToAuthorisationPage, status } = useNotConnected();

  return (
    <>
      <div className="intro">
        <div>{nt(ns, 'intro')}</div>
        <ul>
          <li>{nt(ns, 'bullet1')}</li>
          <li>{nt(ns, 'bullet2')}</li>
        </ul>
      </div>
      <div className="not-connected">
        {status === 'error' && <LoadingError />}
        <Button
          className="button-light tw-flex tw-items-center tw-gap-3"
          onClick={redirectToAuthorisationPage}
          disabled={status === 'loading'}
        >
          <img className="tw-h-5" src="/images/exact-small.svg" />
          {nt(ns, 'create_connection')}
        </Button>
      </div>
    </>
  );
};

export default NotConnected;

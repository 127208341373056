import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Activation from 'app/components/guest/Activation';
import * as routes from 'app/routes';
import NavigationBar from 'app/components/NavigationBar';
import EmailChangeConfirmation from 'app/components/guest/EmailChangeConfirmation';
import Login from 'app/components/guest/Login';
import NotFound from 'app/components/NotFound';
import RequestResetPassword from 'app/components/guest/RequestResetPassword';
import ResetPassword from 'app/components/guest/ResetPassword';
import Signup from 'signup/containers/GuestSignup';
import RequireAuthGuestOnly from 'shared/utils/routing/RequireAuthGuestOnly';

const Guest = () => (
  <div className="GuestRoot">
    <NavigationBar />
    <Switch>
      <Route exact path={routes.LOGIN}>
        <RequireAuthGuestOnly supportRedirectParam>
          <Login />
        </RequireAuthGuestOnly>
      </Route>
      <Route path={routes.SIGNUP_REFERRER}>
        <RequireAuthGuestOnly>
          <Signup />
        </RequireAuthGuestOnly>
      </Route>
      <Route path={routes.SIGNUP}>
        <RequireAuthGuestOnly>
          <Signup />
        </RequireAuthGuestOnly>
      </Route>
      <Route exact path={routes.REQUEST_RESET_PASSWORD}>
        <RequestResetPassword />
      </Route>
      <Route exact path={routes.RESET_PASSWORD}>
        <ResetPassword />
      </Route>
      <Route exact path={routes.ACTIVATION}>
        <Activation />
      </Route>
      <Route exact path={routes.CHANGE_EMAIL}>
        <EmailChangeConfirmation />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  </div>
);

export default Guest;

import React from 'react';
import * as I18n from 'shared/utils/I18n';
import Button from 'shared/components/tailwind/Button';
import DontWorryMessage from 'shared/components/DontWorryMessage';
import GeneralErrors from 'hookForm/GeneralErrors';
import useUboIdentificationStep from './useUboIdentificationStep';
import { UltimateBeneficialOwner } from 'types/UltimateBeneficialOwner';
import UboList from 'Onboarding/components/UltimateBeneficialOwners/UboList';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from 'shared/components/Tooltip';
import { Modal } from 'shared/bootstrap';
import PersonaliaStep from 'Onboarding/components/UltimateBeneficialOwners/UboForm/PersonaliaStep';

const ns = 'app.profile.uboIdentificationStep';
const ns_more_info = 'signup.onboarding.ultimate_beneficial_owners.modal';

const UboIdentificationStep = () => {
  const {
    addUboToState,
    currentAction,
    goToPreviousStep,
    isSubmitting,
    loadStatus,
    removeUboFromState,
    resetActionAndUbo,
    serverErrorMessages,
    setActionAndUbo,
    submit,
    ubo,
    ubos,
    updateUboInState,
  } = useUboIdentificationStep();

  const showEditModal = currentAction !== '';

  return (
    <div className="lg:tw-pr-16">
      <h1 className="tw-mb-4 tw-text-xl md:tw-mt-0 md:tw-text-bootstrap-h3">
        {I18n.nt(ns, 'title')}
      </h1>
      <div className="tw-mb-8">
        {I18n.nt(ns, 'intro')}
        <span> </span>
        <Tooltip tooltipInteraction="click" tooltipSize="xl">
          <TooltipTrigger>
            <a className="tw-text-black tw-underline hover:tw-text-black">
              {I18n.nt(ns, 'more_information')}
            </a>
          </TooltipTrigger>
          <TooltipContent>
            {I18n.nt(ns_more_info, 'intro_explanation_html')}
          </TooltipContent>
        </Tooltip>
      </div>

      <div className="fieldset tw-mb-16">
        <GeneralErrors generalErrors={serverErrorMessages} />

        <UboList
          ubos={ubos}
          loadStatus={loadStatus}
          hideInvite={true}
          onEdit={(ubo: UltimateBeneficialOwner) =>
            setActionAndUbo('edit', ubo)
          }
          onInvite={() => {}}
        />
        <Button
          className="button-primary"
          onClick={() => setActionAndUbo('add', null)}
          disabled={loadStatus !== 'loaded'}
        >
          <span className="icon icon-plus tw-mr-3 tw-inline tw-align-middle tw-text-sm" />
          {I18n.nt(ns, 'add_ubo')}
        </Button>
      </div>
      <div className="tw-flex tw-gap-2">
        <Button className="button-light" onClick={goToPreviousStep}>
          {I18n.ut('common.goBack')}
        </Button>
        <Button
          type="submit"
          className="button-primary"
          disabled={isSubmitting}
          onClick={submit}
        >
          {I18n.nt(ns, 'submit')}
        </Button>
      </div>
      <DontWorryMessage />

      {showEditModal && (
        <Modal className="UboModal" show onHide={resetActionAndUbo}>
          <PersonaliaStep
            addUboToState={(ubo) => addUboToState(ubo)}
            ubo={ubo}
            updateUboInState={(ubo) => {
              updateUboInState(ubo);
            }}
            removeUboFromState={(ubo) => {
              removeUboFromState(ubo);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default UboIdentificationStep;

import React, { useState } from 'react';
import classNames from 'classnames';

import * as I18n from 'shared/utils/I18n';
import Button from 'shared/components/tailwind/Button';
import OnfidoModal from 'shared/components/OnfidoModal';
import { IdentifyingPerson, OnboardingActionStatus } from 'types';
import useVerifyIdentity from './useVerifyIdentity';

const t = (key, options?) =>
  I18n.nt('signup.onboarding.verify_identity.action', key, options);

interface Props {
  identifyingPerson: IdentifyingPerson;
}

export default ({ identifyingPerson }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState<OnboardingActionStatus>(
    identifyingPerson.hasSubmittedDocuments ? 'Done' : 'Todo'
  );
  const { completeSubmission, modalStatus } = useVerifyIdentity();
  const onComplete = () => {
    setStatus('Done');
    completeSubmission();
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-3 xs:tw-flex-row xs:tw-items-center xs:tw-gap-0">
      <div className="tw-flex tw-grow tw-items-center tw-gap-8">
        <span
          className={classNames(
            'tw-font-icomoon',
            'tw-text-2xl',
            status === 'Todo' ? 'tw-text-gray-400' : 'tw-text-floryn-green',
            status === 'Todo'
              ? 'icon-checkbox-unchecked'
              : 'icon-checkbox-checked'
          )}
        ></span>
        <div>
          <div className="tw-font-bold">{t('title')}</div>
          <div className="">{t(status.toLowerCase() + '_description')}</div>
        </div>
      </div>
      {status === 'Todo' && (
        <Button
          className="button-primary-inverted button-small tw-min-w-36"
          onClick={() => setShowModal(true)}
        >
          {t('button')}
        </Button>
      )}
      {showModal && (
        <OnfidoModal
          closeModal={() => setShowModal(false)}
          identifyingPerson={identifyingPerson}
          status={modalStatus}
          onComplete={onComplete}
        />
      )}
    </div>
  );
};

import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import useWithdrawDialog from './useWithdrawDialog';
import WithdrawDialogForm from './WithdrawDialogForm';
import WithdrawStatus from './WithdrawStatus';

import { Button, Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';

const ns = 'charlie.frontpage.withdraw_dialog';

interface Props {
  parentPath: string;
}

const WithdrawDialog = ({ parentPath }: Props) => {
  const history = useHistory();
  const close = () => history.push(parentPath);
  const {
    availableBalance,
    companyName,
    complexAccount,
    control,
    generalErrors,
    handleSubmit,
    isSubmitting,
    succeeded,
    summaryProperties,
    withdrawAll,
  } = useWithdrawDialog(() => {});

  const amountInputRef = useRef<HTMLInputElement>(null);

  const withdrawContent = (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
        {complexAccount && <h5 className="subtitle">{companyName}</h5>}
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="description">{I18n.nt(ns, 'description_html')}</div>
          <WithdrawDialogForm
            amountInputRef={amountInputRef}
            availableBalance={availableBalance}
            control={control}
            generalErrors={generalErrors}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            summaryProperties={summaryProperties}
            withdrawAll={withdrawAll}
          />
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="button-primary"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {I18n.nt(ns, 'title')}
        </Button>
      </Modal.Footer>
    </>
  );

  const statusContent = (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'status.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WithdrawStatus
          summaryProperties={summaryProperties}
          parentPath={parentPath}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="button-primary" onClick={close}>
          {I18n.nt(ns, 'status.done')}
        </Button>
      </Modal.Footer>
    </>
  );

  return (
    <Modal
      className="WithdrawDialog"
      initialFocus={amountInputRef}
      onHide={close}
      show
    >
      {succeeded ? statusContent : withdrawContent}
    </Modal>
  );
};

export default WithdrawDialog;

import React, { useState } from 'react';

import { Col, Row } from 'shared/bootstrap';
import * as Helpers from 'shared/utils/Helpers';
import { nt } from 'shared/utils/I18n';
import Button from 'shared/components/tailwind/Button';
import InvoiceFilters from 'shared/components/InvoiceFilters';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import RefreshModal from 'shared/components/Consent/RefreshModal';

import InvoiceRow from './InvoiceRow';
import InvoicesDashboard from './InvoicesDashboard';
import RequestFinancingModal from './RequestFinancingModal';
import NoInvoicesAlert from './NoInvoicesAlert';
import useInvoices from './useInvoices';
import { isAisConnected } from 'models/Seller';
import { Invoice } from 'types';
import ReviewWarning from 'charlie/components/DashboardAlerts/ReviewWarning';

const ns = 'app.invoices';

const Invoices = () => {
  const {
    canUploadInvoice,
    dashboard,
    gotoInvoice,
    initialInvoicesLoaded,
    invoiceCounts,
    invoices,
    invoicesLoading,
    invoicesTotalAmount,
    onRequestFinancing,
    selectedStatusFilter,
    selectedFinancingFilter,
    selectFinancingFilter,
    selectStatusFilter,
    seller,
    showRefreshPopup,
    uploadInvoice,
  } = useInvoices();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | undefined>();
  const onCancelRequestFinancing = () => setSelectedInvoice(undefined);
  const onApproveRequestFinancing = () => {
    onRequestFinancing(selectedInvoice?.id);
    setSelectedInvoice(undefined);
  };

  return (
    <div className="Invoices">
      <ReviewWarning />
      {isAisConnected(seller) && (
        <RefreshModal showPopup={showRefreshPopup} seller={seller} />
      )}
      <Row>
        <div className="header">
          <Col md={5} mdOffset={3}>
            <h1>{nt(ns, 'invoices')}</h1>
          </Col>
          <Col md={4}>
            {canUploadInvoice && (
              <Button
                className="button-primary newInvoice"
                onClick={uploadInvoice}
              >
                {nt(ns, 'upload_invoice')}
              </Button>
            )}
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          {initialInvoicesLoaded && (
            <InvoiceFilters
              invoiceCounts={invoiceCounts}
              selectedStatusFilter={selectedStatusFilter}
              selectedFinancingFilter={selectedFinancingFilter}
              selectFinancingFilter={selectFinancingFilter}
              selectStatusFilter={selectStatusFilter}
            />
          )}
        </Col>
        <Col md={9}>
          <div className="listing">
            <InvoicesDashboard {...dashboard} />
            <table>
              <thead>
                <tr>
                  <th className="status">{nt(ns, 'listing.status')}</th>
                  <th className="reference">{nt(ns, 'listing.invoice')}</th>
                  <th className="date_sent">
                    {nt(ns, 'listing.invoice_date')}
                  </th>
                  <th className="debtor">{nt(ns, 'listing.debtor')}</th>
                  <th className="amount">{nt(ns, 'listing.amount')}</th>
                  <th className="financing-state" />
                </tr>
              </thead>

              <tbody style={{ opacity: invoicesLoading ? 0.5 : 1 }}>
                {invoices.map((invoice) => (
                  <InvoiceRow
                    gotoInvoice={gotoInvoice}
                    key={invoice.id}
                    invoice={invoice}
                    onRequestFinancing={(invoice) =>
                      setSelectedInvoice(invoice)
                    }
                  />
                ))}
              </tbody>

              {!invoicesLoading && invoices.length !== 0 && (
                <tfoot>
                  <tr>
                    <td colSpan={4} />
                    <td className="amount">
                      {Helpers.toEuro(invoicesTotalAmount)}
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>

            {initialInvoicesLoaded &&
              !invoicesLoading &&
              invoices.length === 0 && (
                <NoInvoicesAlert selectedStatusFilter={selectedStatusFilter} />
              )}

            {invoicesLoading && <LoadingAnimation />}

            <RequestFinancingModal
              invoiceId={selectedInvoice?.id}
              onApprove={onApproveRequestFinancing}
              onCancel={onCancelRequestFinancing}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Invoices;

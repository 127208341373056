import React from 'react';
import Page from './Page';
import { t } from './utils';
import ContainerWithoutPadding from 'shared/components/tailwind/ContainerWithoutPadding';

const ErrorPage = ({ children }) => (
  <Page>
    <ContainerWithoutPadding>
      <div className="tw-mb-6 tw-bg-red-200 tw-px-4 tw-py-4 tw-text-red-800 sm:tw-px-8 md:tw--mx-8 md:tw-mb-10 md:tw-mt-7 md:tw-py-10">
        <div className="tw-mb-2 tw-font-heading tw-text-xl tw-font-bold md:tw-text-bootstrap-h3">
          {t('error.title')}
        </div>
        <div>{children}</div>
      </div>
    </ContainerWithoutPadding>
  </Page>
);

export default ErrorPage;

import React from 'react';
import { Button, Col } from 'shared/bootstrap';
import ChangeAddressModal from './ChangeAddressModal';
import useServicePage from '../ServicePage/useServicePage';
import * as I18n from 'shared/utils/I18n';

const ns = 'app.profilePage.changeAddress';

const ChangeAddressRequest = () => {
  const { action, setAction, resetAction } = useServicePage();

  return (
    <Col lg={6} className="change-email">
      <h4>{I18n.nt(ns, 'title')}</h4>
      <p> {I18n.nt(ns, 'intro')} </p>
      <Button
        className="btn-primary"
        disabled={action !== ''}
        onClick={() => setAction('changeAddress')}
      >
        {I18n.nt(ns, 'button')}
      </Button>
      <ChangeAddressModal
        onHide={resetAction}
        show={action === 'changeAddress'}
      />
    </Col>
  );
};

export default ChangeAddressRequest;

import { useEffect, useState } from 'react';
import useAppDispatch from 'namespaces/shared/hooks/useAppDispatch';
import client from 'shared/utils/client';
import { loadSeller } from 'shared/modules/loadSeller';
import { UltimateBeneficialOwner } from 'types';

export type Action = '' | 'add' | 'edit' | 'invite';

const useUltimateBeneficialOwners = () => {
  const [loadStatus, setLoadStatus] = useState<string>('');
  const [ubos, setUbos] = useState<UltimateBeneficialOwner[]>([]);
  const [currentAction, setCurrentAction] = useState<Action>('');
  const [ubo, setUbo] = useState<UltimateBeneficialOwner | null>(null);

  const dispatch = useAppDispatch();
  const resetUbosConfirmation = () => {
    dispatch(loadSeller());
  };

  const resetActionAndUbo = () => {
    setCurrentAction('');
    setUbo(null);
  };

  const setActionAndUbo = (
    action: Action,
    ubo: UltimateBeneficialOwner | null
  ) => {
    setUbo(ubo);
    setCurrentAction(action);
  };

  const loadUbos = async () => {
    try {
      const { payload: ubos } = await client(
        'GET',
        '/api/ultimate_beneficial_owners'
      );
      setUbos(ubos);
      setLoadStatus('loaded');
    } catch {
      setLoadStatus('error');
    }
  };

  const addUboToState = (ubo: UltimateBeneficialOwner) => {
    setUbos([...ubos, ubo]);

    resetUbosConfirmation();
    if (ubo.onfidoIdentificationStatus === 'not_started') {
      setActionAndUbo('invite', ubo);
    } else {
      resetActionAndUbo();
    }
  };

  const updateUboInState = (updatedUbo: UltimateBeneficialOwner) => {
    setUbos(
      ubos.map((ubo) => {
        if (ubo.id === updatedUbo.id) {
          return updatedUbo;
        } else {
          return ubo;
        }
      })
    );

    resetUbosConfirmation();
    if (updatedUbo.onfidoIdentificationStatus === 'not_started') {
      setActionAndUbo('invite', updatedUbo);
    } else {
      resetActionAndUbo();
    }
  };

  const removeUboFromState = (ubo: UltimateBeneficialOwner) => {
    setUbos(ubos.filter((u) => u.id !== ubo.id));

    resetUbosConfirmation();
    resetActionAndUbo();
  };

  useEffect(() => {
    loadUbos();
  }, []);

  return {
    addUboToState,
    currentAction,
    loadStatus,
    removeUboFromState,
    resetActionAndUbo,
    setActionAndUbo,
    ubo,
    ubos,
    updateUboInState,
  };
};

export default useUltimateBeneficialOwners;

import React from 'react';

import { Button, Col, Row } from 'shared/bootstrap';
import RefreshSvg from 'svg/refresh-arrows.svg';
import AccountLine from './AccountLine';
import { Bank } from 'types';
import { allowBankRefresh, t } from './helpers';

interface Props {
  bank: Bank;
  onRefresh: () => void;
  isDisabled: boolean;
}

const AccountsList = ({ bank, onRefresh, isDisabled }: Props) => {
  const allowRefresh = allowBankRefresh(bank);
  const { name, accounts } = bank;
  return (
    <div className="tw-mb-5">
      <div className="tw-border tw-border-gray-300 tw-p-5">
        <Row>
          <Col md={7} className="space-y-24">
            {accounts.map((account) => (
              <AccountLine key={account.iban} account={account} />
            ))}
          </Col>
          <Col md={5} className="space-y-24 text-right">
            <div className="tw-flex tw-justify-end">
              <img
                className={`${name} tw-max-w-[120px]`}
                src={`/images/banks/${name}.svg`}
                title={name}
              />
            </div>
            {allowRefresh && (
              <Button
                className="button-primary"
                disabled={isDisabled}
                onClick={onRefresh}
              >
                <div className="tw-flex tw-items-center tw-gap-3">
                  <RefreshSvg className="tw-h-4" />
                  {t('refresh')}
                </div>
              </Button>
            )}
            {!allowRefresh && (
              <p className="small text-success">{t('upToDate')}</p>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AccountsList;

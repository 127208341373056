export const APP_ROOT = '';
export const ERROR = '/error';
export const CUSTOMER_APP_ROOT = '/klant';
export const DASHBOARD = '/klant/dashboard';
export const MUTATIONS = '/klant/transacties';
export const INVOICE = '/klant/factuur/:id';
export const INVOICE_DELETE = `${INVOICE}/verwijder`;
export const INVOICE_EDIT = `${INVOICE}/aanpassen`;
export const INVOICE_TAB = `${INVOICE}/:tabKey`;
export const INVOICES = '/klant/facturen';
export const UPLOAD = `${INVOICES}/upload`;
export const INVOICES_FILTER = `${INVOICES}/:filter`;
export const PROFILE_PAGE = '/klant/account';
export const PROFILE_PAGE_TAB = `${PROFILE_PAGE}/:tabKey`;
export const PROFILE_PAGE_USER_INFORMATION = `${PROFILE_PAGE}/gebruikers`;
export const PROFILE_PAGE_CONSENTS = `${PROFILE_PAGE}/rekeningen`;
export const PROFILE_PAGE_CONSENTS_CONNECT = `${PROFILE_PAGE_CONSENTS}/koppel`;
export const PROFILE_PAGE_EXACT_ONLINE_CONNECTION = `${PROFILE_PAGE}/exact-online-koppeling`;
export const PROFILE_PAGE_SERVICE = `${PROFILE_PAGE}/service`;
export const PROFILE_PAGE_DELIVERIES = `${PROFILE_PAGE}/deliveries`;
export const PROFILE_PAGE_INVOICES = `${PROFILE_PAGE}/facturen`;
export const REPAYMENTS = `/klant/incassos`;
export const MANDATE = '/klant/machtiging';
export const MANDATE_RETURN = '/klant/machtiging-ondertekend';
export const INACTIVE = '/klant/inactief';
export const REACTIVATION = '/klant/heractivatie';
export const REVIEW = `${CUSTOMER_APP_ROOT}/bankafschriften`;

export const SELLER_SIGNUP_BASE = '/klant/profiel';
export const SELLER_SIGNUP_STEP = `${SELLER_SIGNUP_BASE}/:currentStep`;
export const SELLER_SIGNUP_ACCOUNT_STEP = `${SELLER_SIGNUP_BASE}/bedrijfsgegevens`;
export const SELLER_SIGNUP_ADDITIONAL_QUESTIONS_STEP = `${SELLER_SIGNUP_BASE}/aanvullende-gegevens`;
export const SELLER_SIGNUP_UBO_IDENTIFICATION_STEP = `${SELLER_SIGNUP_BASE}/ubo-identificatie`;
export const SELLER_SIGNUP_BANK_STATEMENTS_STEP = `${SELLER_SIGNUP_BASE}/bankgegevens`;
export const SELLER_ONBOARDING = `${SELLER_SIGNUP_BASE}/onboarding`;
export const SELLER_ONBOARDING_UBOS = `${SELLER_ONBOARDING}/uiteindelijk-belanghebbenden`;
export const SELLER_ONBOARDING_VERIFY_IDENTITY = `${SELLER_ONBOARDING}/verifieer-identiteit`;
export const FINANCIAL_DASHBOARD = `${SELLER_SIGNUP_BASE}/dashboard`;

export const GUEST_ROOT = '/g';
export const SIGNUP = '/g/aanmelden';
export const SIGNUP_REFERRER = '/g/aanmelden/:referrer';
export const LOGIN = '/g/inloggen';
export const REQUEST_RESET_PASSWORD = '/g/wachtwoord-herstellen';
export const RESET_PASSWORD = '/g/wachtwoord-herstellen/:resetToken';
export const ACTIVATION = '/g/activeren/:activationToken';
export const CHANGE_EMAIL = '/g/bevestiging-email-wijziging/:newEmailToken';

export const SECOND_SIGNER = '/teken-borg/:token';
export const ONBOARDING_USER = '/aanleveren/:token';

export const PARTNER_SIGNUP_BANK_STATEMENTS = '/afschriften-aanleveren';
export const PARTNER_SIGNUP_BANK_STATEMENTS_OVERVIEW =
  '/afschriften-aanleveren/overzicht';

export const LOGOUT = '/uitloggen';

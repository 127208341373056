import React from 'react';
import * as I18n from 'shared/utils/I18n';
import CheckboxInput from 'hookForm/CheckboxInput';
import Button from 'shared/components/tailwind/Button';
import { Invoice, MinimalSeller, Seller } from 'types';

const ns = 'app.invoiceSummary.submission';

interface Props {
  control: any;
  invoice: Invoice;
  performingAction: boolean;
  seller: MinimalSeller | Seller;
  showForm: () => void;
  submitInvoice: (invoiceId: number, params: any) => void;
  submitInvoiceWithoutValidation: (invoiceId: number, params: any) => void;
}

const InvoiceSubmission = ({
  control,
  invoice,
  performingAction,
  seller,
  showForm,
  submitInvoice,
  submitInvoiceWithoutValidation,
}: Props) => {
  if (invoice.state !== 'draft') {
    return null;
  }

  const submitWithFinancing = () => {
    if (invoice.state === 'draft') {
      submitInvoice(invoice.id, { requestedFinancing: true });
    }
  };

  const allowedToSendInvoices =
    seller.sendInvoices && invoice.invoiceContacts.length > 0;

  const submitWithoutFinancing = () => {
    if (
      confirm(
        I18n.nt(
          ns,
          allowedToSendInvoices
            ? 'confirm.sendWithoutFinancing'
            : 'confirm.saveWithoutFinancing'
        )
      )
    ) {
      submitInvoiceWithoutValidation(invoice.id, {
        requestedFinancing: false,
        invoiceAction: 'softcheck',
      });
    }
  };

  const canSubmitWithFinancing =
    invoice.state === 'draft' || Boolean(invoice.requestedFinancing);
  const canSubmitWithoutFinancing =
    seller.maySubmitUnfinancedInvoices &&
    (invoice.state === 'draft' || !invoice.requestedFinancing);
  const confirmationLabel = I18n.nt(
    ns,
    allowedToSendInvoices ? 'agreement.with_email' : 'agreement.without_email'
  );

  return (
    <div className="submission">
      {canSubmitWithFinancing && (
        <div className="tw-mb-4">
          <CheckboxInput
            control={control}
            label={confirmationLabel}
            name="confirmation"
          />
        </div>
      )}
      <Button className="back button-light" onClick={showForm}>
        {I18n.ut('common.goBack')}
      </Button>

      {canSubmitWithFinancing && (
        <Button
          disabled={performingAction}
          className="button-primary tw-ml-2"
          onClick={submitWithFinancing}
          type="submit"
        >
          {I18n.nt(ns, 'button.confirmFinancing')}
        </Button>
      )}
      {canSubmitWithoutFinancing && (
        <Button
          className="button-secondary tw-ml-2"
          disabled={performingAction}
          onClick={submitWithoutFinancing}
          type="submit"
        >
          {I18n.nt(
            ns,
            allowedToSendInvoices
              ? 'button.sendWithoutFinancing'
              : 'button.saveWithoutFinancing'
          )}
        </Button>
      )}
    </div>
  );
};

export default InvoiceSubmission;

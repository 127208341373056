import React from 'react';
import { Route } from 'react-router-dom';
import NavigationBar from 'app/components/NavigationBar/NavigationBarDraftAndSubmitted';
import * as I18n from 'shared/utils/I18n';
import { SECOND_SIGNER } from 'app/routes';
import Sign from './Sign';
import Signed from './Signed';

const ns = 'app.second_signer';

const SecondSigner = () => {
  return (
    <div className="SecondSigner">
      <NavigationBar />
      <div className="SecondSignerContent">
        <h3>{I18n.nt(ns, 'title')}</h3>
        <Route exact path={SECOND_SIGNER}>
          <Sign />
        </Route>
        <Route exact path={`${SECOND_SIGNER}/getekend`}>
          <Signed />
        </Route>
      </div>
    </div>
  );
};

export default SecondSigner;

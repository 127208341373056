import React, { useState } from 'react';
import { TranslateOptions } from 'i18n-js';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import * as I18n from 'shared/utils/I18n';
import usePoll from './usePoll';
import { FlorynPayPaymentResponse } from './types';

const POLL_TIMEOUT = 500; // Timeout in ms
const MAX_POLL_COUNT = 30_000 / POLL_TIMEOUT; // Timeout after ~30 seconds

const ns = 'charlie.frontpage.floryn_pay_add_payment.processing_step';
const t = (key: string, options?: TranslateOptions) =>
  I18n.nt(ns, key, options);

interface Props {
  florynPayPaymentId: number;
}

const ProcessingStep = ({ florynPayPaymentId }: Props) => {
  const [pollCount, setPollCount] = useState(0);
  const [pollingFailed, setPollingFailed] = useState(false);

  const callback = (response: FlorynPayPaymentResponse) => {
    setPollCount(pollCount + 1);
    const florynPayPayment = response.florynPayPayment;

    if (
      florynPayPayment.status === 'consent_pending' &&
      florynPayPayment.swanConsentUrl
    ) {
      location.href = florynPayPayment.swanConsentUrl;
      return false;
    } else if (pollCount > MAX_POLL_COUNT) {
      setPollingFailed(true);
      return false;
    }

    return true;
  };

  usePoll<FlorynPayPaymentResponse>({
    path: `/api/floryn_pay_payments/${florynPayPaymentId}`,
    timeMs: POLL_TIMEOUT,
    callback,
  });

  if (pollingFailed) {
    return (
      <>
        <div className="tw-flex tw-flex-col tw-items-center tw-gap-6">
          <div>{t('failed')}</div>
        </div>
      </>
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-gap-6">
      <LoadingAnimation />
      <div className="tw-italic">{t('preparing_payment')}</div>
      <div>{t('next_step')}</div>
    </div>
  );
};

export default ProcessingStep;

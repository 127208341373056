import React from 'react';
import { Route } from 'react-router-dom';

import {
  PARTNER_SIGNUP_BANK_STATEMENTS,
  PARTNER_SIGNUP_BANK_STATEMENTS_OVERVIEW,
} from 'app/routes';
import NavigationBar from './components/NavigationBar';
import LoginStatus from './components/LoginStatus';
import Container from './components/Container';
import usePartnerSignup from './usePartnerSignup';
import BankStatementsStep from './BankStatementsStep';
import OverviewStep from './OverviewStep';

const PartnerSignup = () => {
  const { loginStatus, partner, review } = usePartnerSignup();

  if (loginStatus !== 'logged_in' || !review || !partner) {
    return (
      <Container>
        <LoginStatus loginStatus={loginStatus} />
      </Container>
    );
  }

  return (
    <>
      <NavigationBar companyName={partner.companyName} />
      <Container>
        <Route exact path={PARTNER_SIGNUP_BANK_STATEMENTS}>
          <BankStatementsStep partner={partner} review={review} />
        </Route>
        <Route exact path={PARTNER_SIGNUP_BANK_STATEMENTS_OVERVIEW}>
          <OverviewStep partner={partner} review={review} />
        </Route>
      </Container>
    </>
  );
};

export default PartnerSignup;

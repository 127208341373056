import React, { useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { generateHtmlElementId } from 'shared/utils/Helpers';
import InputWrapper from './InputWrapper';
import ErrorMessage from './ErrorMessage';
import HintPopover, { TooltipSize } from './HintPopover';

interface Props<TFieldValues extends FieldValues> {
  autoFocus?: boolean;
  control: Control<TFieldValues>;
  hint?: string;
  includeEmpty?: boolean;
  includeLayoutClass?: boolean;
  label: string;
  name: Path<TFieldValues>;
  options: [string, string][];
  required?: boolean;
  tooltipSize?: TooltipSize;
}

const Select = <TFieldValues extends FieldValues>({
  autoFocus,
  control,
  hint,
  includeEmpty,
  includeLayoutClass,
  label,
  name,
  options,
  required,
  tooltipSize,
}: Props<TFieldValues>) => {
  const [id] = useState(generateHtmlElementId);
  const [errorMessageId] = useState(generateHtmlElementId);

  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error, invalid },
  } = useController<TFieldValues>({ control, name });

  const [hasFocus, setHasFocus] = useState(false);

  const handleBlur = () => {
    setHasFocus(false);
    onBlur();
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  return (
    <InputWrapper
      id={id}
      includeLayoutClass={includeLayoutClass}
      invalid={Boolean(error) && !hasFocus}
      label={label}
      name={name}
      required={required}
    >
      {hint && <HintPopover hint={hint} tooltipSize={tooltipSize} />}
      <select
        aria-errormessage={invalid ? errorMessageId : undefined}
        aria-invalid={invalid ? true : undefined}
        className="form-control"
        id={id}
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
        ref={ref}
        value={value}
        autoFocus={autoFocus}
      >
        {includeEmpty && <option key="" value=""></option>}
        {options.map(([key, title]) => (
          <option key={key} value={key}>
            {title}
          </option>
        ))}
      </select>
      <ErrorMessage error={error} id={errorMessageId} />
    </InputWrapper>
  );
};

export default Select;

import React from 'react';
import { Route } from 'react-router-dom';
import { Contract, OnboardingActionStatus } from 'types';

import Action, { ActionButton, ActionClosingModal } from '../Action';
import GuaranteeModal from './GuaranteeModal';
import { nt } from '../utils';

interface Props {
  basePath: string;
  contract?: Contract;
  status: OnboardingActionStatus;
}

const t = nt('signup.onboarding.guarantee');

const GuaranteeAction = ({ basePath, contract, status }: Props) => {
  if (!contract || !contract.guarantee) {
    return null;
  }

  const { guarantee } = contract;
  const guaranteePath = `${basePath}/borgtocht`;

  if (!guarantee.signed) {
    const Button = () => (
      <ActionButton to={guaranteePath}>{t('button')}</ActionButton>
    );

    return (
      <>
        <Action
          button={Button}
          description={t('description.not_signed')}
          path={guaranteePath}
          status={status}
          title={t('title')}
        />
        <Route path={guaranteePath}>
          <ActionClosingModal basePath={basePath}>
            <GuaranteeModal />
          </ActionClosingModal>
        </Route>
      </>
    );
  }

  if (guarantee.guaranteeRequiresCoSigner && !guarantee.coSignerSigned) {
    return (
      <Action
        button={null}
        description={t('description.not_signed_by_partner', {
          email: guarantee.coSignerEmail,
        })}
        path={guaranteePath}
        status={status}
        title={t('title')}
      />
    );
  }

  return (
    <Action
      button={null}
      description={t('description.signed')}
      path={guaranteePath}
      status={status}
      title={t('title')}
    />
  );
};

export default GuaranteeAction;

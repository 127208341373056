import React, { ReactNode, useEffect } from 'react';

import { Button } from 'shared/bootstrap';
import ChevronLeftSvg from 'svg/chevron_left.svg';
import { translate } from 'shared/utils/I18n';
import { scrollToTop } from 'shared/utils/scroll';

import BalancePanel from './BalancePanel';
import BucketTable from './BucketTable';
import TransactionsTable from './TransactionsTable';
import TaxTransactionsTable from './TaxTransactionsTable';
import GenericChart from './GenericChart';
import TimeFilterDropdown, { TimeFilter } from './TimeFilterDropdown';
import { getCharts, colors } from './charts';
import HealthRatingPanel from './HealthRatingPanel';
import { TransactionInsights } from './TransactionInsights';

const t = translate('insights.page');

export type Section =
  | 'overview'
  | 'finance'
  | 'taxes'
  | 'debtors_creditors'
  | 'balances';

interface ComponentProps {
  children?: ReactNode;
  months: TimeFilter;
  setMonths: (months: TimeFilter) => void;
  showHealthRating?: boolean;
  navigateToSection: (section: Section) => void;
  section: Section;
  onBack?: () => void;
}

type Props = TransactionInsights & ComponentProps;

const Page = ({
  accounts,
  buckets,
  closingBalances,
  healthRating,
  months,
  periods,
  transactions,
  setMonths,
  showHealthRating,
  section,
  navigateToSection,
  onBack,
  children,
}: Props) => {
  useEffect(scrollToTop, [section]);

  const [data, categories] = getCharts(periods, buckets);

  return (
    <div className="ti-space-y-4">
      <div className="ti-row">
        {onBack && (
          <Button onClick={onBack} className="button-light">
            <div className="tw-flex tw-items-center">
              <ChevronLeftSvg className="tw-mr-2 tw-h-3 tw-w-3 tw-fill-floryn-bleen" />
              {section === 'overview'
                ? t('back_to_root')
                : t('back_to_dashboard')}
            </div>
          </Button>
        )}

        <h1>{t('title')}</h1>
        <TimeFilterDropdown currentValue={months} onSelect={setMonths} />
        {children}
      </div>

      <div className="ti-space-y-4">
        {section === 'overview' && (
          <>
            {showHealthRating && healthRating && (
              <HealthRatingPanel healthRating={healthRating} />
            )}
            <div className="ti-panels">
              <BalancePanel
                onExpand={() => navigateToSection('balances')}
                accounts={accounts}
                months={months}
                closingBalances={closingBalances}
                colors={colors}
              />
              <GenericChart
                legend
                data={data}
                categories={categories.cashflow}
                title={t('debtors_creditors_chart.title')}
                onExpand={() => navigateToSection('debtors_creditors')}
              />
              <GenericChart
                stacked
                onExpand={() => navigateToSection('debtors_creditors')}
                data={data}
                categories={categories.debtors}
                title={t('buckets.debtors')}
              />
              <GenericChart
                stacked
                onExpand={() => navigateToSection('debtors_creditors')}
                data={data}
                categories={categories.creditors}
                title={t('buckets.creditors')}
              />
              <GenericChart
                legend
                onExpand={() => navigateToSection('finance')}
                data={data}
                categories={categories.finance}
                title={t('finance_chart.title')}
              />
              <GenericChart
                legend
                stacked
                data={data}
                categories={categories.wages}
                title={t('taxes_panel.title')}
                onExpand={() => navigateToSection('taxes')}
              />
            </div>
          </>
        )}

        {section === 'finance' && (
          <>
            <GenericChart
              legend
              data={data}
              categories={categories.finance}
              title={t('finance_chart.title')}
            />

            <BucketTable
              title={t('buckets.finance_withdrawals')}
              periods={periods}
              bucket={buckets.financeWithdrawals}
            />
            <BucketTable
              title={t('buckets.finance_interest_and_repayments')}
              periods={periods}
              bucket={buckets.financeInterestAndRepayments}
            />
            <BucketTable
              title={t('buckets.informal_finance_withdrawals')}
              periods={periods}
              bucket={buckets.informalFinanceWithdrawals}
            />
            <BucketTable
              title={t('buckets.informal_finance_interest_and_repayments')}
              periods={periods}
              bucket={buckets.informalFinanceInterestAndRepayments}
            />
          </>
        )}

        {section === 'taxes' && (
          <>
            <GenericChart
              legend
              stacked
              data={data}
              categories={categories.wages}
              title={t('wages_chart.title')}
            >
              <BucketTable
                title={t('buckets.wage_taxes')}
                periods={periods}
                bucket={buckets.wageTaxes}
                swatches={[colors[0], undefined, colors[1]]}
              />
            </GenericChart>

            <TaxTransactionsTable
              title={t('transactions.wage_taxes')}
              transactions={transactions.wageTaxes}
            />
            <GenericChart
              legend
              title={t('buckets.vat')}
              categories={categories.taxes}
              data={data}
            >
              <BucketTable
                title={t('buckets.vat')}
                periods={periods}
                bucket={buckets.vat}
                swatches={[
                  undefined,
                  colors[0],
                  colors[1],
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                ]}
              />
            </GenericChart>
            <BucketTable
              title={t('buckets.vat_expected')}
              periods={periods}
              bucket={buckets.vatExpected}
              swatches={[undefined, undefined, undefined, undefined, undefined]}
            />
            <TaxTransactionsTable
              title={t('transactions.vat')}
              transactions={transactions.vat}
            />
            <TaxTransactionsTable
              title={t('transactions.corporation_taxes')}
              transactions={transactions.corporationTaxes}
            />
            <TaxTransactionsTable
              title={t('transactions.misc_taxes')}
              transactions={transactions.miscTaxes}
            />
          </>
        )}

        {section === 'debtors_creditors' && (
          <>
            <GenericChart
              legend
              data={data}
              categories={categories.cashflow}
              title={t('debtors_creditors_chart.title')}
            />

            <GenericChart
              stacked
              data={data}
              categories={categories.debtors}
              title={t('buckets.debtors')}
            >
              <BucketTable
                title={t('buckets.debtors')}
                periods={periods}
                bucket={buckets.debtors}
                swatches={colors}
              />
            </GenericChart>

            <GenericChart
              stacked
              data={data}
              categories={categories.creditors}
              title={t('buckets.creditors')}
            >
              <BucketTable
                title={t('buckets.creditors')}
                periods={periods}
                bucket={buckets.creditors}
                swatches={colors}
              />
            </GenericChart>

            <BucketTable
              title={t('buckets.revenue')}
              periods={periods}
              bucket={buckets.revenue}
            />
          </>
        )}

        {section === 'balances' && (
          <>
            <BalancePanel
              accounts={accounts}
              closingBalances={closingBalances}
              colors={colors}
              months={months}
            />

            <div>
              <BucketTable
                title={t('buckets.overview')}
                periods={periods}
                bucket={buckets.overview}
              />
            </div>

            <div>
              <h3>{t('chapters.storno')}</h3>
              <BucketTable
                title={t('buckets.storno')}
                periods={periods}
                bucket={buckets.storno}
              />
              <TransactionsTable
                title={t('transactions.storno')}
                transactions={transactions.storno}
              />
            </div>

            <div>
              <h3>{t('chapters.other')}</h3>
              <BucketTable
                title={t('buckets.internal')}
                periods={periods}
                bucket={buckets.internal}
              />
              <TransactionsTable
                title={t('transactions.internal')}
                transactions={transactions.internal}
              />
              <BucketTable
                title={t('buckets.cash')}
                periods={periods}
                bucket={buckets.cash}
              />
              <TransactionsTable
                title={t('transactions.cash')}
                transactions={transactions.cash}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Page;

import React from 'react';
import DeliveriesPage from 'app/components/seller/profile/DeliveriesPage';
import ContentWithSidebar from 'shared/components/tailwind/ContentWithSidebar';
import DefaultSidebar from 'shared/components/DefaultSidebar';
import { SELLER_ONBOARDING } from 'app/routes';
import BackButton from '../BackButton';

const DeliveriesStep = () => (
  <ContentWithSidebar sidebarChildren={<DefaultSidebar />}>
    <BackButton to={SELLER_ONBOARDING} />
    <DeliveriesPage />
  </ContentWithSidebar>
);

export default DeliveriesStep;

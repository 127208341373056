import React from 'react';
import { Link } from 'react-router-dom';
import ChevronLeftSvg from 'svg/chevron_left.svg';
import { nt } from 'shared/utils/I18n';
import { ns } from './Questions/utils';

const BackButton = ({ to }) => (
  <Link
    to={to}
    className="button-light tw-inline-flex tw-items-center tw-gap-2"
  >
    <ChevronLeftSvg className="tw-h-3 tw-w-3 tw-fill-floryn-bleen" />
    {nt(ns, 'back')}
  </Link>
);

export default BackButton;

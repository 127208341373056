import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccountStep from 'signup/components/seller/AccountStep';
import AdditionalQuestionsStep from './AdditionalQuestionsStep';
import BankStatementsStep from 'namespaces/signup/components/seller/BankStatementsStep';
import {
  SELLER_SIGNUP_ACCOUNT_STEP,
  SELLER_SIGNUP_ADDITIONAL_QUESTIONS_STEP,
  SELLER_SIGNUP_BANK_STATEMENTS_STEP,
  SELLER_SIGNUP_BASE,
  SELLER_SIGNUP_UBO_IDENTIFICATION_STEP,
} from 'app/routes';
import {
  useCanAccessAdditionalQuestionsRoute,
  useCanAccessUboIdentificationRoute,
} from 'shared/hooks/useCanAccessRoutes';
import RedirectToFrontpage from 'shared/utils/routing/RedirectToFrontpage';
import UboIdentificationStep from 'signup/components/seller/UboIdentificationStep';

const CurrentStep = () => {
  const canAccessAdditionalQuestionsRoute =
    useCanAccessAdditionalQuestionsRoute();
  const canAccessUBOIdentificationRoute = useCanAccessUboIdentificationRoute();

  return (
    <Switch>
      <Route path={[SELLER_SIGNUP_BASE, SELLER_SIGNUP_ACCOUNT_STEP]} exact>
        <AccountStep />
      </Route>
      <Route path={SELLER_SIGNUP_BANK_STATEMENTS_STEP}>
        <BankStatementsStep />
      </Route>
      <Route path={SELLER_SIGNUP_ADDITIONAL_QUESTIONS_STEP}>
        {canAccessAdditionalQuestionsRoute ? (
          <AdditionalQuestionsStep />
        ) : (
          <RedirectToFrontpage />
        )}
      </Route>
      <Route path={SELLER_SIGNUP_UBO_IDENTIFICATION_STEP}>
        {canAccessUBOIdentificationRoute ? (
          <UboIdentificationStep />
        ) : (
          <RedirectToFrontpage />
        )}
      </Route>
      <Route path={[SELLER_SIGNUP_BASE, SELLER_SIGNUP_ACCOUNT_STEP]}>
        <AccountStep />
      </Route>
    </Switch>
  );
};

export default CurrentStep;

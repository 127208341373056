import React, { useState } from 'react';
import { useParams } from 'react-router';

import Button from 'shared/components/tailwind/Button';
import ContainerWithoutPadding from 'shared/components/tailwind/ContainerWithoutPadding';
import client from 'shared/utils/client';
import ServerError from 'shared/components/ServerError';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import Page from './Page';
import { t } from './utils';

type Status = 'not_asked' | 'failure' | 'pending' | 'success';

interface Props {
  canReset: boolean;
}

const ExpiredTokenPage = ({ canReset }: Props) => {
  const { token } = useParams<{ token: string }>();
  const [status, setStatus] = useState<Status>('not_asked');

  const resetToken = async () => {
    const response = await client(
      'POST',
      `/api/onboarding_users/${token}/reset_token`,
      {},
      { raiseError: false, acceptHtml: true }
    );

    if (response.error) {
      setStatus('failure');
    } else {
      setStatus('success');
    }
  };

  return (
    <Page>
      <ContainerWithoutPadding>
        <div className="tw-flex tw-flex-col tw-gap-4 tw-py-4 md:tw-py-10">
          {status === 'success' && <div>{t('error.token_is_reset')}</div>}
          {status !== 'success' && (
            <>
              <div>{t('error.expired_message')}</div>
              <div className="tw-py-4">
                {canReset && (
                  <Button
                    className="button-primary"
                    onClick={resetToken}
                    disabled={status !== 'not_asked' && status !== 'failure'}
                  >
                    {t('error.mail_new_link')}
                  </Button>
                )}
              </div>
              {status === 'failure' && <ServerError />}
              {status === 'pending' && <LoadingAnimation />}
            </>
          )}
        </div>
      </ContainerWithoutPadding>
    </Page>
  );
};

export default ExpiredTokenPage;

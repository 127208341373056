import React from 'react';

import TextInput from 'hookForm/TextInput';
import Select from 'hookForm/Select';
import Button from 'shared/components/tailwind/Button';
import * as I18n from 'shared/utils/I18n';
import { NewInvoiceContact } from './types';
import useAddNewContact from './useAddNewContact';

interface Props {
  addContact: (contact: NewInvoiceContact) => void;
}

const AddContactForm = ({ addContact }: Props) => {
  const { control, submit } = useAddNewContact({ addContact });

  const languagePreferenceOptions: [string, string][] = [
    ['nl', I18n.nt('app', 'nl')],
    ['en', I18n.nt('app', 'en')],
  ];

  return (
    <div>
      <div className="tw-mb-2 tw-flex tw-flex-col tw-gap-4 md:tw-flex-row md:tw-justify-between">
        <div className="tw-grow">
          <TextInput
            control={control}
            hint={I18n.hint('contact', 'fullName')}
            includeLayoutClass={false}
            label={I18n.label('contact', 'fullName')}
            name="fullName"
          />
        </div>
        <TextInput
          control={control}
          includeLayoutClass={false}
          label={I18n.label('contact', 'email')}
          name="email"
        />
        <Select
          control={control}
          includeEmpty={false}
          includeLayoutClass={false}
          label={I18n.label('contact', 'locale')}
          name="locale"
          options={languagePreferenceOptions}
        />
      </div>
      <Button
        className="tw-pl-0 tw-font-heading tw-text-floryn-blue hover:tw-text-floryn-blue-hover"
        onClick={submit}
      >
        {I18n.ut('form.contactsInput.anotherContact')}
      </Button>
    </div>
  );
};

export default AddContactForm;

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'shared/utils/I18n';
import { Glyphicon } from 'shared/bootstrap';
import classNames from 'classnames';
import { ReduxState, Review, Seller } from 'types';
import { currentSellerSelector } from 'shared/selectors';
import { load as loadReview } from 'shared/modules/review';

const t = translate('signup.onboarding.internal_account_detection');

interface Props {
  loadReview: (sellerId: number) => void;
  seller: Seller;
  review: Review | null;
}

class InternalAccountDetection extends React.Component<Props> {
  public render() {
    const hasRequiredIbans =
      this.props.review && this.props.review.nonAisRequiredIbans.length > 0;

    const internalAccountDetectionEnabled =
      this.props.seller.aBTests.internalAccountDetection === true &&
      (hasRequiredIbans || this.props.review?.hasSuspectedInternalAccounts);

    return (
      internalAccountDetectionEnabled && (
        <div
          className={classNames(
            'alert alert-warning tw-mb-0 tw-text-sm',
            hasRequiredIbans && 'tw-pb-0'
          )}
        >
          <div className="rw-sidebar rw-sidebar--left rw-gap-12">
            <div className="tw-mt-px">
              <Glyphicon glyph="search" />
            </div>
            <div className="rw-space-y-8">
              <div className="tw-font-medium">
                {hasRequiredIbans
                  ? t('required_ibans_title')
                  : t('suspected_internal_title')}
              </div>
              {hasRequiredIbans && (
                <div className="tw-mt-1">
                  <div>{t('required_ibans_description')}</div>
                  <div className="tw-pl-4 tw-pt-2">
                    <ul>
                      {this.props.review.nonAisRequiredIbans.map(
                        (iban, index) => (
                          <li key={index} className="tw-flex">
                            <div className="tw-flex tw-items-center">
                              <span className="icon icon-arrow-right2 tw-mr-2" />
                            </div>
                            <div className="tw-font-bold">{iban}</div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    );
  }

  public componentDidMount() {
    this.props.loadReview(this.props.seller.id);
  }
}

const mapStateToProps = (state: ReduxState) => {
  const review = state.charlie.review.payload;
  const seller = currentSellerSelector(state) as Seller;

  return { review, seller };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loadReview }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalAccountDetection);

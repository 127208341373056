import { trackEvent } from 'namespaces/shared/utils/tracker';
import React, { Component } from 'react';
import { Button, Modal, Alert } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import TwoFactorPhoneForm from './TwoFactorPhoneForm';
import TwoFactorTokenForm from './TwoFactorTokenForm';

const ns = 'app.profilePage';

interface Props {
  show: boolean;
  hideModal: () => void;
  twoFactorEnabled: (any) => void;
  dashboardWarning?: boolean;
  hideOnSuccess?: boolean;
}

class EnableTwoFactorModal extends Component<Props> {
  goToStep = (number: number): void => {
    this.setState({ step: number });
  };

  hideModal(): void {
    if (this.state.step === 4) {
      trackEvent('EnableTwoFactorModal.Enabled');
      this.props.twoFactorEnabled(this.state.phone);
    }

    if (this.props.hideOnSuccess !== false || this.state.step !== 4) {
      trackEvent('EnableTwoFactorModal.Closed');
      this.props.hideModal();
    }

    this.setState({ step: 1 });
  }

  state = {
    step: 1,
    phone: '',
  };

  setPhone(phone): void {
    this.setState({ phone });
  }

  componentDidMount() {
    trackEvent('EnableTwoFactorModal.Step', { step: this.state.step });
  }

  componentDidUpdate(_, prevState) {
    if (prevState.step !== this.state.step) {
      trackEvent('EnableTwoFactorModal.Step', {
        step: this.state.step,
      });
    }
  }

  renderStep() {
    const tfmns = 'app.profilePage.twoFactorSettings.enableModal';

    switch (this.state.step) {
      case 1:
        return (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                {I18n.nt(ns, 'twoFactorSettings.title')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.dashboardWarning && (
                <Alert type="warning">
                  {I18n.nt(ns, 'twoFactorSettings.dashboardWarning')}
                </Alert>
              )}
              <p>{I18n.nt(ns, 'twoFactorSettings.description')}</p>
              <p>{I18n.nt(tfmns, 'step1.processDescription')}</p>
              <div className="inner-modal-footer">
                <Button
                  className="button-primary"
                  onClick={() => this.goToStep(2)}
                >
                  {I18n.nt(tfmns, 'continue')}
                </Button>
              </div>
            </Modal.Body>
          </>
        );
      case 2:
        return (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{I18n.nt(tfmns, 'step2.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{I18n.nt(tfmns, 'step2.description')}</p>
              <TwoFactorPhoneForm
                setPhone={this.setPhone.bind(this)}
                goToNextStep={() => this.goToStep(3)}
              />
            </Modal.Body>
          </>
        );
      case 3:
        return (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{I18n.nt(tfmns, 'step3.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {I18n.nt(
                  tfmns,
                  'step3.description',
                  {},
                  { phone_number: this.state.phone }
                )}
              </p>
              <TwoFactorTokenForm goToNextStep={() => this.goToStep(4)} />
            </Modal.Body>
          </>
        );
      case 4:
        return (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{I18n.nt(tfmns, 'step4.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{I18n.nt(tfmns, 'step4.description')}</p>
              <div className="inner-modal-footer">
                <Button
                  className="button-primary"
                  onClick={this.hideModal.bind(this)}
                >
                  {I18n.nt(tfmns, 'step4.complete')}
                </Button>
              </div>
            </Modal.Body>
          </>
        );
      default:
        return <></>;
    }
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.hideModal.bind(this)}>
        {this.renderStep()}
      </Modal>
    );
  }
}

export default EnableTwoFactorModal;

import React, { useState } from 'react';

import { Button, Col, Modal, Row } from 'shared/bootstrap';
import { nt } from 'Onboarding/components/utils';
import * as I18n from 'shared/utils/I18n';
import { UltimateBeneficialOwner } from 'types';
import TextInput from 'hookForm/TextInput';
import MaskedTextInput from 'hookForm/MaskedTextInput';
import usePersonaliaStep from './usePersonaliaStep';
import Select from 'hookForm/Select';
import GeneralErrors from 'hookForm/GeneralErrors';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { countries } from 'Onboarding/constants';

const t = nt('signup.onboarding.ultimate_beneficial_owners.ubo_form');

interface Props {
  addUboToState: (ubo: UltimateBeneficialOwner) => void;
  ubo: UltimateBeneficialOwner | null;
  updateUboInState: (ubo: UltimateBeneficialOwner) => void;
  removeUboFromState: (ubo: UltimateBeneficialOwner) => void;
}

export default ({
  addUboToState,
  ubo,
  updateUboInState,
  removeUboFromState,
}: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    control,
    deleteUbo,
    displayShareholderPercentageField,
    isDeleting,
    isSubmitting,
    role,
    roleOptions,
    serverErrorMessages,
    submit,
  } = usePersonaliaStep({
    addUboToState,
    ubo,
    updateUboInState,
    removeUboFromState,
  });

  return (
    <>
      <form onSubmit={submit}>
        <Modal.Header closeButton>
          <Modal.Title>{t(ubo ? 'title.edit' : 'title.add')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GeneralErrors generalErrors={serverErrorMessages} />
          <Row>
            <Col md={6}>
              <TextInput
                control={control}
                label={I18n.label('ultimateBeneficialOwner', 'firstName')}
                name="firstName"
              />
            </Col>
            <Col md={6}>
              <TextInput
                control={control}
                label={I18n.label('ultimateBeneficialOwner', 'lastName')}
                name="lastName"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <MaskedTextInput
                control={control}
                inputMode="decimal"
                label={I18n.label('ultimateBeneficialOwner', 'birthDate')}
                mask="##-##-####"
                name="birthDate"
                placeholder="DD-MM-JJJJ"
              />
            </Col>
            <Col md={6}>
              <Select
                control={control}
                hint={I18n.hint(
                  'ultimateBeneficialOwner',
                  'countryOfResidence'
                )}
                label={I18n.label(
                  'ultimateBeneficialOwner',
                  'countryOfResidence'
                )}
                name="countryOfResidence"
                options={countries}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextInput
                control={control}
                label={I18n.label('ultimateBeneficialOwner', 'email')}
                name="email"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Select
                control={control}
                hint={I18n.hint('ultimateBeneficialOwner', 'role')}
                includeEmpty
                label={I18n.label('ultimateBeneficialOwner', 'role')}
                name="role"
                options={roleOptions}
                tooltipSize="md"
              />
            </Col>
            <Col md={6}>
              {role === 'other' && (
                <TextInput
                  control={control}
                  label={I18n.label('ultimateBeneficialOwner', 'otherRole')}
                  name="otherRole"
                />
              )}
              {displayShareholderPercentageField && (
                <TextInput
                  addOn="%"
                  control={control}
                  label={I18n.label(
                    'ultimateBeneficialOwner',
                    'shareholderPercentage'
                  )}
                  name="shareholderPercentage"
                  type="number"
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {ubo && (
            <Button
              className="button-light pull-left"
              onClick={() => setShowDeleteModal(true)}
            >
              {t('delete')}
            </Button>
          )}
          <Button
            type="submit"
            className="button-primary"
            disabled={isSubmitting}
          >
            {t('submit')}
          </Button>
        </Modal.Footer>
      </form>

      <ConfirmDeleteModal
        show={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={deleteUbo}
        isDeleting={isDeleting}
      />
    </>
  );
};

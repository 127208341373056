import React from 'react';
import { useSelector } from 'react-redux';

import { ReduxState, Review } from 'types';
import * as I18n from 'shared/utils/I18n';
import IbanInfoList from 'shared/components/UploadBankStatements/IbanInfoList';
import { requiredAndOtherIbans } from 'shared/utils/requiredIbans';

const ns = 'shared.required_ibans';

const RequiredIbans = () => {
  const review = useSelector(
    (state: ReduxState) => state.partnerSignup.review as Review
  );
  const bankStatementAttachments = review.bankStatementAttachments;

  const { otherIbans, requiredIbans } = requiredAndOtherIbans(
    review,
    bankStatementAttachments
  );

  return (
    <div className="RequiredIbans">
      {requiredIbans.length !== 0 && (
        <>
          <p>{I18n.nt(ns, 'required_ibans_intro')}</p>
          <IbanInfoList ibanInfoList={requiredIbans} />
        </>
      )}
      {otherIbans.length !== 0 && (
        <div className="OtherIbans">
          {requiredIbans.length !== 0 && <p>{I18n.nt(ns, 'other_ibans')}</p>}
          <IbanInfoList ibanInfoList={otherIbans} />
        </div>
      )}
    </div>
  );
};

export default RequiredIbans;

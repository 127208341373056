import React from 'react';
import PropTypes from 'prop-types';

export default class Attachments extends React.Component {
  renderAttachment(attachment) {
    return (
      <li key={attachment.id}>
        <a
          href={`/api/attachments/${attachment.id}/download`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {attachment.fileName}
        </a>
      </li>
    );
  }

  render() {
    const { attachments } = this.props;

    return (
      <ul>
        {attachments.map((attachment) => this.renderAttachment(attachment))}
      </ul>
    );
  }
}
Attachments.propTypes = {
  attachments: PropTypes.array.isRequired,
};

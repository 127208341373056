import * as routes from 'app/routes';
import client from 'shared/utils/client';
import { asyncAction } from 'shared/utils/Helpers';
import { pushToPath } from 'shared/utils/routing';
import { currentStepSelector, currentSellerSelector } from 'shared/selectors';
import { nextStep, previousStep } from 'models/SignupOrder';
import { trackEvent } from 'shared/utils/tracker';

export const SET_BANK_STATEMENT_UPLOAD_TYPE =
  'if.profile.SET_BANK_STATEMENT_UPLOAD_TYPE';
export const TRANSITION_SELLER = asyncAction('if.profile.TRANSITION_SELLER');
export const UPDATE_BANK_STATEMENT_ATTACHMENTS = asyncAction(
  'if.profile.UPDATE_BANK_STATEMENT_ATTACHMENTS'
);
export const UPDATE_SELLER = asyncAction('if.profile.UPDATE_SELLER');

/*
 * PUT seller data
 * go to next step in signup
 * optionally update segment
 */
export const updateSeller = (params) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_SELLER.REQUEST });

  const seller = currentSellerSelector(getState());

  let response = null;
  try {
    response = await client('PUT', `/api/sellers/${seller.id}`, params);
  } catch (e) {
    dispatch({ type: UPDATE_SELLER.FAILURE, error: true, ...e });
    return e;
  }
  const succesAction = dispatch({
    type: UPDATE_SELLER.SUCCESS,
    normalize: true,
    ...response,
  });

  const state = getState();
  const currentStep = currentStepSelector(state);
  const step = nextStep(currentStep) || 'bedrijfsgegevens';

  dispatch(pushToPath(routes.SELLER_SIGNUP_STEP, { currentStep: step }));

  return succesAction;
};

export const updateBankStatementAttachments = (
  bankStatementAttachments,
  form
) => ({
  type: UPDATE_BANK_STATEMENT_ATTACHMENTS,
  form,
  normalize: true,
  promise: (client, store) => {
    const seller = currentSellerSelector(store.getState());
    return client(
      'PUT',
      `/api/sellers/${seller.id}/update_bank_statement_uploads`,
      { bankStatementAttachments } // ids
    );
  },
});

export const submitSeller = () => (dispatch, getState) => {
  const seller = currentSellerSelector(getState());

  dispatch({ type: TRANSITION_SELLER.REQUEST });

  return client('POST', `/api/sellers/${seller.id}/transition`, {
    toStatus: 'submitted',
  }).then(
    (response) => {
      dispatch({
        type: TRANSITION_SELLER.SUCCESS,
        normalize: true,
        ...response,
      });
      dispatch(pushToPath(routes.SELLER_ONBOARDING));
    },
    (response) =>
      dispatch({ type: TRANSITION_SELLER.FAILURE, error: true, ...response })
  );
};

export const goToNextStep = () => (dispatch, getState) => {
  const state = getState();
  const currentStep = currentStepSelector(state);

  dispatch(
    pushToPath(routes.SELLER_SIGNUP_STEP, {
      currentStep: nextStep(currentStep),
    })
  );
};

export const goToPreviousStep = () => (dispatch, getState) => {
  const state = getState();
  const currentStep = currentStepSelector(state);

  dispatch(
    pushToPath(routes.SELLER_SIGNUP_STEP, {
      currentStep: previousStep(currentStep),
    })
  );
};

export const setBankStatementUploadType = (uploadType) => {
  trackEvent(
    `Bankstatements switch to ${uploadType === 'documents' ? 'PDF' : 'PSD2'}`
  );

  return {
    type: SET_BANK_STATEMENT_UPLOAD_TYPE,
    payload: uploadType,
  };
};

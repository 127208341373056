import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';

import { parseISO } from 'date-fns';

import { withdraw } from 'charlie/modules/dashboardSlice';
import {
  calculateNewDebtCollectionAmount,
  usesClientBankAccount,
} from 'charlie/model';

import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';
import resolver from 'hookForm/resolver';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import useIsComplexAccount from 'shared/hooks/useIsComplexAccount';
import { toEuro } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import { validateAll, validatePresence } from 'shared/utils/validation';
import { instantPaymentAvailability } from 'models/Seller';

import { MinimalSeller, ReduxState } from 'types';

interface FormValues {
  amount: number;
}

const ns = 'charlie.frontpage.withdraw_dialog';

const MAX_AMOUNT = 100000000;

const validateAvailableAmount = (availableBalance) => (amount) =>
  amount <= availableBalance ? null : I18n.nt(ns, 'cannot_withdraw');

const validateClosingCommission = (closingCommissionAmount) => (amount) =>
  closingCommissionAmount > 0 && amount < closingCommissionAmount
    ? I18n.nt(ns, 'amount_invalid_closing_commission')
    : null;

const validateMaxAmount = () => (amount) =>
  amount < MAX_AMOUNT
    ? null
    : I18n.t('errors.messages.less_than', { count: toEuro(MAX_AMOUNT) as any });

const validateMinAmount = (minimumWithdrawalAmount) => (amount) =>
  amount >= minimumWithdrawalAmount
    ? null
    : I18n.nt(ns, 'amount_ge_minimum', {
        amount: toEuro(minimumWithdrawalAmount),
      });

const validateAmount = (
  amount,
  availableBalance,
  closingCommissionAmount,
  minimumWithdrawalAmount
) =>
  validateAll(
    validatePresence,
    validateAvailableAmount(availableBalance),
    validateMaxAmount(),
    validateClosingCommission(closingCommissionAmount),
    validateMinAmount(minimumWithdrawalAmount)
  )(amount);

const useWithdrawDialog = (afterSubmit) => {
  const dispatch = useAppDispatch();
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [isInstantWithdrawal, setIsInstantWithdrawal] =
    useState<boolean>(false);

  const validate = ({ amount }: FormValues) => {
    const validationError = validateAmount(
      amount,
      availableBalance,
      closingCommissionAmount,
      minimumWithdrawalAmount
    );

    return { amount: validationError };
  };

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: resolver(validate),
  });

  const amount = useWatch({ control, name: 'amount' }) || 0.0;
  const seller = useCurrentSeller() as MinimalSeller;
  const companyName = seller.companyName || '';
  const complexAccount = useIsComplexAccount();
  const instantPayment = instantPaymentAvailability(seller);

  // Available balance
  const availableBalanceFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.availableBalance
  );
  const availableBalance = parseFloat(availableBalanceFromState || '');

  // Closing commission amount
  const closingCommissionAmountFromState = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.closingCommissionAmount
  );
  const closingCommissionAmount = parseFloat(
    closingCommissionAmountFromState || ''
  );

  // Debt collection for new withdrawal
  const debtCollectionForNewWithdrawal = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.debtCollectionForNewWithdrawal
  );

  // Debt collection rate
  const debtCollectionRateFromState = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.debtCollectionRate || ''
  );
  const debtCollectionRate = parseFloat(debtCollectionRateFromState || '');

  // Interest rate
  const interestRateFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.interestRate
  );
  const interestRate = parseFloat(interestRateFromState || '');

  // Minimum withdrawal amount
  const minimumWithdrawalAmountFromState = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.minimumWithdrawalAmount
  );
  const minimumWithdrawalAmount = parseFloat(
    minimumWithdrawalAmountFromState || ''
  );

  // Pending current balance
  const pendingCurrentBalanceFromState = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.pendingCurrentBalance
  );
  const pendingCurrentBalance = parseFloat(
    pendingCurrentBalanceFromState || ''
  );

  // Pending current limit
  const pendingCurrentLimitFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.pendingCurrentLimit
  );
  const pendingCurrentLimit = parseFloat(pendingCurrentLimitFromState || '');

  // Requested amount
  const requestedAmountFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.requestedAmount
  );
  const requestedAmount = parseFloat(requestedAmountFromState || '');

  // Service fee rate
  const serviceFeeRateFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.serviceFeeRate
  );
  const serviceFeeRate = parseFloat(serviceFeeRateFromState || '');

  // Withdrawal fee rate
  const withdrawalFeeRateFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.withdrawalFeeRate
  );
  const withdrawalFeeRate = parseFloat(withdrawalFeeRateFromState || '');

  const currentDebtCollectionAmount = debtCollectionForNewWithdrawal
    ? parseFloat(debtCollectionForNewWithdrawal?.amount)
    : 0;
  const debtCollectionDate = debtCollectionForNewWithdrawal
    ? parseISO(debtCollectionForNewWithdrawal.date)
    : undefined;
  const newDebtCollectionAmount = calculateNewDebtCollectionAmount({
    currentDebtCollectionAmount,
    debtCollectionRate: debtCollectionRate,
    minimumWithdrawalAmount: minimumWithdrawalAmount,
    pendingCurrentLimit: pendingCurrentLimit,
    requestedAmount: requestedAmount,
    withdrawAmount: amount,
  });
  const withdrawalWillRequireApproval = seller.withdrawalWillRequireApproval;

  const summaryProperties = {
    amount: amount,
    interestRate: interestRate,
    closingCommissionAmount: closingCommissionAmount,
    currentDebtCollectionAmount: currentDebtCollectionAmount,
    debtCollectionDate: debtCollectionDate,
    minimumWithdrawalAmount: minimumWithdrawalAmount,
    newCurrentBalance: pendingCurrentBalance - amount,
    newDebtCollectionAmount: newDebtCollectionAmount,
    serviceFeeRate: serviceFeeRate,
    usesClientBankAccount: usesClientBankAccount(seller),
    withdrawalFeeRate: withdrawalFeeRate,
    instantPayment: instantPayment,
    withdrawalWillRequireApproval: withdrawalWillRequireApproval,
    pendingCurrentBalance: pendingCurrentBalance,
    debtCollectionRate: debtCollectionRate,
    isInstantWithdrawal: isInstantWithdrawal,
  };

  const submit = async (values: FormValues) => {
    const response = await dispatch(
      withdraw({ amount: values.amount, sellerId: seller.id })
    );

    if (response.payload && withdraw.rejected.match(response)) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        response.payload,
        setError
      );
      setServerErrorMessages(generalErrorMessages);
    } else {
      afterSubmit();
      setSucceeded(true);
      if (response.payload && 'withdrawStatus' in response.payload) {
        setIsInstantWithdrawal(
          response.payload.withdrawStatus?.isInstant ?? false
        );
      } else {
        setIsInstantWithdrawal(false);
      }
    }
  };

  const withdrawAll = () => {
    setServerErrorMessages([]);
    setValue('amount', availableBalance);
  };

  return {
    availableBalance,
    companyName,
    complexAccount,
    control,
    generalErrors: serverErrorMessages,
    handleSubmit: handleSubmit(submit),
    isSubmitting,
    summaryProperties,
    withdrawAll,
    succeeded,
  };
};

export default useWithdrawDialog;

import {
  bankAccountVerified,
  financeConditions,
  hasBankStatementAttachments,
  hasPsd2Connection,
  pendingCentVerification,
} from 'models/Seller';
import { mainSigner } from 'Onboarding/components/utils';
import { capitalize } from 'shared/utils/Helpers';
import {
  AlfaSeller,
  Contract,
  CustomerSignup,
  OnboardingActionStatus,
  Remote,
  Seller,
} from 'types';
import useDeliveries from 'app/components/seller/profile/DeliveriesPage/useDeliveries';
import { DeliveryRequest } from 'namespaces/app/components/seller/profile/DeliveriesPage/types';

export const bankAccountVerificationStatus = (
  seller: Seller
): OnboardingActionStatus => {
  if (bankAccountVerified(seller)) {
    return 'Done';
  }

  if (pendingCentVerification(seller)) {
    return 'Pending';
  }

  return 'Todo';
};

export const contractStatus = (contract?: Contract): OnboardingActionStatus => {
  if (!contract) {
    return 'Pending';
  }

  const signer = mainSigner(contract);
  const signed = contract.state === 'signed' || signer?.signed;

  if (signed) {
    return 'Done';
  } else if (contract.contractType === 'manual') {
    return 'Pending';
  } else {
    return 'Todo';
  }
};

export const exampleInvoicesStatus = (
  seller: AlfaSeller
): OnboardingActionStatus =>
  seller.exampleInvoices.length > 0 ? 'Done' : 'Todo';

export const guaranteeStatus = (
  contract?: Contract
): OnboardingActionStatus => {
  if (!contract || !contract.guarantee) {
    return 'Pending';
  }

  const { guarantee } = contract;

  if (
    guarantee.signed &&
    (guarantee.guaranteeRequiresCoSigner ? guarantee.coSignerSigned : true)
  ) {
    return 'Done';
  } else {
    return 'Todo';
  }
};

export const referralStatus = (
  customerSignup: CustomerSignup | undefined
): OnboardingActionStatus => {
  if (!customerSignup) {
    return 'Pending';
  }

  return customerSignup.hasReferral ? 'Done' : 'Todo';
};

export const mandateStatus = (seller: Seller): OnboardingActionStatus => {
  switch (seller.debtCollectionMandateState) {
    case 'printed': {
      return 'Pending';
    }
    case 'signed': {
      return 'Done';
    }
    default: {
      return 'Todo';
    }
  }
};

export const propertyTaxationStatus = (
  seller: Seller
): OnboardingActionStatus => {
  if (
    seller.propertyAddress &&
    seller.propertyAddress.length > 0 &&
    seller.valuationReports.length > 0
  ) {
    return 'Done';
  } else {
    return 'Todo';
  }
};

export const statementsStatus = (seller: Seller): OnboardingActionStatus =>
  hasBankStatementAttachments(seller) || hasPsd2Connection(seller)
    ? 'Done'
    : 'Pending';

export const uboStatus = (seller: Seller): OnboardingActionStatus =>
  seller.ubosFinishedAndConfirmed ? 'Done' : 'Todo';

export const verifyIdentityStatus = (seller: Seller): OnboardingActionStatus =>
  capitalize(seller.identifyingPeopleStatus) as OnboardingActionStatus;

export const withdrawalStatus = (seller: Seller): OnboardingActionStatus => {
  const conditions = financeConditions(seller);
  const { amountFirstWithdrawalDuringOnboarding } = conditions;

  return amountFirstWithdrawalDuringOnboarding > 0 ? 'Done' : 'Todo';
};

export const deliveriesStatus = (
  deliveryRequests: Remote<DeliveryRequest[]>
) => {
  // There is no way of telling the status if we don't know about the requests,
  // so assume they are not started yet.
  if (deliveryRequests.type !== 'success') {
    return 'Todo';
  }

  const items = deliveryRequests.response;

  if (items.every((i) => i.status === 'open')) {
    return 'Todo';
  }

  if (items.every((i) => i.status === 'accepted')) {
    return 'Done';
  }

  return 'Pending';
};

export const useDeliveriesStatus = (): OnboardingActionStatus => {
  const { deliveryRequests } = useDeliveries();

  return deliveriesStatus(deliveryRequests);
};

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { mainSellerSelector } from 'shared/selectors';
import { compilePath } from 'shared/utils/routing';
import { useEnsureFullSellers, useUser } from 'shared/hooks';
import { PROFILE_PAGE_TAB } from 'app/routes';
import { resetState } from 'app/modules/profilePage';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import { Seller } from 'types';

interface RouteParams {
  tabKey?: string;
}

const useProfilePage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const routeParams = useParams<RouteParams>();
  const [currentTab, setCurrentTab] = useState(() => routeParams.tabKey ?? '');

  const user = useUser();
  const { sellers, status } = useEnsureFullSellers(user.customerAccountId);
  const mainSeller = useSelector(mainSellerSelector) as Seller;

  const displayExactOnlineTab = sellers.some(
    (seller) => seller.clientBankAccount
  );

  const selectTab = (tabKey: string) => {
    dispatch(resetState());

    const url = compilePath(PROFILE_PAGE_TAB, { tabKey });
    history.push(url);
  };

  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  useEffect(() => {
    setCurrentTab(routeParams.tabKey ?? '');
  }, [routeParams.tabKey]);

  return {
    currentTab,
    displayExactOnlineTab,
    mainSeller,
    selectTab,
    sellers,
    status,
  };
};

export default useProfilePage;

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { SELLER_ONBOARDING } from 'app/routes';
import useEnsureFullSeller from 'shared/hooks/useEnsureFullSeller';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import OnboardingStep from './OnboardingStep';
import QuestionsStep from './Questions/QuestionsStep';
import ProductInformationStep from './ProductInformation/ProductInformationStep';
import DeliveriesStep from './Deliveries/DeliveriesStep';
import useDeliveries from 'namespaces/app/components/seller/profile/DeliveriesPage/useDeliveries';

const OnboardingPage = () => {
  const { seller } = useEnsureFullSeller();
  const { deliveryRequests } = useDeliveries();

  if (!seller || deliveryRequests.type === 'pending') {
    return <LoadingAnimation />;
  }

  const canSeeProductInformation =
    Boolean(seller.contract) ||
    seller.showProposalSummary ||
    seller.showAutomatedOffer;

  return (
    <Switch>
      {canSeeProductInformation && (
        <Route path={`${SELLER_ONBOARDING}/product-informatie`}>
          <ProductInformationStep />
        </Route>
      )}
      <Route path={`${SELLER_ONBOARDING}/questions/:kind`}>
        <QuestionsStep />
      </Route>
      <Route path={`${SELLER_ONBOARDING}/deliveries`}>
        <DeliveriesStep />
      </Route>
      <Route path={SELLER_ONBOARDING}>
        <OnboardingStep deliveryRequests={deliveryRequests} />
      </Route>
    </Switch>
  );
};

export default OnboardingPage;

import React from 'react';
import { Link } from 'react-router-dom';

import * as routes from 'app/routes';
import * as I18n from 'shared/utils/I18n';
import useReviewInfo from 'shared/hooks/useReviewInfo';

const ns = 'charlie.frontpage.warnings';

interface Props {
  asButton?: boolean;
  uploadMore?: boolean;
}

const UploadBankstatementLink = ({
  asButton = false,
  uploadMore = false,
}: Props) => {
  const reviewInfo = useReviewInfo();
  if (!reviewInfo) {
    return null;
  }

  if (reviewInfo.isProcessing) {
    return (
      <div className="analysis-in-progress">
        {I18n.nt(ns, 'analysis_is_in_progress')}
      </div>
    );
  }

  const classes = asButton ? 'button-primary' : 'upload';

  return (
    <div>
      <Link className={classes} to={routes.REVIEW}>
        {I18n.nt(ns, uploadMore ? 'upload_more' : 'upload')}
      </Link>
    </div>
  );
};

export default UploadBankstatementLink;

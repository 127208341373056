import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Button } from 'shared/bootstrap';
import TextInput from 'hookForm/TextInput';
import * as I18n from 'shared/utils/I18n';
import { validatePresence, validatePhone } from 'shared/utils/validation';
import client from 'shared/utils/client';
import resolver from 'hookForm/resolver';
import { trackFormSubmit } from 'shared/utils/tracker';

const tfmns = 'app.profilePage.twoFactorSettings.enableModal';

interface FormValues {
  phone: string;
}

interface Props {
  goToNextStep: () => void;
  setPhone: (value: string) => void;
}

const validate = ({ phone }: FormValues) => ({
  phone: validatePresence(phone) || validatePhone(phone),
});

const TwoFactorPhoneForm = ({ goToNextStep, setPhone }: Props) => {
  const [serverError, setServerError] = useState<string | null>(null);

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: resolver(validate),
  });

  const submit = async ({ phone }: FormValues) => {
    const response = await client(
      'POST',
      '/api/users/enable_two_factor',
      {
        user: { phone },
      },
      { raiseError: false }
    );

    if (response.error) {
      setServerError(I18n.nt(tfmns, 'step2.failedToSendSms'));
    } else {
      trackFormSubmit('enable-two-factor');
      setPhone(phone);
      goToNextStep();
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
      {serverError && <Alert type="danger">{serverError}</Alert>}
      <TextInput
        autoFocus
        control={control}
        label={I18n.label('user', 'phone')}
        name="phone"
      />
      <div className="inner-modal-footer">
        <Button type="submit" className="button-primary">
          {I18n.nt(tfmns, 'continue')}
        </Button>
      </div>
    </form>
  );
};

export default TwoFactorPhoneForm;

import { Onfido } from 'onfido-sdk-ui';

interface Props {
  token: string;
  phoneNumber: string | undefined;
  onComplete: () => void;
}

const customUISettings = {
  fontFamilyTitle: 'GalanoGrotesque, "Helvetica Neue", Helvetica, Arial',

  colorBackgroundSurfaceModal: '#fff',
  borderWidthSurfaceModal: '0',

  colorBackgroundButtonPrimary: '#09b54e',
  colorBackgroundButtonPrimaryHover: '#089d44',
  colorBackgroundButtonPrimaryActive: '#089d44',
  colorBorderButtonPrimary: '#09b54e',

  colorContentButtonSecondaryText: '#919191',
  colorBackgroundButtonSecondary: '#fff',
  colorBackgroundButtonSecondaryHover: '#f2f2f2',
  colorBackgroundButtonSecondaryActive: '#f2f2f2',
  colorBorderButtonSecondary: '#d3d3d3',

  colorContentLinkTextHover: '#fff',
  colorBorderLinkUnderline: '#09b54e',
  colorBackgroundLinkHover: '#089d44',
  colorBackgroundLinkActive: '#089d44',

  colorBorderDocTypeButtonHover: '#09b54e',
  colorBorderDocTypeButtonActive: '#09b54e',

  borderRadiusButton: '30px',
  colorBackgroundInfoPill: '#09b54e',
};

const languageSettings = {
  locale: 'nl_NL',
  phrases: {
    cross_device_checklist: {
      button_primary: 'Verificatie verzenden',
      list_item_doc_multiple: 'Identiteitsbewijs',
      list_item_doc_one: 'Identiteitsbewijs',
      subtitle: 'Hier zie je alles wat je geüpload hebt:',
    },
    cross_device_intro: {
      list_accessibility:
        'Vereiste stappen om de verificatie op jouw mobiele telefoon voort te zetten',
      list_item_finish: 'Kom hier terug om het verzenden af te ronden',
      list_item_open_link: 'Open de link en volg de stappen',
      list_item_send_phone: 'Stuur een veilige link naar jouw telefoon',
      subtitle: 'Hier zie je hoe dat moet:',
      title: 'Ga door op jouw telefoon',
    },
    cross_device_return: {
      body: 'Het kan een paar seconden duren voordat jouw computer de update verwerkt',
      subtitle: 'U kunt nu terug naar jouw computer om door te gaan',
    },
    cross_device_session_linked: {
      info: 'Belangrijk',
      list_item_desktop_open: 'Hou venster op jouw computer open',
      list_item_sent_by_you:
        'Voltooi het proces alleen als deze link zelf verstuurd hebt',
      title: 'Mobiele sessie gekoppeld aan jouw computer',
    },
    doc_confirmation: {
      body: 'Zorg ervoor dat je de volledige documentpagina heeft geüpload en dat de details duidelijk en zonder wazigheid of spiegeling te lezen zijn',
      body_bank_statement:
        'Zorg ervoor dat je de volledige documentpagina heeft geüpload en dat de details duidelijk en zonder wazigheid of spiegeling te lezen zijn',
      body_benefits_letter:
        'Zorg ervoor dat je de volledige documentpagina heeft geüpload en dat de details duidelijk en zonder wazigheid of spiegeling te lezen zijn',
      body_bill:
        'Zorg ervoor dat je de volledige documentpagina heeft geüpload en dat de details duidelijk en zonder wazigheid of spiegeling te lezen zijn',
      body_id: 'Controleer of jouw gegevens goed en volledig zichtbaar zijn',
      body_image_medium:
        'Het duurt langer je te verifiëren als we deze niet kunnen lezen',
      body_image_poor:
        'Om je gemakkelijk te kunnen verifiëren, hebben we een betere foto nodig',
      body_license:
        'Controleer of jouw gegevens goed en volledig zichtbaar zijn',
      body_passport:
        'Controleer of jouw gegevens goed en volledig zichtbaar zijn',
      body_permit:
        'Controleer of jouw gegevens goed en volledig zichtbaar zijn',
      image_accessibility: 'Foto van jouw document',
      title: 'Controleer jouw afbeelding',
    },
    doc_select: {
      title: 'Kies type document',
    },
    doc_submit: {
      subtitle: 'Neem een foto met jouw telefoon',
      title_id_back: 'Verstuur jouw identiteitskaart (achterkant)',
      title_id_front: 'Verstuur jouw identiteitskaart (voorkant)',
      title_license_back: 'Verstuur jouw rijbewijs (achterkant)',
      title_license_front: 'Verstuur jouw rijbewijs (voorkant)',
      title_passport: 'Verstuur de fotopagina van jouw paspoort',
      title_permit_back: 'Verstuur jouw verblijfsvergunning (achterkant)',
      title_permit_front: 'Verstuur jouw verblijfsvergunning (voorkant)',
    },
    generic: {
      errors: {
        geoblocked_error: {
          instruction:
            'Het spijt ons, we kunnen niet verdergaan omdat jouw huidige locatie niet ondersteund wordt',
        },
        sms_failed: {
          instruction: 'Kopieer de link naar jouw telefoon',
        },
        sms_overuse: {
          instruction: 'Kopieer de link naar jouw telefoon',
        },
      },
    },
    get_link: {
      alert_wrong_number: 'Controleer of jouw nummer juist is',
      info_qr_how: 'Hoe scan ik een QR-code?',
      info_qr_how_list_item_camera:
        'Richt de camera van jouw telefoon op de QR-code',
      info_qr_how_list_item_download:
        'Lukt het niet? Download dan een QR-scan app vanuit Google Play of App Store',
      number_field_label: 'Voer jouw mobiele nummer in:',
      subtitle_qr: 'Scan de QR-code met jouw telefoon',
      subtitle_sms: 'Stuur deze eenmalige link naar jouw telefoon',
      subtitle_url: 'Stuur deze eenmalige link naar jouw telefoon',
      title: 'Ontvang jouw veilige link',
      url_field_label: 'Kopieer de link naar jouw mobiele telefoon',
    },
    mobilePhrases: {
      photo_upload: {
        body_id_back: 'Neem een foto van de achterkant van jouw kaart',
        body_id_front: 'Neem een foto van de voorkant van jouw kaart',
        body_passport: 'Neem een foto van de fotopagina van jouw paspoort',
      },
      upload_guide: {
        button_primary: 'Neem een foto',
        title: 'Plaats de fotopagina van jouw document in het kader',
      },
    },
    outro: {
      body: 'Bedankt voor het verifiëren van jouw identiteit.',
      title: 'Bedankt!',
    },
    photo_upload: {
      body_id_back: 'Upload de achterkant van jouw identiteitskaart',
      body_id_front: 'Upload de voorkant van jouw identiteitskaart',
      body_passport: 'Upload de fotopagina van jouw paspoort',
    },
    sms_sent: {
      info: 'Belangrijk',
      info_link_expire: 'De mobiele link verloopt over een uur',
      info_link_window:
        'Houd dit venster open terwijl je je mobiele telefoon gebruikt',
      subtitle_minutes:
        'Het kan een paar minuten duren voordat je deze ontvangt',
      title: 'Check jouw mobiele telefoon',
    },
    switch_phone: {
      info: 'Belangrijk',
      info_link_expire: 'De mobiele link verloopt over een uur',
      info_link_refresh: 'Ververs deze pagina niet',
      info_link_window:
        'Houd dit venster open terwijl je je mobiele telefoon gebruikt',
      link: 'Annuleren',
      subtitle:
        'Zodra je klaar bent, word je doorgestuurd naar de volgende stap',
      title: 'Verbonden met jouw mobiele telefoon',
    },
    upload_guide: {
      button_primary: 'Upload foto',
      subtitle: 'Scans en fotokopieën worden niet geaccepteerd',
      title: 'Upload de fotopagina van jouw paspoort',
    },
    welcome: {
      list_header: 'Gebruik je apparaat om:',
      list_header_webcam:
        'Gebruik jouw telefoon om een foto te maken van jouw identiteitsbewijs.',
      list_item_doc: 'je identiteitsbewijs',
      list_item_doc_photo: 'Maak een foto van je identiteitsbewijs',
      subtitle: 'Dit duurt maar een paar minuten',
      title: 'Verifieer jouw identiteit',
    },
  } as any as Record<string, string>, // override incorrect type
};

export default ({ token, phoneNumber, onComplete }: Props) =>
  Onfido.init({
    token: token,
    containerId: 'onfido-mount',
    smsNumberCountryCode: 'NL',
    onComplete,
    userDetails: {
      smsNumber: phoneNumber,
    },
    theme: {
      name: 'light',
    },
    enterpriseFeatures: {
      cobrand: {
        text: 'Floryn',
      },
    },
    steps: [
      'welcome',
      {
        type: 'document',
        options: {
          documentTypes: {
            passport: true,
            national_identity_card: true,
            residence_permit: true,
          },
        },
      },
      'complete',
    ],
    customUI: customUISettings,
    language: languageSettings,
  });

import React, { RefObject } from 'react';

import CurrencyInput from 'hookForm/CurrencyInput';
import { Control } from 'react-hook-form';

import { Button } from 'shared/bootstrap';
import WithdrawalSummary from 'shared/components/WithdrawalSummary';
import GeneralErrors from 'hookForm/GeneralErrors';
import { toEuro } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import { InstantPaymentAvailability } from 'models/InstantPaymentAvailability';

const ns = 'charlie.frontpage.withdraw_dialog';
interface FormValues {
  amount: number;
}
interface Props {
  amountInputRef: RefObject<HTMLInputElement>;
  availableBalance: number;
  control: Control<FormValues>;
  generalErrors: string[];
  handleSubmit: () => void;
  isSubmitting: boolean;
  summaryProperties: {
    amount: number;
    interestRate: number;
    closingCommissionAmount: number;
    currentDebtCollectionAmount: number;
    debtCollectionDate: Date | undefined;
    minimumWithdrawalAmount: number;
    newCurrentBalance: number;
    newDebtCollectionAmount: number;
    serviceFeeRate: number;
    usesClientBankAccount: boolean;
    withdrawalFeeRate: number;
    instantPayment: InstantPaymentAvailability;
    withdrawalWillRequireApproval: boolean;
  };
  withdrawAll: () => void;
}

const WithdrawDialogForm = ({
  amountInputRef,
  availableBalance,
  control,
  generalErrors,
  handleSubmit,
  isSubmitting,
  summaryProperties,
  withdrawAll,
}: Props) => (
  <form
    className="c-withdraw-dialog-form"
    onSubmit={handleSubmit}
    autoComplete="off"
    noValidate
  >
    <GeneralErrors generalErrors={generalErrors} />
    <CurrencyInput
      control={control}
      hideLabel
      inputRef={amountInputRef}
      label={I18n.nt(ns, 'amount')}
      name="amount"
      readOnly={isSubmitting}
    />
    <div className="balance-panel">
      <div className="balance-description">
        {I18n.nt(ns, 'balance_info_html', {
          availableBalance: toEuro(availableBalance),
        })}
      </div>
      <div>
        <Button
          className="button-small button-light"
          disabled={isSubmitting}
          onClick={withdrawAll}
        >
          {I18n.nt(ns, 'withdraw_all')}
        </Button>
      </div>
    </div>
    <WithdrawalSummary
      amount={summaryProperties.amount}
      closingCommissionAmount={summaryProperties.closingCommissionAmount}
      currentDebtCollectionAmount={
        summaryProperties.currentDebtCollectionAmount
      }
      debtCollectionDate={summaryProperties.debtCollectionDate}
      instantPayment={summaryProperties.instantPayment}
      interestRate={summaryProperties.interestRate}
      minimumWithdrawalAmount={summaryProperties.minimumWithdrawalAmount}
      newCurrentBalance={summaryProperties.newCurrentBalance}
      newDebtCollectionAmount={summaryProperties.newDebtCollectionAmount}
      serviceFeeRate={summaryProperties.serviceFeeRate}
      usesClientBankAccount={summaryProperties.usesClientBankAccount}
      withdrawalFeeRate={summaryProperties.withdrawalFeeRate}
      withdrawalWillRequireApproval={
        summaryProperties.withdrawalWillRequireApproval
      }
    />
  </form>
);

export default WithdrawDialogForm;

import React from 'react';
import { Modal, Button } from 'shared/bootstrap';
import PlusSvg from 'svg/plus.svg';
import BanksList from './BanksList';
import classNames from 'classnames';
import * as Consent from 'shared/components/Consent';
import { allowAnyRefresh, t } from './helpers';
import { Bank } from 'types';

interface Props {
  banks: Bank[];
  introTextKey: string;
  isDisabled: boolean;
  onAddAccount: () => void;
  onHide: () => void;
  onRefresh: (bankName: string) => void;
}

const ModalBody = ({
  banks,
  introTextKey,
  isDisabled,
  onAddAccount,
  onHide,
  onRefresh,
}: Props) => {
  const showWarning = allowAnyRefresh(banks);

  return (
    <div className="ConsentsModalBody">
      <Modal.Body>
        <Consent.Feedback t={(key) => t(`consentFeedback.${key}`)} />
        <div className="description">
          <p>{t(introTextKey)}</p>
          {showWarning && <p>{t('refreshAll')}</p>}
        </div>
        <BanksList
          isDisabled={isDisabled}
          banks={banks}
          onRefresh={onRefresh}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="buttons">
          <div>
            <Button
              disabled={isDisabled}
              className="button-primary button-primary-inverted"
              onClick={onAddAccount}
            >
              <div className="tw-flex tw-items-center tw-gap-3">
                <PlusSvg className="tw-h-4" />
                {t('addAccount')}
              </div>
            </Button>
          </div>
          <div>
            <Button
              disabled={isDisabled}
              className={classNames({
                'button-light': showWarning,
                'button-primary': !showWarning,
              })}
              onClick={onHide}
            >
              {' '}
              {t('close')}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </div>
  );
};

export default ModalBody;

import React from 'react';
import { Alert, Button } from 'shared/bootstrap';
import { nt } from 'shared/utils/I18n';
import LoadingError from 'shared/components/LoadingError';
import LoadingAnimation from 'shared/components/LoadingAnimation';

import useExactOnlineConnection from './useExactOnlineConnection';
import Connected from './Connected';
import ConnectionInformation from './ConnectionInformation';
import NotConnected from './NotConnected';
import useConnection from './ConnectionInformation/useConnection';

const ns = 'app.profilePage.exact_online_connection';

const ExactOnlineConnection = () => {
  const {
    callbackStatus,
    connected,
    connectionStatus,
    loadingStatus,
    someHaveDivisions,
    username,
  } = useExactOnlineConnection();

  const { disconnect, disconnectStatus } = useConnection();

  const handleDisconnect = () => {
    if (confirm(nt(ns, 'confirm_disconnect'))) {
      disconnect();
    }
  };

  const Title = ({ title }: { title: string }) => (
    <div className="title">
      <h3>{title}</h3>
      <img aria-label="exact-logo" src="/images/exact-logo.svg" />
    </div>
  );

  switch (loadingStatus) {
    case 'error':
      return <LoadingError />;

    case 'loading':
      return <LoadingAnimation />;

    case 'loaded': {
      if (connectionStatus === 'error') {
        return (
          <>
            <Alert type="danger">{nt(ns, 'connection_error')}</Alert>
            <Button
              className="button-light"
              onClick={handleDisconnect}
              disabled={disconnectStatus === 'updating'}
            >
              {nt(ns, 'remove_connection')}
            </Button>
          </>
        );
      } else {
        return (
          <div className="ExactOnlineConnection">
            {callbackStatus === 'error' && (
              <Alert type="danger">{nt(ns, 'callback.error')}</Alert>
            )}
            {connected && (
              <>
                {!someHaveDivisions && (
                  <Alert type="warning">
                    {nt(ns, 'missing_division', { username: username ?? '' })}
                  </Alert>
                )}
                <Title title={nt(ns, 'title_connected')} />
                <Connected />
                <ConnectionInformation />
              </>
            )}
            {!connected && (
              <>
                <Title title={nt(ns, 'title')} />
                <NotConnected />
              </>
            )}
          </div>
        );
      }
    }

    default:
      return null;
  }
};

export default ExactOnlineConnection;

import ModalBody from './Modal/ModalBody';
import ModalFooter from './Modal/ModalFooter';
import ModalHeader from './Modal/ModalHeader';
import ModalTitle from './Modal/ModalTitle';
import ModalContext from './Modal/ModalContext';

import React, { Fragment } from 'react';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';

type DialogProps = Parameters<typeof Dialog>[0];

interface Props {
  children?: React.ReactNode;
  onHide: () => void;
  show: boolean;
  className?: string;
}

const Modal = ({
  onHide,
  show,
  children,
  className,
  ...props
}: Props & Omit<DialogProps, 'as' | 'className' | 'onClose' | 'unmount'>) => {
  const animation = window.RAILS_ENV !== 'test';

  const transitionDuration = animation
    ? 'tw-duration-500'
    : 'tw-duration-[0ms]';

  return (
    <ModalContext.Provider value={{ onHide: onHide }}>
      <Transition show={show} appear={animation}>
        <Dialog
          {...props}
          as="div"
          className={['tw-relative', 'tw-z-10', className].join(' ')}
          onClose={onHide}
        >
          <TransitionChild
            as={Fragment}
            enter={`tw-ease-out ${transitionDuration}`}
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave={`tw-ease-in ${transitionDuration}`}
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <div className="tw-fixed tw-inset-0 tw-bg-white tw-bg-opacity-50" />
          </TransitionChild>

          <div className="tw-modal tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
            <div className="tw-sm:p-0 tw-flex tw-min-h-full tw-place-items-start tw-p-1 tw-text-center">
              <TransitionChild
                as={Fragment}
                enter={`tw-transition tw-ease-out tw-transform ${transitionDuration}`}
                enterFrom="tw--translate-y-full tw-opacity-0"
                enterTo="tw-translate-y-0 tw-opacity-100"
                leave={`tw-transition tw-ease-in-out tw-transform ${transitionDuration}`}
                leaveFrom="tw-translate-y-0 tw-opacity-100"
                leaveTo="tw--translate-y-full tw-opacity-0"
              >
                <DialogPanel className="modal-dialog tw-relative tw-mx-auto tw-min-w-[90%] tw-transform tw-overflow-hidden tw-bg-white tw-p-0 tw-text-left tw-shadow-xl tw-transition-all sm:tw-min-w-[600px]">
                  <div className="modal-content">{children}</div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </ModalContext.Provider>
  );
};

export default Object.assign(Modal, {
  Body: ModalBody,
  Header: ModalHeader,
  Title: ModalTitle,
  Footer: ModalFooter,
});

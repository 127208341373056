import React from 'react';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import { Attachment } from './types';
import { translate } from 'shared/utils/I18n';
import Glyphicon from '../tailwind/Glyphicon';
import InformationCircleSvg from 'svg/information_circle.svg';

const t = translate('review_wizard.uploaded_file');

interface Props {
  onDeleteAttachment: (id: number) => void;
  uploadedFile: Attachment;
  type: 'warning' | 'error';
}

const UploadedFileWithMessage = ({
  onDeleteAttachment,
  type,
  uploadedFile,
}: Props) => {
  const isWarning = type === 'warning';
  return (
    <>
      <li className="rw-uploaded-file rw-space-x-16 tw-flex tw-flex-col tw-bg-gray-50">
        <div className="tw-flex tw-gap-4 tw-p-4 tw-pb-1">
          <span className="tw-flex">
            <InformationCircleSvg
              className={classNames(
                'tw-m-auto tw-h-[18px] tw-w-[18px]',
                isWarning && 'rw-warning',
                !isWarning && 'tw-text-red-500'
              )}
            />
          </span>
          <span className="rw-uploaded-file__filename">
            {uploadedFile.fileName}
          </span>
          <button
            className="rw-uploaded-file__delete tw-ml-auto"
            onClick={() => onDeleteAttachment(uploadedFile.id)}
          >
            <Glyphicon glyph="trash" className="tw-m-auto tw-text-xs" />
            <span className="sr-only">{t('delete')}</span>
          </button>
        </div>
        <div className="tw-pl-[34px] tw-pr-4 tw-pt-1">
          <p
            className={classNames(
              'tw-text-sm tw-font-bold',
              isWarning && 'rw-warning',
              !isWarning && 'tw-text-red-500'
            )}
            // see https://legacy.reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                uploadedFile.diagnostics?.message || ''
              ),
            }}
          ></p>
        </div>
      </li>
    </>
  );
};

export default UploadedFileWithMessage;
